import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, Container, Heading, StyledComponent } from '4finance-components';

@translate
@radium
class GetYourLoan extends StyledComponent {
  static styleRoot = 'GetYourLoan'

  static propTypes = {
    msg: RPT.func,
  }

  renderLinkStyles() {
    const style = this.getStyle();

    return (
      <Style
        rules={{
          '#get-your-loan-heading a': {
            color: style.link.color,
          },
        }}
      />
    );
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Container>
          {this.renderLinkStyles()}
          <Heading inheritedStyle={style.heading} kind="h2" centered>
            {msg('homepage.get_your_loan.heading')}
          </Heading>
          <Heading
            id="get-your-loan-heading"
            decorationStyle={style.decoration}
            inheritedStyle={style.subheading}
            kind="h3"
            centered
          >
            {msg('homepage.get_your_loan.subheading')}
          </Heading>
          <ButtonLink
            centered
            noMargin
            kind="primary"
            inheritedStyle={style.button}
            to="registration"
          >
            {msg('homepage.get_your_loan.button')}
          </ButtonLink>
        </Container>
      </div>
    );
  }

  static style = ({ colors }) => ({
    button: {
      fontWeight: 500,
      borderBottomColor: 'transparent',
    },

    wrapper: {
      padding: '60px 0 70px',
      backgroundColor: colors.secondary,
      position: 'relative',
    },

    decoration: {
      marginTop: '2.5rem',
    },

    heading: {
      lineHeight: 1.4,
      marginTop: '1rem',
      marginBottom: '2rem',
      color: colors.white,
      fontSize: '5.4rem',
      fontWeight: 400,
    },

    subheading: {
      marginTop: '0',
      marginBottom: '3rem',
      lineHeight: 1.4,
      color: colors.white,
      fontSize: '2.1rem',
      fontWeight: 400,
    },

    link: {
      color: colors.white,
    },
  })
}

export default GetYourLoan;
