import { getPath } from '../../browser/routes';

export default function registration({
  replace, getState, dispatch, nextState,
}) {
  const { authentication: { isLoggedIn } } = getState();

  if (!isLoggedIn) {
    return true;
  }

  if (nextState.location.pathname === getPath('registrationPersonalDetails')) {
    replace(getPath('registrationFinanceAndAddress'));
    return false;
  }

  // dispatch info action why transition did not finish
  dispatch({ type: 'GUARD_FORBID_AUTH_FAILED' });
  replace('/');
  return false;
}
