import createConnectWrappers from '4finance-connect';
import actions from '../common/actions';

const {
  connectState: connectStateDecorator,
  connectActions: connectActionsDecorator,
  connectPrefetch: connectPrefetchDecorator,
  connectPending: connectPendingDecorator,
} = createConnectWrappers({ actions });

export const connectState = connectStateDecorator;
export const connectActions = connectActionsDecorator;
export const connectPrefetch = connectPrefetchDecorator;
export const connectPending = connectPendingDecorator;
