import { connect } from 'react-redux';

function isCurrentRoute(routing, paths) {
  const { pathname } = routing.locationBeforeTransitions || {};

  if (typeof paths === 'string') {
    return pathname === paths;
  }

  return pathname && paths.indexOf(pathname) !== -1;
}

function mapStateToProps(options = {}) {
  return (state) => {
    const { routing } = state;

    const map = Object.keys(options).reduce(
      (localState, propName) => ({ ...localState, [propName]: isCurrentRoute(routing, options[propName]) }),
      {},
    );

    return map;
  };
}

export default function connectRoutes(options) {
  const props = mapStateToProps(options);

  return connect(props, null);
}
