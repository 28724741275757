import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import config from '4finance-configuration';
import { Button, Column, Container, Heading, Row, StyledComponent } from '4finance-components';
import { scrollToError } from '4finance-onion-form';
import AmountNotification from '../components/AmountNotification.react';
import CrossLogin from '../crossLogin/CrossLogin.react';
import EditableCalculator from '../calculator/EditableCalculator.react';
import listenWindowResize from '../lib/listenWindowResize.react';
import ProgressBar from '../components/ProgressBar.react';
import ResponsiveEditableCalculator from '../calculator/ResponsiveEditableCalculator.react';
import { financeAndAddress as validations } from '../../common/registration/validations';
import {
  BankAccountNumber,
  City,
  EmploymentStatus,
  Floor,
  HouseNumber,
  LandLinePhone,
  Letter,
  MonthlyIncome,
  PostalCode,
  Province,
  ScrollingForm,
  Street,
  Submit,
} from '../components/fields';
import { connectActions, connectState, connectPending } from '../connect';
import { UPDATE_PROFILE } from '../../common/registration/actions'; // eslint-disable-line no-unused-vars
import SecurityBadge from '../components/SecurityBadge';

@connectActions({
  randomFill: ['registration', 'randomFill'],
  updateProfile: ['registration', 'updateProfile'],
})
@connectPending({
  pending: UPDATE_PROFILE,
})
@connectState({
  amount: ['calculator', 'amount'],
  amountMessage: ['calculator', 'amountMessage'],
  firstName: ['onionForm', 'fields', 'personalDetails', 'firstName', 'value'],
  autoFilledAddress: ['onionForm', 'fields', 'financeAndAddress', 'autoFilledAddress', 'value'],
  floorValue: ['onionForm', 'fields', 'financeAndAddress', 'location5', 'value'],
  letterValue: ['onionForm', 'fields', 'financeAndAddress', 'location6', 'value'],
})
@listenWindowResize
@translate
@radium
class FinanceAndAddress extends StyledComponent {
  static styleRoot = 'FinanceAndAddress'

  static propTypes = {
    cnt: RPT.func,
    firstName: RPT.string,
    pending: RPT.bool,
    updateProfile: RPT.func.isRequired,
  }

  state = {
    floorHasWarning: false,
    letterHasWarning: false,
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.autoFilledAddress !== this.props.autoFilledAddress &&
      this.props.autoFilledAddress
    ) {
      this.setApartmentNotification();
    }
  }

  setApartmentNotification = () => {
    const { floorValue, letterValue } = this.props;

    this.setState({
      floorHasWarning: !floorValue,
      letterHasWarning: !letterValue,
    });
  }

  getWarningMessage = location => this.props.msg('registration.financeAndAddress.apartmentWarning', { location });

  onSubmit = ({ values }) => {
    const { updateProfile } = this.props;
    const {
      location1, location2, location3, location4, location5, location6, postalCode, ...submittableValues
    } = values;
    const address = {
      location1, location2, location3, location4, location5, location6, postalCode,
    };

    updateProfile({ address, ...submittableValues }).then(({ error }) => {
      if (!error) return;
      scrollToError();
    });
  }

  renderRandomFill = () => {
    const { randomFill } = this.props;

    if (!config.faker.enabled) return null;

    return (
      <Button kind="secondary" onClick={() => { randomFill('financeAndAddress'); }}>
        Random Fill
      </Button>
    );
  }

  render() {
    const {
      cnt, device, firstName, msg, pending,
    } = this.props;
    const { floorHasWarning, letterHasWarning } = this.state;
    const style = this.getStyle();
    const isScreenWidthUnderTablet = device.atMost('tablet');

    return (
      <div>
        <CrossLogin />
        <ProgressBar step={2} />
        <AmountNotification />
        {isScreenWidthUnderTablet && <ResponsiveEditableCalculator />}
        <Container>
          <Row>
            <Column large={8} medium={7} small={6} phone={12} tablet={12} style={style.offsetColumn}>
              <Heading decorated kind="h1" inheritedStyle={style.heading}>
                {cnt('registration.financeAndAddress.heading', { firstName })}
              </Heading>

              <Heading kind="h2" inheritedStyle={style.subheading}>{msg('registration.financeAndAddress.subheading')}</Heading>
              <div style={style.subheadingText}>{cnt('registration.financeAndAddress.subheadingText')}</div>
              <ScrollingForm name="financeAndAddress" onSubmit={this.onSubmit} onError={this.onError} validations={validations}>

                <Street />
                <HouseNumber ref={(input) => { this.houseNumber = input; }} />
                <City />
                <Province />
                <PostalCode inheritedStyle={style.postalCode} />
                <Floor
                  warning={floorHasWarning ? this.getWarningMessage('el piso') : null}
                  onBlur={this.setApartmentNotification}
                />
                <Letter
                  warning={letterHasWarning ? this.getWarningMessage('la letra') : null}
                  onBlur={this.setApartmentNotification}
                />

                <LandLinePhone onFocus={this.setApartmentNotification} />

                <Heading kind="h2" inheritedStyle={style.subheading}>
                  {msg('registration.financeAndAddress.subheading2')}
                </Heading>

                <div style={style.subheadingText}>{cnt('registration.financeAndAddress.subheadingText2')}</div>

                <MonthlyIncome onFocus={this.setApartmentNotification} />
                <EmploymentStatus onFocus={this.setApartmentNotification} />

                <BankAccountNumber onFocus={this.setApartmentNotification} />

                <Submit
                  disabled={pending}
                  id="submitFinanceAndAddress"
                  loading={pending}
                  loadingText={msg('common.loading')}
                >
                  {msg('registration.financeAndAddress.submit')}
                </Submit>
                {this.renderRandomFill()}
                <SecurityBadge />
              </ScrollingForm>
            </Column>
            {!isScreenWidthUnderTablet &&
              <Column large={4} medium={5} small={6} phone={12} tablet={12}>
                <EditableCalculator />
              </Column>
            }
          </Row>
        </Container>
      </div>
    );
  }

  static style = () => ({
    heading: {
      fontWeight: 100,
      lineHeight: '1.2',
      fontSize: '5.2rem',
    },

    subheading: {
      fontWeight: 500,
      lineHeight: '1.2',
      fontSize: '2.4rem',
      marginTop: '50px',
      marginBottom: '20px',
    },

    subheadingText: {
      fontSize: '1.6rem',
      fontWeight: 400,
      padding: '2px 0 20px',
      lineHeight: '1.6',
    },

    postalCode: {
      MozAppearance: 'textfield',
    },
  })
}

export default FinanceAndAddress;
