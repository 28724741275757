const S = 'KcPdCp'; // Ory's bulgarian hash-constant
// TODO: localStorage.getItem('LOG_LEVEL')

const Logger = {

  writeLogs(method, args) {
    if (typeof console !== 'undefined' && console !== null) {
      if (typeof console[method] !== 'undefined') { // eslint-disable-line no-console
        console[method](...args); // eslint-disable-line no-console
      } else {
        console.log(`[Unsupported console method '${method}', using console.log]`, ...args); // eslint-disable-line no-console
      }
    }
  },

  log(...args) {
    if (isLogLevel('LOG')) Logger.writeLogs('log', args);
  },

  debug(...args) {
    if (isLogLevel('DEBUG')) Logger.writeLogs('debug', args);
  },

  error(...args) {
    if (isLogLevel('ERROR')) Logger.writeLogs('error', args);
  },

  warn(...args) {
    if (isLogLevel('WARN')) Logger.writeLogs('warn', args);
  },

  info(...args) {
    if (isLogLevel('INFO')) Logger.writeLogs('info', args);
  },

  time(...args) {
    if (isLogLevel('DEBUG')) Logger.writeLogs('time', args);
  },

  timeEnd(...args) {
    if (isLogLevel('DEBUG')) Logger.writeLogs('timeEnd', args);
  },

  debugEnabled() {
    return !!(typeof window !== 'undefined' && window[S]);
  },

  tracking(...args) {
    if (isLogLevel('TRACKING')) Logger.writeLogs('log', args);
  },

};

const LOG_LEVELS = [
  'TRACKING',
  'DEBUG',
  'LOG',
  'INFO',
  'WARN',
  'ERROR',
  'DISABLED',
];

// const DEFAULT_LOG_LEVEL = 'TRACKING';
const DEFAULT_LOG_LEVEL = 'DEBUG';

function isLogLevel(logLevel) {
  return LOG_LEVELS.indexOf(logLevel) >= LOG_LEVELS.indexOf(currentLogLevel());
}

function currentLogLevel() {
  if (typeof window === 'undefined') return 'DISABLED';
  if (!window[S]) return 'DISABLED';
  if (window.LOG_LEVEL) return window.LOG_LEVEL;

  return DEFAULT_LOG_LEVEL;
}

export default Logger;
