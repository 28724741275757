import { push as updatePath, push, replace, go, goBack, goForward } from 'react-router-redux';
import { actions as translate } from '4finance-translate';
import { actions as onionForm } from '4finance-onion-form';
import { actions as api } from './api';
import * as agreement from './agreement/actions';
import * as calculator from './calculator/actions';
import * as crossLogin from './crossLogin/actions';
import * as creditLimit from './creditLimit/actions';
import * as device from './lib/device/actions';
import * as passwordRecovery from './passwordRecovery/actions';
import * as persistence from './lib/persistence/actions';
import * as registration from './registration/actions';
import * as tracking from './tracking/actions';
import * as ui from './ui/actions';
import * as authentication from '../features/auth/common/actions';

const routing = {
  updatePath, push, replace, go, goBack, goForward,
};

const actions = {
  api,
  agreement,
  authentication,
  calculator,
  crossLogin,
  creditLimit,
  device,
  onionForm,
  passwordRecovery,
  persistence,
  registration,
  routing,
  tracking,
  translate,
  ui,
};

export default actions;
