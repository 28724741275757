import actions from '../actions';
import { PRODUCT_NAME } from '../api';

export function fetchPrivacyPolicy() {
  return ({ httpGetHtml }) => ({
    type: 'FETCH_PRIVACY_POLICY',
    meta: { productNumber: PRODUCT_NAME },
    payload: httpGetHtml(
      '/privacy-policy',
      { productNumber: PRODUCT_NAME },
    ),
  });
}

export function fetchGeneralTerms() {
  return ({ httpGetHtml }) => ({
    type: 'FETCH_GENERAL_TERMS',
    payload: httpGetHtml(`/general-terms/${PRODUCT_NAME}`),
  });
}

export function fetchFirstLoanLegalTerms(amount, attachment, extraServiceRequired, term) {
  return ({ httpGetHtml }) => ({
    type: 'FETCH_FIRST_LOAN_LEGAL_TERMS',
    meta: {
      amount, attachment, extraServiceRequired, term,
    },
    payload: httpGetHtml(
      '/first-loan-legal-terms',
      {
        amount, attachment, extraServiceRequired, term,
      },
    ),
  });
}

export function prefetchLegalTerms() {
  return ({ getState, dispatch }) => ({
    type: 'PREFETCH_LEGAL_TERMS',
    payload: (async () => {
      const state = getState();
      const { routing: { locationBeforeTransitions:  { query: { amount: queryAmount } } } } = state;

      if (!queryAmount) {
        await dispatch(actions.calculator.fetchDefaultConstraintsWithOffer());
      }

      const { calculator: { amount, term } } = getState();

      await dispatch(fetchFirstLoanLegalTerms(`${queryAmount || amount}`, false, false, `${term}`));
    })(),
  });
}
