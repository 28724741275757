import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonWithError } from '4finance-components';

@translate
class SubmitWithError extends Component {
  static propTypes = {
    hasErrors: RPT.bool,
    msg: RPT.func,
  }

  render() {
    const { hasErrors, msg } = this.props;
    const errorMsg = hasErrors ? msg('common.formHasErrors') : '';

    return (
      <ButtonWithError
        {...this.props}
        loadingText=""
        error={errorMsg}
      />
    );
  }
}

export default SubmitWithError;
