import { Record } from 'immutable';
import * as actions from './actions';

export const InitialState = Record({
  error: null,
  isLoggedIn: false,
  pending: false,
});
const initialState = new InitialState();

export default function authenticationReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.AUTHENTICATION_LOGIN_START: {
      return state.set('pending', true).set('error', null);
    }

    case actions.AUTHENTICATION_LOGIN_SUCCESS: {
      return state.set('pending', false);
    }

    case actions.AUTHENTICATION_LOGIN_ERROR: {
      return state.set('error', 'unauthorized').set('pending', false);
    }

    case actions.AUTHENTICATION_CLEAR_ERROR: {
      return state.set('error', null);
    }

    case actions.AUTHENTICATION_SET_IS_LOGGED_IN: {
      return state.set('isLoggedIn', action.isLoggedIn);
    }
  }

  return state;
}
