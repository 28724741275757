import radium from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import GetYourLoan from './GetYourLoan.react';
import HomeLayout from '../layouts/Home.react';
import HowDoesItWork from './HowDoesItWork.react';
import Proposition from './Proposition.react';
import LegalTerms from './LegalTerms.react';
import listenWindowResize, { Device } from '../lib/listenWindowResize.react';
import { connectPrefetch } from '../connect';

@connectPrefetch([
  ['authentication', 'logout'],
])
@translate
@listenWindowResize
@radium
class Home extends Component {
  static propTypes = {
    device:   RPT.instanceOf(Device).isRequired,
    location: RPT.object.isRequired,
  }

  componentDidMount() {
    const currentLocation = window.location.href;
    if (currentLocation === 'https://client.vivusonline.es/') {
      window.location.replace('https://www.vivusonline.es');
    }
  }

  render() {
    const { device, location } = this.props;

    return (
      <HomeLayout>
        <Proposition location={location} />
        <HowDoesItWork collapsed={device.atMost('tablet')} />
        <GetYourLoan />
        <LegalTerms />
      </HomeLayout>
    );
  }
}

export default Home;
