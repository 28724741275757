import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { Link } from 'react-router';
import { Link as ScrollLink } from 'react-scroll';
import { StyledComponent } from '4finance-components';

const RadiumLink = radium(Link);

@radium
class VerticalMenuItem extends StyledComponent {
  static styleRoot = 'VerticalMenuItem'

  static propTypes = {
    label:                RPT.string.isRequired,
    onClick:              RPT.func,
    name:                 RPT.string,
    pathname:             RPT.string,
    scrollable:           RPT.bool,
    to:                   RPT.oneOfType([RPT.string, RPT.object]),
    toggleSideMenuAction: RPT.func.isRequired,
  }

  handleClick() {
    const { toggleSideMenuAction, onClick } = this.props;

    if (onClick) onClick();

    toggleSideMenuAction();
  }

  renderScrollableLink() {
    const { label, name } = this.props;
    const style = this.getStyle();

    return (
      <ScrollLink
        onClick={() => this.handleClick()}
        style={style.linkStyle}
        smooth
        to={name}
      >
        {label}
      </ScrollLink>
    );
  }

  renderRadiumLink() {
    const { label, to, pathname } = this.props;
    const activeLink = (pathname === to);
    const style = this.getStyle();

    return (
      <RadiumLink
        onClick={() => this.handleClick()}
        style={[style.linkStyle, activeLink && style.activeLink]}
        to={to}
      >
        {label}
      </RadiumLink>
    );
  }

  render() {
    const { scrollable } = this.props;
    const style = this.getStyle();

    return (
      <li style={style.menuItem}>
        {scrollable ? this.renderScrollableLink() : this.renderRadiumLink()}
      </li>
    );
  }

  static style = ({ colors }) => ({
    linkStyle: {
      display: 'block',
      textDecoration: 'underline',
      padding: '15px 20px',
    },

    activeLink: {
      color: colors.black,
      fontWeight: 500,
      textDecoration: 'none',
    },
  })
}

export default VerticalMenuItem;
