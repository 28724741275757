import Carousel from 'react-slick';
import { Element as ScrollElement } from 'react-scroll';
import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Column, Container, Row, Heading, StyledComponent } from '4finance-components';
import Icon from '../components/Icon.react';
import ListCarouselStyles from './ListCarouselStyles.react';
import { getPath } from '../routes';

const items = [
  { icon: 'apply', arrow: true },
  { icon: 'verify', arrow: true },
  { icon: 'receive' },
];

@translate
@radium
class HowDoesItWork extends StyledComponent {
  static styleRoot = 'HowDoesItWork'

  static propTypes = {
    cnt: RPT.func,
    collapsed: RPT.bool,
    msg: RPT.func,
  }

  static defaultProps = {
    collapsed: false,
  }

  renderItem(item, i) {
    const { cnt, collapsed, msg } = this.props;
    const style = this.getStyle();

    return (
      <div key={i} style={style.item.base}>
        <div style={style.iconWrapper}>
          <Icon kind={item.icon} size={127} style={style.icon} />
        </div>

        <Heading inheritedStyle={style.item.heading} kind="h3">
          {msg(`homepage.how_it_works.columns.${item.icon}.title`)}
        </Heading>

        <div style={style.item.text}>{msg(`homepage.how_it_works.columns.${item.icon}.text`)}</div>

        {item.icon === 'apply' &&
          <div className="homepage-list">
            {cnt(`homepage.how_it_works.columns.${item.icon}.list`)}
          </div>
        }

        {!collapsed && item.arrow &&
          <Icon kind="arrow-right" width={30} height={23} style={style.arrow} />
        }
      </div>
    );
  }

  renderStatic() {
    return (
      <Row id="hp-how-it-work" data-test="hp-how-it-work">
        {items.map((option, i) => this.renderStaticItem(option, i))}
      </Row>
    );
  }

  renderStaticItem(item, i) {
    return (
      <Column key={i} small={4} data-test={`card-${i}`}>
        {this.renderItem(item)}
      </Column>
    );
  }

  renderCarousel() {
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Carousel {...settings}>
        {items.map((item, i) => this.renderItem(item, i))}
      </Carousel>
    );
  }

  render() {
    const { cnt, collapsed } = this.props;
    const style = this.getStyle();
    const content = collapsed ? this.renderCarousel() : this.renderStatic();

    return (
      <ScrollElement name="how_it_works">
        <div style={style.wrapper}>
          <Container>
            <p style={style.additionalText}>
              {cnt('homepage.how_it_works.additionalText', { url: getPath('termsGeneralTerms') })}
            </p>

            <Heading inheritedStyle={style.heading} kind="h2" thin centered>
              {cnt('homepage.how_it_works.heading')}
            </Heading>

            <div style={style.subheadingWrapper}>
              <Heading
                decorationStyle={style.decoration}
                inheritedStyle={style.subheading}
                kind="h3"
                noMargin
                centered
                decorated
              >
                {cnt('homepage.how_it_works.subheading', { url: getPath('registrationPersonalDetails') })}
              </Heading>
            </div>
            <ListCarouselStyles collapsed={collapsed} />
            {content}
          </Container>
        </div>
      </ScrollElement>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    additionalText: {
      fontWeight: 400,
      fontSize: '1.6rem',
    },

    wrapper: {
      padding: '48px 0',
      backgroundColor: colors.white,
      position: 'relative',
      textAlign: 'center',
    },

    listIcon: {
      color: colors.primary,
    },

    arrow: {
      position: 'absolute',
      right: 0,
      top: '45px',
      marginRight: '-15px',
      fill: colors.monotone2,
    },

    decoration: {
      marginTop: '2.5rem',
    },

    heading: {
      lineHeight: 1.4,
      marginTop: '1rem',
      marginBottom: '3rem',
      color: colors.monotone4,
      fontSize: '5.4rem',
    },

    subheading: {
      maxWidth: '500px',
      display: 'inline-block',
      lineHeight: 1.4,
      color: colors.monotone4,
      fontSize: '2rem',
      fontWeight: 400,
    },

    subheadingWrapper: {
      textAlign: 'center',
      paddingBottom: '70px',
    },

    icon: {
      fill: colors.primary,
    },

    iconWrapper: {
      textAlign: 'center',
    },

    item: {
      base: {
        textAlign: 'left',
        maxWidth: '300px',
        margin: '0 auto 32px',
        [mediaQueries.breakpointTablet]: {
          textAlign: 'center',
          margin: '0 0 32px',
          maxWidth: '100%',
        },
      },
      heading: {
        color: colors.monotone4,
        marginTop: '12px',
        marginBottom: '12px',
        fontWeight: 400,
        fontSize: '2.8rem',
        lineHeight: '3.5rem',
        minHeight: '70px',
        [mediaQueries.breakpointTablet]: {
          minHeight: 'auto',
        },
      },
      text: {
        lineHeight: '1.7',
        fontSize: '1.6rem',
        color: colors.monotone4,
        marginTop: '30px',
      },
    },
  })
}

export default HowDoesItWork;
