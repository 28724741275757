import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import radium, { Style } from 'radium';
import { StyledComponent } from '4finance-components';
import Icon from '../components/Icon.react';

@translate
@radium
class PrintButton extends StyledComponent {
  static styleRoot = 'PrintButton';

  static propTypes = {
    msg: RPT.func,
  };

  renderButtonStyle = () => (
    <Style
      rules={{
          mediaQueries: {
            print: {
              '.print-button': {
                display: 'none',
              },
            },
          },
        }}
    />
  )

  print = () => {
    const { id, title } = this.props;

    const mywindow = window.open('', 'Print agreement', 'height=800,width=800');
    mywindow.document.write(`<html><head><title>${title}</title>`);
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById(id).innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    mywindow.print();
    mywindow.close();

    return true;
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();
    const buttonHovered = radium.getState(this.state, 'printButton', ':hover');

    return (
      <div>
        {this.renderButtonStyle()}
        <button
          key="printButton"
          className="print-button"
          data-test="print-button"
          onClick={this.print}
          style={style.button}
        >
          <Icon
            kind="pdf-file"
            size={18}
            style={[style.button.icon, buttonHovered && style.button.icon.hover]}
          />
          {msg('common.print')}
        </button>
      </div>
    );
  }

  static style = ({ colors }) => ({
    button: {
      padding: '8px 11px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray',
      borderRadius: '4px',
      fontSize: '13px',
      background: colors.white,
      textTransform: 'uppercase',
      textDecoration: 'none',
      color: colors.black,
      cursor: 'pointer',
      ':hover': {
        color: colors.primary,
        borderColor: colors.primary,
      },
      icon: {
        marginRight: '5px',
        display: 'inline-block',
        verticalAlign: 'bottom',
        hover: {
          fill: colors.primary,
        },
      },
    },
  });
}

export default PrintButton;
