import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, Loader, StyledComponent, Heading, Icon, OfferRow, Column } from '4finance-components';
import { Map, Record } from 'immutable';
import { Style } from 'radium';
import listenWindowResize, { Device } from '../../common/listenWindowResize';
import { connectState, connectPrefetch } from '../connect';
import { PRODUCT_NAME } from '../../common/api';

@connectPrefetch([
  ['api', 'fetchClientBankAccountNumber'],
  ['registration', 'approvedManualPrefetch'],
])
@connectState({
  application: ['api', 'fetch', 'client', 'application', 'query'],
  bankAccountNumber: ['api', 'fetch', 'client', 'bankAccountNumber', 'query'],
  offer: ['api', 'fetch', 'application', 'firstLoanOffer', 'productNumber'],
})
@listenWindowResize
@translate
class ApprovedManual extends StyledComponent {
  static styleRoot = 'ApprovedManual'

  static PropTypes = {
    application: RPT.instanceOf(Record),
    bankAccountNumber: RPT.string.isRequired,
    cnt: RPT.func.isRequired,
    device: RPT.instanceOf(Device).isRequired,
    formatDate: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    offer: RPT.instanceOf(Map).isRequired,
    preciseNumberToCurrency: RPT.func.isRequired,
  }

  renderLoanData() {
    const {
      cnt, msg, offer, application, preciseNumberToCurrency, formatDate,
    } = this.props;
    const style = this.getStyle();

    const amount = application.getIn(['data', 'amount']);
    const term = application.getIn(['data', 'term']);

    const data = offer &&
      offer.getIn(['', amount.toString()]) &&
      offer.getIn(['', amount.toString(), term.toString(), PRODUCT_NAME, 'data']);

    if (!data) {
      return <Loader />;
    }

    return (
      <div style={style.info.offer}>
        <div style={style.info.title}>{cnt('registration.approved_manual.loan_data.title_html')}</div>
        <OfferRow title={msg('registration.approved_manual.loan_data.amount_requested')} id="amountRequested">
          {preciseNumberToCurrency(data.get('totalPrincipal'))}
        </OfferRow>
        <OfferRow title={msg('registration.approved_manual.loan_data.interest')} id="amountRequested">
          {preciseNumberToCurrency(data.get('totalInterest'))}
        </OfferRow>
        <OfferRow title={msg('registration.approved_manual.loan_data.to_return')} id="amountToBeReturned">
          {preciseNumberToCurrency(data.get('totalRepayableAmount'))}
        </OfferRow>
        <OfferRow last title={msg('registration.approved_manual.loan_data.return_date')} id="returnDate">
          {formatDate(data.get('agreementEndDate'), { format: 'DD/MM/YYYY' })}
        </OfferRow>
      </div>
    );
  }

  renderBankAccount() {
    const { cnt, msg, bankAccountNumber } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.info.offer}>
        <div style={style.info.title}>{cnt('registration.approved_manual.bank_info.title_html')}</div>
        <OfferRow title={msg('registration.approved_manual.bank_info.bank_account')} id="bankAccountTitle" />
        <OfferRow last title={bankAccountNumber && bankAccountNumber.data && bankAccountNumber.data.get('bankAccountNumber').replace(/.(?=.{4,}$)/g, '*')} id="bankAccountNumber" />
      </div>
    );
  }

  renderContentStyles = () => (
    <Style
      rules={{
        '.approved-manual a[href^="tel:"]': {
          cursor: 'default',
          pointerEvents: 'none',
        },
      }}
    />
  );

  render() {
    const { cnt, device, msg } = this.props;
    const style = this.getStyle();

    return (
      <Container>
        <Column large={12} medium={12} small={12} phone={12} tablet={12} style={style.wrapper} className="approved-manual">
          {device.atLeast('desktop') && this.renderContentStyles()}

          <Icon kind="check" style={style.checkIcon} color={style.checkIcon.color} width="103" height="81" />

          <div>
            <Heading decorated kind="h1" thin style={style.heading}>
              {msg('registration.approved_manual.heading_html')}
            </Heading>

            <div style={style.text}>{cnt('registration.approved_manual.top_text_html')}</div>
          </div>

          <div style={style.info.wrapper}>
            {this.renderLoanData()}
            {this.renderBankAccount()}
          </div>

          <div style={style.contact}>
            <div style={style.contact.text}>
              {cnt('registration.approved_manual.bottom_text_html', { mail: '/', phone: 'tel:912907788' })}
            </div>
          </div>
        </Column>
      </Container>
    );
  }
  static style = ({ mediaQueries, colors }) => ({
    wrapper: {
      paddingBottom: 130,
      paddingLeft: 130,
      paddingRight: 0,
      paddingTop: 70,
      [mediaQueries.breakpointLaptopSmall]: {
        paddingLeft: 0,
      },
    },

    text: {
      color: '#65696e',
      fontSize: 22,
      lineHeight: 1.6,
      margin: '0 0 25px 0',
      maxWidth: 830,
    },

    checkIcon: {
      color: colors.primary,
      height: 81,
      left: 0,
      position: 'absolute',
      top: 110,
      width: 103,
      [mediaQueries.breakpointLaptopSmall]: {
        position: 'relative',
        top: 0,
        width: '100%',
      },
    },

    heading: {
      base: {
        [mediaQueries.breakpointLaptopSmall]: {
          textAlign: 'center',
          margin: 0,
        },
      },

      h1: {
        [mediaQueries.breakpointLaptopSmall]: {
          textAlign: 'center',
          margin: 0,
        },
      },

      decoration: {
        base: {
          minWidth: '5%',
          width: '5%',
          [mediaQueries.breakpointLaptopSmall]: {
            minWidth: 0,
            width: 0,
          },
        },
      },
    },

    info: {
      wrapper: {
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,.1)',
        color: '#46484b',
      },

      offer: {
        display: 'inline-block',
        padding: '35px 35px 35px 40px',
        width: '50%',
        [mediaQueries.breakpointLaptopSmall]: {
          width: '100%',
        },
      },

      title: {
        fontSize: '32px',
      },
    },

    contact: {
      margin: '25px 0 -35px',
      maxWidth: 830,
      link: {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
      text: {
        lineHeight: '1.9',
        color: '#65696e',
        fontSize: '16px',
      },
    },
  })
}

export default ApprovedManual;
