import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Loader, OfferRow, StyledComponent } from '4finance-components';
import { Map } from 'immutable';
import { PRODUCT_NAME } from '../../common/api'; // eslint-disable-line no-unused-vars
import { connectState } from '../connect';

@connectState((props, state) => {
  const amount = state.calculator.get('amount');
  const term = state.calculator.get('term');

  return {
    offer: ['api', 'fetch', 'application', 'firstLoanOffer', 'productNumber', '', amount.toString(), term.toString(), PRODUCT_NAME],
  };
})
@translate
@radium
class Offer extends StyledComponent {
  static styleRoot = 'Offer'

  static propTypes = {
    formatDate: RPT.func.isRequired,
    inverseRow: RPT.bool,
    msg: RPT.func,
    numberToPercentage: RPT.func,
    offer: RPT.object,
    preciseNumberToCurrency: RPT.func.isRequired,
  }

  render() {
    const {
      formatDate, inverseRow, msg, numberToPercentage, offer, preciseNumberToCurrency,
    } = this.props;
    const style = this.getStyle();
    const data = offer && offer.get('data') ? offer.get('data') : Map();

    const term = msg('date.days', { count: data.get('term') });
    const InlineLoader = () => (<Loader style={style.loader} />);

    return (
      <div>
        <OfferRow title={msg('offer.totalPrincipal')} id="offerTotalPrincipal" inverseColors={inverseRow} style={{ column: style.column }}>
          {preciseNumberToCurrency(data.get('totalPrincipal')) || <InlineLoader />}
        </OfferRow>
        <OfferRow title={msg('offer.totalInterest')} id="offerNewInterest" inverseColors={inverseRow} style={{ column: style.column }}>
          {preciseNumberToCurrency(data.get('totalInterest')) || <InlineLoader />}
        </OfferRow>
        <OfferRow title={msg('offer.term')} inverseColors={inverseRow} style={{ column: style.column }}>
          {msg('offer.term_value')}
        </OfferRow>
        <OfferRow title={msg('offer.totalRepayableAmount')} id="offerTotalRepayableAmount" inverseColors={inverseRow} style={{ column: style.column }}>
          {preciseNumberToCurrency(data.get('totalRepayableAmount')) || <InlineLoader />}
        </OfferRow>
        <OfferRow last title={msg('offer.agreementEndDate')} id="offerAgreementEndDate" inverseColors={inverseRow} style={{ column: style.column }}>
          {formatDate(data.get('agreementEndDate'), { format: 'DD/MM/YYYY' }) || <InlineLoader />}
        </OfferRow>
        <div style={style.tae}>{msg('offer.annualPercentageRate')}: {numberToPercentage(data.get('annualPercentageRate'), { format: '%n%u', unit: '%' })}</div>
        <div style={style.repaymentInfo}>
          {msg('homepage.repayment_info', { term })}
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    column: {
      width: '51%',
      right: {
        width: '49%',
      },
    },

    table: {
      width: '100%',
      minHeight: '50px',
      color: colors.grayDark,
    },

    loader: {
      fluid: {
        base: {
          padding: 0,
          minHeight: '1px',
          height: '19px',
          marginTop: '-6px',
          display: 'inline-block',
          width: 'auto',
        },
        wrapper: {
          margin:   '0',
        },
      },
    },

    cell: {
      paddingRight: '5px',
      fontSize: '14px',
      fontWeight: 500,
      [mediaQueries.breakpointSmall]: {
        fontSize: '12px',
      },

      value: {
        fontSize: '16px',
      },
    },

    repaymentInfo: {
      paddingBottom: '10px',
      fontSize: '1.3rem',
    },

    tae: {
      fontSize: '12px',
      marginBottom: '-18px',
      marginTop: '10px',
      [mediaQueries.breakpointTablet]: {
        marginBottom: '0px',
      },
    },
  })
}

export default Offer;
