import Promise from 'bluebird';
import getApiResponseCreator from './store/getApiResponse';

export default function createValidateGuards(dispatch, getState) {
  const getApiResponse = getApiResponseCreator(getState, dispatch);

  return guards => (nextState, replace, callback) => {
    const executeGuard = async (index) => {
      if (index >= guards.length) {
        callback();
      } else {
        const result = await Promise.resolve(guards[index]({
          nextState, replace, getApiResponse, getState, dispatch,
        }));

        if (result) {
          executeGuard(index + 1);
        } else {
          callback();
        }
      }
    };

    executeGuard(0);
  };
}
