import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';
import { List } from 'immutable';
import { Container, StyledComponent } from '4finance-components';
import QuickContactsIcon from './QuickContactsIcon.react';


@radium
class QuickContacts extends StyledComponent {
  static styleRoot = 'QuickContacts'

  static propTypes = {
    outerContainerID: RPT.string.isRequired,
    quickContacts: RPT.array,
  }

  state = {
    isQuickContactsFixed: false,
  }

  static defaultProps = {
    outerContainerID: 'app',
  }

  componentDidMount() {
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { outerContainerID } = this.props;

    const outerContainer = document.getElementById(outerContainerID);
    const outerContainerHeight = outerContainer.getBoundingClientRect().height;
    const documentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const isQuickContactsFixed = documentScrollTop + window.innerHeight < document.getElementById('footer').offsetTop;
    const isScrolledDown = Math.abs(documentScrollTop - (Math.round(outerContainerHeight) - window.innerHeight)) < 10;

    if (this.state.isQuickContactsFixed !== isQuickContactsFixed) {
      this.setState({ isQuickContactsFixed });
    }
    if (this.state.isScrolledDown !== isScrolledDown) {
      this.setState({ isScrolledDown });
    }
  }

  renderItem(item, index, list) {
    const { isScrolledDown } = this.state;
    const last = (index === list.size - 1);

    return (
      <QuickContactsIcon
        key={`quick${index}`}
        last={last}
        isScrolledDown={isScrolledDown}
        {...item}
      />
    );
  }

  render() {
    const { isQuickContactsFixed, isScrolledDown } = this.state;
    const { quickContacts } = this.props;
    const style = this.getStyle();
    const Element = isScrolledDown ? 'div' : ScrollLink;
    const quickContactsMap = List.isList(quickContacts) ? quickContacts : List(quickContacts);
    const elementProps = !isScrolledDown ? { smooth: true } : {};

    return (
      <div style={[isQuickContactsFixed && style.fixed]}>
        <Container style={style.container}>
          <Element to="footer" {...elementProps}>
            <div
              style={[
                style.content,
                isQuickContactsFixed && style.shadow,
              ]}
            >
              {quickContactsMap.map((item, index, list) => this.renderItem(item, index, list))}
            </div>
          </Element>
        </Container>
      </div>
    );
  }

  static style = ({ colors }) => ({
    fixed: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 10,
    },
    container: {
      position: 'relative',
    },
    content: {
      backgroundColor: colors.primary,
      padding: '10px 0',
      borderRadius: '12px 12px 0 0',
      position: 'absolute',
      top: '-36px',
      right: '20px',
      height: '36px',
      zIndex: 99,
      cursor: 'pointer',
    },
    shadow: {
      boxShadow: '0 0 8px rgba(0, 0, 0, .1)',
    },
  })
}

export default QuickContacts;
