export const TRACK_EXISTING_CLIENT_LOGIN = 'TRACK_EXISTING_CLIENT_LOGIN';
export const TRACK_LOG_IN_CLICK = 'TRACK_LOG_IN_CLICK';

export function trackExistingClientClick() {
  return {
    type: TRACK_EXISTING_CLIENT_LOGIN,
  };
}

export function trackLoginClick() {
  return {
    type: TRACK_LOG_IN_CLICK,
  };
}
