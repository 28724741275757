const routes = {
  app: '/',
  registrationPersonalDetails: '/registration',
  registrationIdentificationEmailOrWhatsapp: '/registration/step-four',
  registrationApprovedManual: '/registration/confirmation',
  registrationFinanceAndAddress: '/registration/step-two',
  registrationCreditCheck: '/registration/step-three',
  rejected: '/application/rejected',
  termsCookiesPolicy: '/politica-de-cookies',
  termsGeneralTerms: '/condiciones-generales',
  termsLoanInformation: '/informacion-sobre-el-prestamo',
  termsPrivacyPolicy: '/politica-de-privacidad',
  aboutUs: '/quienes-somos',
  informacionComercial: '/marketing-y-comunicacion',
  dataSharing: '/comunicaciones-a-terceros',
  companiesWithReachedAgreement: '/empresas-con-las-que-hemos-alcanzado-acuerdos',
};

// const routes = {p1: 'aa', p2: 'a2/:id/:b_id'}
// usage:
// const p = getPath('p2', {id: 23, b_id: 3})
// => a2/23/3
export function getPath(name, options) {
  if (!options) {
    return routes[name];
  }

  return Object.keys(options).reduce(
    (prev, curr) =>
      prev.replace(`:${curr}`, options[curr]),
    routes[name],
  );
}

export default routes;
