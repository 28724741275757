import forbidAuth from './forbidAuth';
import registration from './registration';
import requireAuth from './requireAuth';
import requireClientAbleToTopUpOrWithoutActiveLoan from './requireClientAbleToTopUpOrWithoutActiveLoan';

export default {
  forbidAuth,
  registration,
  requireAuth,
  requireClientAbleToTopUpOrWithoutActiveLoan,
};
