import createBrowserHistory from 'history/lib/createBrowserHistory';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import { useRouterHistory } from 'react-router';
import configureCommonStore from '../common/configureStore';
import Cookie from '../common/lib/Cookie';

export default function configureStoreWithHistory() {
  const appHistory = useRouterHistory(createBrowserHistory)();

  const store = configureCommonStore({
    initialState: window.__INITIAL_STATE__, // eslint-disable-line no-underscore-dangle
    originHost: window.location.hostname + (window.location.port && `:${window.location.port}`),
    persistenceStore: new Cookie(),
    platformMiddleware: [routerMiddleware(appHistory)],
  });

  const history = syncHistoryWithStore(appHistory, store);

  return { store, history };
}
