import vivus from '4finance-components/lib/styles/vivus';

const extendedSliderInput = options => ({
  ...vivus.get('SliderInput')(options),
  label: {
    color: options.colors.white,
    fontWeight: 400,
    [options.mediaQueries.breakpointLaptop]: {
      fontSize: '1.6rem',
    },
    [options.mediaQueries.breakpointLaptopSmall]: {
      fontSize: '2.1rem',
    },
  },
});

const fieldContainer = options => ({
  label: {
    fontSize: '15px',
    [options.mediaQueries.breakpointLaptopSmall]: {
      fontSize: '14px',
    },
  },
});

const extendedVivusTheme = vivus.merge({
  SliderInput: extendedSliderInput,
  FieldContainer: fieldContainer,
});

export default extendedVivusTheme;
