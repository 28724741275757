import CookieDough from 'cookie-dough';

export default class Cookie {
  constructor(req) {
    this.cookies = new CookieDough(req);
  }

  get(key) {
    return this.cookies.get(key);
  }

  set(key, value, options = { path: '/' }) {
    if (options.maxAge && !options.expires) {
      const maxAgeInSeconds = options.maxAge * 1000;
      const expirationDate = new Date();
      expirationDate.setTime(Date.now() + maxAgeInSeconds);

      options.expires = expirationDate; // eslint-disable-line no-param-reassign
    }

    return this.cookies.set(key, value, options);
  }

  remove(key) {
    return this.cookies.remove(key);
  }

  all() {
    return this.cookies.all();
  }
}
