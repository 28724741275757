import { actions as apiActions, reducer as apiReducer } from '4finance-vivuses61days-api'; // eslint-disable-line import/named

export const PRODUCT_NAME = '6';
export const actions = apiActions;
export const reducer = apiReducer;

export default {
  actions: apiActions,
  reducer: apiReducer,
};
