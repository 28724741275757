import React from 'react';
import { IndexRoute, Route } from 'react-router';
import AboutUs from './aboutUs/AboutUs.react';
import App from './app/App.react';
import ApprovedManual from './registration/ApprovedManual.react';
import Authentication from './authentication/Authentication.react';
import CompaniesWithReachedAgreement from './termsAndConditions/CompaniesWithReachedAgreement';
import CookiesPolicy from './termsAndConditions/CookiesPolicy.react';
import createValidateGuards from '../common/lib/createValidateGuards';
import CreditCheck from './registration/CreditCheck.react';
import DataSharing from './termsAndConditions/DataSharing.react';
import FinanceAndAddress from './registration/FinanceAndAddress.react';
import GeneralTerms from './termsAndConditions/GeneralTerms.react';
import guard from '../common/routeGuards';
import Home from './home/Page.react';
import IdentificationEmailOrWhatsapp from './identification/EmailOrWhatsapp.react';
import LoanInformation from './termsAndConditions/LoanInfromation.react';
import PasswordRecovery from './passwordRecovery/PasswordRecovery.react';
import PasswordRecoveryCompleted from './passwordRecovery/PasswordRecoveryCompleted.react';
import PersonalDetails from './registration/PersonalDetails.react';
import PrivacyPolicy from './termsAndConditions/PrivacyPolicy.react';
import RegistrationLayout from './registration/Page.react';
import Rejected from './features/application/Rejected.react';
import InformacionComercial from './termsAndConditions/InformacionComercial.react';
import authenticationRoute from '../features/auth/browser/routes';
import { getPath } from './routes';

export default function createRoutes(dispatch, getState) {
  const validateGuards = createValidateGuards(dispatch, getState);

  return (
    <Route component={App} path="/">
      <IndexRoute component={Home} />

      <Route component={RegistrationLayout} path={getPath('registrationPersonalDetails')}>
        <IndexRoute component={PersonalDetails} onEnter={validateGuards([guard.registration])} />
        <Route component={IdentificationEmailOrWhatsapp} path={getPath('registrationIdentificationEmailOrWhatsapp')} onEnter={validateGuards([guard.requireAuth, guard.requireClientAbleToTopUpOrWithoutActiveLoan])} />
        <Route component={ApprovedManual} path={getPath('registrationApprovedManual')} onEnter={validateGuards([guard.requireAuth, guard.requireClientAbleToTopUpOrWithoutActiveLoan])} />
        <Route component={FinanceAndAddress} path={getPath('registrationFinanceAndAddress')} onEnter={validateGuards([guard.requireAuth, guard.requireClientAbleToTopUpOrWithoutActiveLoan])} />
        <Route component={CreditCheck} path={getPath('registrationCreditCheck')} onEnter={validateGuards([guard.requireAuth, guard.requireClientAbleToTopUpOrWithoutActiveLoan])} />
      </Route>

      <Route component={Rejected} path={getPath('rejected')} />

      <Route component={PasswordRecovery} path="/recuperacion-contrasena" />
      <Route component={PasswordRecoveryCompleted} path="/enviar-contrasena" />

      <Route component={CookiesPolicy} path={getPath('termsCookiesPolicy')} />
      <Route component={GeneralTerms} path={getPath('termsGeneralTerms')} />
      <Route component={LoanInformation} path={getPath('termsLoanInformation')} />
      <Route component={PrivacyPolicy} path={getPath('termsPrivacyPolicy')} />

      <Route component={AboutUs} path={getPath('aboutUs')} />

      <Route component={InformacionComercial} path={getPath('informacionComercial')} />

      <Route component={DataSharing} path={getPath('dataSharing')} />
      <Route component={CompaniesWithReachedAgreement} path={getPath('companiesWithReachedAgreement')} />

      {authenticationRoute(Authentication)}

    </Route>
  );
}
