import { routerReducer as routing } from 'react-router-redux';
import { pendingActionsReducer as pendingActions } from '4finance-connect';
import { reducer as translate } from '4finance-translate';
import { reducer as apiUrls } from '4finance-http';
import { reducer as onionForm } from '4finance-onion-form';
import { reducer as api } from './api';
import authentication from '../features/auth/common/reducer';
import calculator from './calculator/reducer';
import device from './lib/device/reducer';
import persistence from './lib/persistence/reducer';
import tracking from './tracking/reducer';
import ui from './ui/reducer';

const appReducers = {
  api,
  apiUrls,
  authentication,
  calculator,
  device,
  onionForm,
  pendingActions,
  persistence,
  routing,
  tracking,
  translate,
  ui,
};

export default appReducers;
