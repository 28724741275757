import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { Link } from 'react-router';
import { Link as ScrollLink } from 'react-scroll';
import { StyledComponent } from '4finance-components';

const RadiumLink = radium(Link);

@radium
class MenuItem extends StyledComponent {
  static styleRoot = 'MenuItem'

  static propTypes = {
    bordered:      RPT.bool,
    inverseColors: RPT.bool,
    label:         RPT.string.isRequired,
    onClick:       RPT.func,
    name:          RPT.string,
    pathname:      RPT.string,
    scrollable:    RPT.bool,
    to:            RPT.oneOfType([RPT.string, RPT.object]),
    isRootPage:    RPT.bool,
    isExternal:    RPT.bool,
  }

  renderScrollableLink() {
    const {
      bordered, inverseColors, label, name, onClick,
    } = this.props;
    const style = this.getStyle();

    return (
      <ScrollLink to={name} smooth>
        <span
          onClick={onClick}
          style={[
            style.link,
            bordered && style.linkBordered,
            inverseColors && style.linkInverse,
          ]}
        >
          {label}
        </span>
      </ScrollLink>
    );
  }

  renderRadiumLink() {
    const {
      bordered, inverseColors, label, onClick, to, pathname, isExternal = false,
    } = this.props;
    const activeLink = (pathname === to);
    const style = this.getStyle();

    return (isExternal ?
      <a
        style={[
          style.link,
          bordered && style.linkBordered,
          inverseColors && style.linkInverse,
          activeLink && style.activeLink,
        ]}
        href={to}
      >
        {label}
      </a> :
      <RadiumLink
        onClick={onClick}
        style={[
          style.link,
          bordered && style.linkBordered,
          inverseColors && style.linkInverse,
          activeLink && style.activeLink,
        ]}
        to={to}
      >
        {label}
      </RadiumLink>
    );
  }

  render() {
    const { scrollable } = this.props;
    const style = this.getStyle();

    return (
      <li style={style.item}>
        {scrollable ? this.renderScrollableLink() : this.renderRadiumLink()}
      </li>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    item: {
      display: 'inline-block',
    },
    link: {
      fontSize:          '1.6rem',
      fontWeight:        400,
      color:             colors.grayDarker,
      marginLeft:        '35px',
      height:            'auto',
      lineHeight:        1,
      display:           'block',
      textDecoration:    'none',
      borderBottomWidth: '5px',
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      transition:        'all .1s linear',
      padding:           '10px 15px 8px',
      ':hover': {
        cursor: 'pointer',
        color: colors.primary,
      },
      [mediaQueries.breakpointLaptop]: {
        fontSize: '14px',
        marginLeft: '10px',
        padding: '10px 10px 8px',
      },
    },
    linkBordered: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.grayDarker,
      borderRadius: '3px',
      fontSize: '1.4rem',
      ':hover': {
        borderColor: colors.primary,
      },
    },
    linkInverse: {
      color:             colors.grayDarker,
      borderBottomWidth: '3px',
      fontWeight:        'bold',
      height:            '55px',
    },
    activeLink: {
      color:  colors.grayDarker,
      position: 'relative',
      fontWeight: 'bold',
      ':hover': {
        color: colors.grayDarker,
      },
    },
  })
}

export default MenuItem;
