import React from 'react';
import { Style } from 'radium';
import { StyledComponent } from '4finance-components';

export default class AppStyles extends StyledComponent {
  static styleRoot = 'AppStyles'

  render() {
    return <Style rules={this.getStyle()} />;
  }

  static style = ({ colors }) => ({
    html: {
      height: '100%',
      fontSize: '10px',
    },

    body: {
      fontFamily: 'CoreSans, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '1.414',
      color: '#333',
      backgroundColor: '#ebedf2',
      height: '100%',
      margin: 0,
    },

    a: {
      color: colors.secondary,
    },

    '#app': {
      height: '100%',
    },

    strong: {
      fontWeight: '500',
    },

    '.bold-type': {
      fontWeight: '500',
    },

    '.font-larger': {
      fontSize: '1.15em',
    },

    '.line-break': {
      display: 'block',
    },

    'input::-ms-clear': {
      display: 'none',
    },

    'input::-ms-reveal': {
      display: 'none',
    },

    select: {
      '-moz-appearance': 'none',
    },

    input: {
      fontWeight: '400 !important',
    },

    'form .warningMessage': {
      fontSize: '12px',
      padding: '0 5px',
    },
  })
}
