import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
class SecurityBadge extends StyledComponent {
  static styleRoot = 'FooterBottom'

  static propTypes = {
    msg: RPT.func,
    withMargin: RPT.bool,
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.container}>
        <span style={style.image}>
          {this.renderIcon()}
          <span style={style.text}>{msg('form.securityBadge')}</span>
        </span>
      </div>
    );
  }

  renderIcon() {
    const styles = this.getStyle();

    return (
      <svg width="23px" height="26px" viewBox="0 0 23 26" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styles.icon}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="registration_2" transform="translate(-321.000000, -841.000000)" fill="#606469">
            <path d="M326.475512,857.830069 C323.912347,852.141164 324.396613,845.453305 324.396613,845.453305 C324.396613,845.453305 325.29828,845.436507 326.271878,845.345109 C327.282454,845.249759 328.191212,844.978035 328.191212,844.978035 C328.191212,844.978035 327.935909,851.078478 329.286889,857.247098 C330.519336,862.870295 332.422968,864.749634 332.422968,864.748646 C332.421954,864.749634 328.811741,863.016532 326.475512,857.830069 M343.948093,843.307676 C343.948093,843.307676 340.566336,843.779487 337.762051,843.247403 C335.211549,842.763735 332.50199,840.998025 332.50047,841.000002 C332.497431,840.998025 329.788885,842.763735 327.237877,843.247403 C324.434098,843.779487 321.051835,843.307676 321.051835,843.307676 C321.051835,843.307676 320.361907,853.100596 324.016191,859.372471 C327.348306,865.089536 332.498444,867.000494 332.50047,867 C332.501483,867.000494 337.651115,865.089536 340.98323,859.372471 C344.638527,853.100596 343.948093,843.307676 343.948093,843.307676" id="Fill-1" />
          </g>
        </g>
      </svg>
    );
  }

  static style = ({ mediaQueries }) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '30px',
      paddingBottom: '30px',
    },

    icon: {
      width: '44px',
      height: '44px',
    },

    image: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '330px',
      margin: 0,

      [mediaQueries.breakpointTablet]: {
        marginLeft: '0px auto',
      },
    },

    text: {
      color: '#65696e',
      fontSize: '13px',
      lineHeight: '15px',
      marginLeft: '15px',
    },
  });
}

export default SecurityBadge;
