import IBAN from 'iban';
import moment from 'moment';
import personalIdGenerator from '../lib/faker/personalIdGenerator';
import employmentStatuses from '../constants';

/* eslint-disable no-mixed-operators */
function iban() {
  // Based on specification for Spain
  // https://docs.oracle.com/cd/E18727_01/doc.121/e13483/T359831T498954.htm

  const bankNumberList = ['0049', '2095', '2108', '2104', '1465', '2013', '0075', '0182', '2103', '0128', '2038', '3134', '3160', '1209', '0198', '2105', '0182', '3059', '1491'];
  const bankNumber = bankNumberList[faker.random.number({ min: 0, max: bankNumberList.length - 1 })];
  const branchNumber = faker.helpers.replaceSymbolWithNumber('####');
  const bankAccountNumber = faker.helpers.replaceSymbolWithNumber('##########');

  const factorBank = bankNumber[0] * 4 + bankNumber[1] * 8 + bankNumber[2] * 5 + bankNumber[3] * 10;
  const factorBranch = branchNumber[0] * 9 + branchNumber[1] * 7 + branchNumber[2] * 3 + branchNumber[3] * 6;

  const factorSum = factorBank + factorBranch;
  const factorMod = factorSum % 11;

  const cd1 = 11 - factorMod;

  const accountFactor = bankAccountNumber[0] * 1 + bankAccountNumber[1] * 2 + bankAccountNumber[2] * 4 + bankAccountNumber[3] * 8 + bankAccountNumber[4] * 5 + bankAccountNumber[5] * 10 + bankAccountNumber[6] * 9 + bankAccountNumber[7] * 7 + bankAccountNumber[8] * 3 + bankAccountNumber[9] * 6;
  const accountMod = accountFactor % 11;
  const cd2 = 11 - accountMod;

  const checkDigits = `${cd1}${cd2}`;

  const bban = `${bankNumber}${branchNumber}${checkDigits}${bankAccountNumber}`;

  try {
    return IBAN.fromBBAN('ES', bban);
  } catch (exception) {
    return iban();
  }
}

function phone() {
  const numberList = ['6', '7'];
  const numberStart = faker.random.arrayElement(numberList);
  const numberRest = faker.helpers.replaceSymbolWithNumber('########');

  return `${numberStart}${numberRest}`;
}

function amount() {
  const amounts = ['00', '450', '600', '800', '1000', '1200', '1600'];
  return faker.random.arrayElement(amounts);
}

function employerStatus() {
  const employers = employmentStatuses.map(item => item.value);
  return faker.random.arrayElement(employers);
}

function locations() {
  const streetAddress = faker.address.streetAddress().split(' ');
  const streetNumber = streetAddress[0];

  delete streetAddress[0];
  const location3 = streetAddress.join(' ').trim();

  return {
    location1: 'Madrid',
    location2: faker.address.city(),
    location3,
    location4: streetNumber,
    location5: faker.random.number({ min: 0, max: 30 }),
    location6: faker.random.number({ min: 1, max: 150 }),
  };
}

export default function randomFill() {
  return {
    personalDetails: {
      acceptAgreement: true,
      acceptDataSharing: false,
      agreeGetNews: false,
      dateOfBirth: moment(faker.date.between(new Date(1960, 0, 1), new Date(1995, 11, 31))).format('YYYY-MM-DD'),
      email: faker.internet.email(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      mobilePhone: phone(),
      password: 'parole1',
      personalId: personalIdGenerator(),
    },
    financeAndAddress: {
      // Personal Details
      bankAccountNumber: iban(),
      postalCode: faker.address.zipCode(),
      ...locations(),
      monthlyIncome: amount(),
      employmentStatus: employerStatus(),
    },
  };
}
