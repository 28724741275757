export default async function requireClientAbleToTopUpOrWithoutActiveLoan({ replace, getApiResponse, dispatch }) {
  const { additionalAmount } = await getApiResponse(['fetch', 'client']);
  const { latestLoanLink } = await getApiResponse(['fetch', 'client', 'loans'], { forceFetch: true });
  const hasLatestLoan = latestLoanLink && latestLoanLink.length > 0;

  if (!hasLatestLoan || additionalAmount) {
    return true;
  }

  dispatch({ type: 'GUARD_REQUIRE_CLIENT_ABLE_TO_TOPUP_OR_NOT_HAVE_LOAN_FAILED' });
  replace('/dashboard');
  return false;
}
