export default [
  { key: 'EMPLOYMENT_HOUSEWIFE', value: 'Housewife' },
  { key: 'EMPLOYMENT_STUDENT', value: 'Student' },
  { key: 'EMPLOYMENT_NON_PROFIT', value: 'Working in non-profit organization' },
  { key: 'EMPLOYMENT_MERCHANT', value: 'Street merchants' },
  { key: 'EMPLOYMENT_AGRICULTURE', value: 'Employee in agriculture' },
  { key: 'EMPLOYMENT_PERMANENT_CONTRACT_PUBLIC', value: 'Permanent contract (public sector)' },
  { key: 'EMPLOYMENT_TERM_CONTRACT_PUBLIC', value: 'Term contract (public sector)' },
  { key: 'EMPLOYMENT_PERMANENT_CONTRACT_PRIVATE', value: 'Permanent contract (private sector)' },
  { key: 'EMPLOYMENT_TERM_CONTRACT_PRIVATE', value: 'Term contract (private sector)' },
  { key: 'EMPLOYMENT_SELF', value: 'Self-employed' },
  { key: 'EMPLOYMENT_PRE_RETIRED', value: 'Pre retired' },
  { key: 'EMPLOYMENT_RETIRED', value: 'Retired' },
  { key: 'EMPLOYMENT_PENSIONER', value: 'Pensioner' },
  { key: 'EMPLOYMENT_UNEMPLOYED_PROVISION', value: 'Unemployed with provision' },
  { key: 'EMPLOYMENT_UNEMPLOYED_NO_PROVISION', value: 'Unemployed without benefits' },
];
