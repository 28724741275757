import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import config from '4finance-configuration';
import { autobind, debounce } from 'core-decorators'; // eslint-disable-line no-unused-vars
import { Button, Column, Container, Heading, Row, StyledComponent } from '4finance-components';
import { getFieldError } from '4finance-validation';
import { scrollToError } from '4finance-onion-form';
import AmountNotification from '../components/AmountNotification.react';
import EditableCalculator from '../calculator/EditableCalculator.react';
import ExistingClientModal from './ExistingClientModal.react';
import listenWindowResize from '../lib/listenWindowResize.react';
import ProgressBar from '../components/ProgressBar.react';
import ResponsiveEditableCalculator from '../calculator/ResponsiveEditableCalculator.react';
import {
  AcceptAgreement,
  AgreeGetNews,
  AcceptDataSharing,
  DateOfBirth,
  Email,
  FirstName,
  IoBlackBox,
  LastName,
  MobilePhone,
  PersonalId,
  Password,
  ScrollingForm,
  SecondLastName,
  Submit,
} from '../components/fields';
import { connectActions, connectPrefetch, connectState, connectPending } from '../connect';
import { personalDetails as validations } from '../../common/registration/validations';
import { SUBMIT_REGISTRATION } from '../../common/registration/actions'; // eslint-disable-line no-unused-vars
import SecurityBadge from '../components/SecurityBadge';

@connectActions({
  checkUserExists: ['registration', 'checkUserExists'],
  submitRegistration: ['registration', 'submitRegistration'],
  randomFill: ['registration', 'randomFill'],
  toggleExistingClientModal: ['ui', 'toggleExistingClientModal'],
})
@connectPrefetch([
  ['authentication', 'logout'],
])
@connectPending({
  pending: SUBMIT_REGISTRATION,
})
@connectState({
  amount: ['calculator', 'amount'],
})
@translate
@listenWindowResize
@radium
class PersonalDetails extends StyledComponent {
  static styleRoot = 'Registration'

  static propTypes = {
    checkUserExists: RPT.func.isRequired,
    cnt: RPT.func,
    msg: RPT.func,
    pending: RPT.bool,
    randomFill: RPT.func.isRequired,
    submitRegistration: RPT.func.isRequired,
    toggleExistingClientModal: RPT.func.isRequired,
    amount: RPT.number.isRequired,
  }

  onSubmit = ({ values }) => {
    const { submitRegistration } = this.props;
    const { secondLastName, ...submittableValues } = values;

    submitRegistration({
      ...submittableValues,
      mobilePhone: values.mobilePhone.replace(/[^\d]/g, ''),
      personalId: values.personalId.toUpperCase(),
      lastName: `${values.lastName}${secondLastName ? ` ${secondLastName}` : ''}`,
      passwordRepeat: values.password,
    }).then(({ error }) => {
      if (!error) return;
      scrollToError();
    });
  }

  @autobind
  @debounce(300)
  async handleUserIdentifiersChange({ name, value }) {
    if (getFieldError(value, validations[name])) {
      return;
    }

    await this.checkForExistingClient({ [name]: value });
  }

  async checkForExistingClient({ email, mobilePhone, personalId }) {
    const { checkUserExists, toggleExistingClientModal } = this.props;
    const { payload } = await checkUserExists({ email, mobilePhone, personalId });
    if (payload) {
      toggleExistingClientModal(true);
      return true;
    }
    return false;
  }

  renderRandomFill = () => {
    const { randomFill } = this.props;

    if (!config.faker.enabled) return null;

    return (
      <Button kind="secondary" onClick={() => randomFill('personalDetails')}>
        Random Fill
      </Button>
    );
  }

  render() {
    const {
      cnt, device, msg, pending, amount,
    } = this.props;
    const style = this.getStyle();
    const isScreenWidthUnderTablet = device.atMost('tablet');
    const cntVariables = { amount: amount && `?amount=${amount}` };

    return (
      <div>
        <ProgressBar step={1} />
        {isScreenWidthUnderTablet && <ResponsiveEditableCalculator />}
        <AmountNotification />
        <Container>
          <Row>
            <Column large={8} medium={7} small={6} phone={12} tablet={12} style={style.offsetColumn}>
              <Heading decorated kind="h1" inheritedStyle={style.heading}>{cnt('registration.personalDetails.heading')}</Heading>
              <div style={style.subheadingText}>{cnt('registration.personalDetails.subheadingText')}</div>
              <ScrollingForm name="personalDetails" onSubmit={this.onSubmit} onError={this.onError} validations={validations}>
                <FirstName />
                <LastName />
                <SecondLastName />
                <PersonalId onChange={this.handleUserIdentifiersChange} />
                <DateOfBirth />
                <MobilePhone onChange={this.handleUserIdentifiersChange} />
                <Email onChange={this.handleUserIdentifiersChange} />
                <Password />
                <AgreeGetNews />
                <AcceptDataSharing />
                <AcceptAgreement cntVariables={cntVariables} />
                <IoBlackBox />
                <Submit
                  disabled={pending}
                  loading={pending}
                  loadingText={msg('common.loading')}
                  id="submitPersonalDetails"
                >
                  {msg('registration.personalDetails.submit')}
                </Submit>
                {this.renderRandomFill()}
                <SecurityBadge />
              </ScrollingForm>
              <ExistingClientModal />
            </Column>
            {!isScreenWidthUnderTablet &&
              <Column large={4} medium={5} small={6} phone={12} tablet={12}>
                <EditableCalculator />
              </Column>
            }
          </Row>
        </Container>
      </div>
    );
  }

  static style = () => ({
    heading: {
      fontWeight: 100,
      lineHeight: '1.2',
      fontSize: '5.2rem',
    },

    subheadingText: {
      fontSize: '1.6rem',
      fontWeight: 400,
      padding: '2px 0 10px',
      lineHeight: '1.6',
    },
  })
}

export default PersonalDetails;
