import getCookieManager from './getCookieManager';

export default function getAffiliateInfo() {
  const { affiliateProvider, affiliatePartner, affiliateToken } = getCookieManager().all();

  if (typeof affiliateProvider === 'undefined' || typeof affiliatePartner === 'undefined' || typeof affiliateToken === 'undefined') {
    return {};
  }
  return {
    affiliateInfo: {
      provider: affiliateProvider,
      partner: affiliatePartner,
      token: affiliateToken,
    },
  };
}
