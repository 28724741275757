import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, Footer as FooterComponent, Heading, InfoContacts, StyledComponent } from '4finance-components';
import listenWindowResize, { Device } from '../../../common/listenWindowResize';
import FooterLink from './FooterLink.react';
import QuickContacts from './QuickContacts.react';

@listenWindowResize
@translate
@radium
class FooterTop extends StyledComponent {
  static styleRoot = 'FooterTop'

  static propTypes = {
    cnt: RPT.func,
    device: RPT.instanceOf(Device).isRequired,
    msg: RPT.func,
    withMargin: RPT.bool,
  }

  getContacts() {
    const { msg, device } = this.props;

    return [
      {
        kind: 'phone',
        to: `tel:${msg('footer.phone').replace(/ /g, '')}`,
        onClick: (e) => {
          const isDesktop = device.atLeast('desktop');
          if (isDesktop) {
            e.preventDefault();
            return false;
          }
          return true;
        },
      },
      { kind: 'mail', to: `mailto:${msg('footer.email')}` },
    ];
  }

  renderContentStyles() {
    const style = this.getStyle();

    return (
      <Style
        rules={{
          '.footer-text a': {
            color: style.link.color,
          },
        }}
      />
    );
  }

  render() {
    const {
      cnt, msg, withMargin, device,
    } = this.props;
    const style = this.getStyle();
    const footerStyle = withMargin ? style.footer.withMargin : style.footer;

    const phoneOnClick = device.atLeast('desktop') ?
      (e) => { e.preventDefault(); return false; } :
      () => true;

    return (
      <FooterComponent style={{ base: footerStyle }}>
        <QuickContacts quickContacts={this.getContacts()} outerContainerID="outer-container" />
        <Container style={style.container}>
          <div className="footer-text" style={style.leftColumn}>
            {this.renderContentStyles()}
            <Heading kind="h3" inheritedStyle={style.heading}>
              {msg('footer.heading')}
            </Heading>
            <p style={style.subheading}>
              {msg('footer.first_subheading')}
            </p>
          </div>
          <div style={style.rightColumn}>
            <InfoContacts style={{ base: style.list }}>
              <li style={style.listItem}>
                <FooterLink
                  kind="phone"
                  to={`tel:${msg('footer.phone').replace(/ /g, '')}`}
                  type="contact"
                  onClick={phoneOnClick}
                  style={{ contact: { link: style.footerLink, text: style.footerLink.textContact, icon: style.footerLink.icon } }}
                >
                  {msg('footer.phone')}
                </FooterLink>
              </li>
              <li style={style.listItem}>
                <FooterLink
                  kind="mail"
                  to={`mailto:${msg('footer.email')}`}
                  type="contact"
                  style={{ contact: { link: style.footerLink, text: style.footerLink.text, icon: style.footerLink.icon } }}
                >
                  {msg('footer.email')}
                </FooterLink>
              </li>
            </InfoContacts>
            <p style={style.opening}>{cnt('footer.opening_1')}</p>
            <p style={style.opening}>{cnt('footer.opening_2')}</p>
          </div>
        </Container>
      </FooterComponent>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    footer: {
      marginTop: 0,
      withMargin: {
        marginTop: '130px',
        [mediaQueries.breakpointTablet]: {
          marginTop: '50px',
        },
      },
    },

    container: {
      paddingTop: '15px',
    },

    footerLink: {
      fontSize: '2.2rem',
      fontWeight: 400,
      [mediaQueries.breakpointLaptop]: {
        fontSize: '1.6rem',
        marginLeft: '1.5rem',
      },
      [mediaQueries.breakpointTablet]: {
        marginLeft: 0,
      },
      icon: {
        marginRight: '12px',
        [mediaQueries.breakpointLaptop]: {
          marginRight: '8px',
        },
      },
      text: {
        borderBottomColor: 'transparent',
        [mediaQueries.breakpointTablet]: {
          borderBottomColor: colors.white,
        },
        ':hover': {
          borderBottomColor: colors.white,
        },
      },
      textContact: {
        borderBottomColor: 'transparent',
        cursor: 'default',
        [mediaQueries.breakpointTablet]: {
          borderBottomColor: colors.white,
        },
      },
    },

    heading: {
      color: colors.white,
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
      fontWeight: 500,
      [mediaQueries.breakpointLaptop]: {
        fontSize: '2rem',
      },
    },

    leftColumn: {
      width: '39%',
      backgroundImage: 'url(/assets/img/chat-person.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
      backgroundSize: '218px',
      minHeight: '218px',
      paddingLeft: '178px',
      float: 'left',
      [mediaQueries.breakpointLaptop]: {
        backgroundImage: 'none',
        paddingLeft: 0,
        minHeight: 'auto',
      },
      [mediaQueries.breakpointTablet]: {
        textAlign: 'center',
        width: '100%',
      },
    },

    rightColumn: {
      float: 'left',
      width: '61%',
      [mediaQueries.breakpointTablet]: {
        width: '100%',
      },
    },

    link: {
      color: colors.white,
    },

    list: {
      margin: '2.5rem 0px 10px',
      lineHeight: 1,
      justifyContent: 'flex-end',
      [mediaQueries.breakpointLaptop]: {
        textAlign: 'right',
      },
      [mediaQueries.breakpointTablet]: {
        textAlign: 'center',
      },
    },

    listItem: {
      display: 'inline-block',
      marginLeft: '25px',
      [mediaQueries.breakpointLaptop]: {
        display: 'block',
        marginLeft: 0,
      },
    },

    opening: {
      fontSize: '1.6rem',
      fontWeight: 400,
      textAlign: 'right',
      [mediaQueries.breakpointTablet]: {
        textAlign: 'center',
      },
    },

    subheading: {
      fontSize: '1.6rem',
      marginBottom: '1rem',
      fontWeight: 500,
    },
  })
}

export default FooterTop;
