import { actions as api } from '../../api'; // eslint-disable-line import/named

function formatActionName(path) {
  const rest = path
    .slice(1)
    .map(pathPart => `${pathPart[0].toUpperCase()}${pathPart.slice(1)}`)
    .join('');
  return `${path[0]}${rest}`;
}

export default function getApiResponse(getState, dispatch) {
  return async (path, { dynamicKeys, forceFetch } = {}) => {
    const fetchedPath = path.concat(dynamicKeys || 'query', 'fetched');
    const dataPath = path.concat(dynamicKeys || 'query', 'data');

    return (!forceFetch && getState().api.getIn(fetchedPath))
      ? getState().api.getIn(dataPath).toJS()
      : (await dispatch(api[formatActionName(path)](...(dynamicKeys || [])))).payload;
  };
}
