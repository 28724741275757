import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Column, Container, Row, Heading, StyledComponent } from '4finance-components';
import { connectActions } from '../connect';
import { getPath } from '../routes';
import ApplicationLayout from '../layouts/Application.react';
import Calculator from '../calculator/Calculator.react';
import listenWindowResize, { Device } from '../lib/listenWindowResize.react';

@connectActions({
  updatePath: ['routing', 'updatePath'],
})
@translate
@radium
@listenWindowResize
class AboutUs extends StyledComponent {
  static styleRoot = 'AboutUs'

  static propTypes = {
    cnt: RPT.func,
    device: RPT.instanceOf(Device).isRequired,
    location: RPT.object.isRequired,
    updatePath: RPT.func.isRequired,
  }

  renderTermsStyle = () => (
    <Style
      rules={{
          '#about-us ul': {
            listStyle: 'none',
          },
          '#about-us ul li': {
            textIndent: -21,
          },
          '#about-us p, #about-us ul > *': {
            color: '#65696e',
            fontSize: 16,
            lineHeight: 1.9,
            letterSpacing: -.2,
          },
          '#about-us ul li::before': {
            content: '"\\2014"',
            textIndent: -21,
            marginRight: 5,
          },
          '#about-us h2': {
            fontWeight: 'bold',
            fontSize: '22px',
          },
        }}
    />
  );

  render() {
    const {
      cnt, location, device, updatePath,
    } = this.props;
    const style = this.getStyle();
    const isScreenWidthUnderTablet = device.atMost('tablet');

    return (
      <ApplicationLayout location={location}>
        {this.renderTermsStyle()}
        <Container>
          <Row>
            <Column medium={8} small={6} phone={12}>
              <Heading decorated thin kind="h1" style={style.heading}>
                {cnt('aboutUs.title')}
              </Heading>
              <div id="about-us">
                {cnt('aboutUs.content')}
              </div>
            </Column>
            {!isScreenWidthUnderTablet &&
              <Column large={4} medium={4} small={6} style={style.calculator}>
                <Calculator onApply={() => updatePath(getPath('registrationPersonalDetails'))} />
              </Column>
            }
          </Row>
        </Container>
      </ApplicationLayout>
    );
  }

  static style = () => ({
    heading: {
      h1: {
        fontWeight: 'normal',
      },
      decoration: {
        base: {
          minWidth: '5%',
        },
      },
    },
    calculator: {
      marginTop: 50,
    },
  });
}

export default AboutUs;
