import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import FooterBottom from './FooterBottom.react';
import FooterTop from './FooterTop.react';

export default class Footer extends Component {
  static propTypes = {
    withMargin: RPT.bool,
  }

  render() {
    const { withMargin } = this.props;

    return (
      <div>
        <FooterTop withMargin={withMargin} />
        <FooterBottom />
      </div>
    );
  }
}
