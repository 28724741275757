import { PRODUCT_NAME } from '../api';

export const CALCULATOR_AMOUNT_CHANGE = 'CALCULATOR_AMOUNT_CHANGE';
export const CALCULATOR_AMOUNT_CHANGE_START = 'CALCULATOR_AMOUNT_CHANGE_START';
export const CALCULATOR_AMOUNT_CHANGE_SUCCESS = 'CALCULATOR_AMOUNT_CHANGE_SUCCESS';
export const CALCULATOR_AMOUNT_CHANGED = 'CALCULATOR_AMOUNT_CHANGED';
export const CALCULATOR_FETCH_DEFAULT_CONSTRAINTS_WITH_OFFER = 'CALCULATOR_FETCH_DEFAULT_CONSTRAINTS_WITH_OFFER';
export const CALCULATOR_SET_AMOUNT_LIMIT = 'CALCULATOR_SET_AMOUNT_LIMIT';
export const CALCULATOR_SET_AMOUNT_MESSAGE = 'CALCULATOR_SET_AMOUNT_MESSAGE';
export const CALCULATOR_TOGGLE_OPENED = 'CALCULATOR_TOGGLE_OPENED';

export function fetchDefaultConstraintsWithOffer() {
  return ({ dispatch, getState, getApiResponse }) => {
    const getPromise = async () => {
      const {
        routing: { locationBeforeTransitions: { query } = {} },
      } = getState();

      const { amountInterval: { defaultValue } } = await getApiResponse(
        ['fetch', 'application', 'constraints', 'productNumber', 'byProductnumber'],
        {
          pathOverride: ['fetch', 'application', 'constraints', 'productNumber', PRODUCT_NAME],
          dynamicKeys: [PRODUCT_NAME],
        },
      );

      const amount = getState().calculator.amount || query.amount || defaultValue || 0;

      await dispatch(onAmountChange(amount)); // eslint-disable-line no-unused-expressions
    };

    return {
      type: CALCULATOR_FETCH_DEFAULT_CONSTRAINTS_WITH_OFFER,
      payload: getPromise(),
    };
  };
}

export function onAmountChange(amount) {
  return ({ dispatch, getState, getApiResponse }) => {
    const getPromise = async () => {
      const { calculator: { amount: oldAmount, term } } = getState();

      if (oldAmount !== amount) {
        dispatch(amountChanged(amount));
      }

      await getApiResponse( // eslint-disable-line no-unused-expressions
        ['fetch', 'application', 'firstLoanOffer', 'productNumber', 'byProductnumber'],
        {
          pathOverride: ['fetch', 'application', 'firstLoanOffer', 'productNumber', '', '', amount, term, PRODUCT_NAME],
          dynamicKeys: ['', amount, term, PRODUCT_NAME],
        },
      );
    };

    return {
      type: CALCULATOR_AMOUNT_CHANGE,
      payload: getPromise(),
    };
  };
}

function amountChanged(value) {
  return {
    type: CALCULATOR_AMOUNT_CHANGED,
    payload: { value },
  };
}

export function toggleCalculatorOpened() {
  return {
    type: CALCULATOR_TOGGLE_OPENED,
  };
}

export function setAmountMessage(message) {
  return {
    type: CALCULATOR_SET_AMOUNT_MESSAGE,
    payload: { message },
  };
}


export function setLoanAmountLimit(maxAmount) {
  return {
    type: CALCULATOR_SET_AMOUNT_LIMIT,
    payload: { maxAmount },
  };
}

export function changeLoanAmount(payload) {
  return ({ dispatch, getState }) => {
    const { calculator: { amount } } = getState();
    const { maxAmount } = payload;

    if (maxAmount === 0) {
      dispatch(setAmountMessage('error'));
    } else if (maxAmount > 0 && maxAmount < amount) {
      dispatch(onAmountChange(maxAmount));
      dispatch(setLoanAmountLimit(maxAmount));
      dispatch(setAmountMessage('warning'));
    } else {
      dispatch(setAmountMessage(null));
      dispatch(setLoanAmountLimit(maxAmount));
    }

    return {
      type: 'CHANGE_LOAN_AMOUNT',
    };
  };
}
