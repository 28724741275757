import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, StyledComponent } from '4finance-components';
import { connectState } from '../connect';
import listenWindowResize, { Device } from '../lib/listenWindowResize.react';
import Menu from './menu/Menu.react';

@connectState({
  isLoggedIn: ['authentication', 'isLoggedIn'],
  isSideMenuOpen: ['ui', 'isSideMenuOpen'],
})
@translate
@listenWindowResize
@radium
class Header extends StyledComponent {
  static styleRoot = 'Header'

  static propTypes = {
    device: RPT.instanceOf(Device).isRequired,
    inverseColors: RPT.bool,
    isLoggedIn: RPT.bool,
    isSideMenuOpen: RPT.bool,
    menuShown: RPT.bool.isRequired,
    msg: RPT.func.isRequired,
    pathname: RPT.string,
    smaller: RPT.bool,
  }

  static defaultProps = {
    menuShown: true,
  }

  render() {
    const {
      device, inverseColors, menuShown, smaller, isSideMenuOpen, pathname,
    } = this.props;
    const style = this.getStyle();
    const mobileHeader = device.atLeast('tablet');
    const transparentVersion = mobileHeader && inverseColors;

    return (
      <header
        style={[
          style.header,
          smaller && style.header.smaller,
          !transparentVersion && style.headerInverse,
          isSideMenuOpen && style.header.openedMenu,
        ]}
      >
        <Container>
          <Menu menuShown={menuShown} pathname={pathname} />
        </Container>
      </header>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    header: {
      alignSelf: 'auto',
      flex: '0 0 auto',
      order: '0',
      lineHeight: '100px',
      width: '100%',
      height: '100px',
      zIndex: '100',
      [mediaQueries.breakpointTablet]: {
        position: 'fixed',
        top: 0,
        right: 0,
        backgroundColor: colors.white,
        height: '60px',
        lineHeight: '60px',
        boxShadow: '0 2px 5px rgba(0,1,1,.14)',
        transition: '.5s right ease-in-out',
      },
      smaller: {
        lineHeight: '80px',
        height: '80px',
      },
      openedMenu: {
        [mediaQueries.breakpointTablet]: {
          right: '250px',
        },
      },
    },

    headerInverse: {
      boxShadow: '0 2px 5px rgba(0,1,1,.14)',
      backgroundColor: colors.white,
    },
  })
}

export default Header;
