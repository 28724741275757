import md5 from 'js-md5';
import logger from './Logger';

export const GTM_USER_REGISTERED_EVENT = 'user_registered';
export const GTM_PRE_APPROVED_EVENT = 'pre_approved';
export const GTM_PAGE_CHANGE_EVENT = 'page_change';

export function gtmStoreEvent(data) {
  logger.tracking('[Tracking][GTM]', data);

  if (process.env.IS_BROWSER && window.dataLayer && data) {
    window.dataLayer.push(data);
  }
}

export function gtmTriggerEvent(getState, event, location) {
  const { routing: { locationBeforeTransitions: { pathname: routingPath } = {} } } = getState();
  const pathName = location || routingPath;

  gtmStoreEvent(routeHandler(getState, {
    pathName,
    ...event && { event },
  }));
}

export function gtmFieldErrorHandler({ values }) {
  Object
    .keys(values)
    .forEach((field) => {
      if (values[field]) {
        const data = {
          event: 'error_registration_form',
          errorMessage: validationConditions(values[field], field),
          errorMessageField: validationObjects(field),
        };

        if (isUniqueErrors(data)) {
          gtmStoreEvent(data);
        }
      }
    });
}

function routeHandler(getState, customData) {
  const { api, tracking } = getState();
  const email = api.getIn(['fetch', 'client', 'query', 'data']);
  const returningUser = tracking.get('returningUser');

  return {
    ...customData,
    ...maybeAttr('application', pick(
      api.getIn(['fetch', 'client', 'application', 'query', 'data']),
      ['id', 'amount', 'term'],
    )),
    ...maybeAttr('client', {
      ...pick(api.getIn(['fetch', 'client', 'query', 'data']), [
        'id', 'age', 'identifiedBy', 'dateOfBirth',
        'registeredBy', { number: 'clientNumber' }, { status: 'clientStatus' },
      ]),
      ...pick(api.getIn(['fetch', 'client', 'loans', 'query', 'data']), [
        { clientLoanCount: 'loansCount' }, { clientVoidedLoanCount: 'voidedLoanCount' },
      ]),
      ...md5Mail(email),
      ...returningClient(returningUser, email),
    }),
  };
}

function returningClient(returningUser, email) {
  if (returningUser || email) {
    return { returningClient: returningUser };
  }
  return {};
}

function maybeAttr(key, value) {
  if (value && Object.keys(value).length) {
    return { [key]: value };
  }

  return {};
}

function md5Mail(mail) {
  return mail ? { clientEmail: md5(mail) } : {};
}

// Map({a: 'foo', b: 'bar'}) ['a'] => {a: 'foo'}
// Map({a: 'foo', b: 'bar'}) [{a: 'z'}] => {z: 'foo'}
function pick(obj, fields) {
  if (!obj) return {};

  return fields.reduce(
    (previous, field) => {
      if (typeof field === 'string') {
        return { ...previous, [field]: obj.get(field) };
      }

      const name = Object.keys(field)[0];
      return { ...previous, [field[name]]: obj.get(name) };
    },
    {},
  );
}

function isUniqueErrors(prop) {
  return !getDataLayer().find(({ errorMessage, errorMessageField }) =>
    prop.errorMessageField === errorMessageField && prop.errorMessage === errorMessage);
}

function validationObjects(key) {
  const trackingKeys = {
    firstName: 'first_name',
    lastName: 'first_surname',
    acceptAgreement: 'accept_agreement',
    acceptDataSharing: 'accept_data_sharing',
    agreeGetNews: 'agree_get_news',
    dateOfBirth: 'date_of_birth',
    email: 'email',
    mobilePhone: 'mobile_phone',
    secondLastName: 'second_surname',
    password: 'password',
    personalId: 'personal_id',
    bankAccountNumber: 'bank_account_number',
    employmentStatus: 'employment_status',
    landLinePhone: 'land_line_phone',
    location1: 'location1',
    location2: 'location2',
    location3: 'location3',
    location4: 'location4',
    location5: 'location5',
    location6: 'location6',
    monthlyIncome: 'monthly_income',
    postalCode: 'postal_code',
  };

  return trackingKeys[key] || key;
}

function validationConditions(key, field = null) {
  let trackingKeys = {};
  switch (field) {
    case 'firstName': {
      trackingKeys = {
        too_short: 'length: minimum: 3',
        too_long: 'length: maximum: 50',
      };
      break;
    }
    case 'secondLastName':
    case 'lastName': {
      trackingKeys = {
        too_short: 'length: minimum: 2',
        too_long: 'length: maximum: 50',
      };
      break;
    }
    case 'personalId': {
      trackingKeys = {
        incorrect_format: 'country: es',
      };
      break;
    }
    case 'password': {
      trackingKeys = {
        too_short: 'length minimum:6 maximum: 30',
        too_long: 'length minimum:6 maximum: 30',
      };
      break;
    }
    default: {
      trackingKeys = {
        required: 'presence',
        invalid_email: 'email',
        too_long: 'length: maximum: ',
        too_short: 'length: minimum: ',
        letters_only: 'letters_only',
        invalid_phone_number: 'phone_number_incorrect',
        password_needs_number: 'password_format',
        password_needs_letter: 'password_format',
        must_be_number: ' numericality: { only_integer: true }',
        invalid_age: 'age_range: { validate: 21..75, render: 21..112 }',
        incorrect_format: 'phone_number_incorrect',
        invalid_land_line_phone: 'number must begin with 9 or be empty',
        invalid_iban: 'iban',
      };
    }
  }

  return trackingKeys[key] || key;
}

function getDataLayer() {
  if (process.env.IS_BROWSER && window.dataLayer) {
    return window.dataLayer;
  }
  return [];
}
