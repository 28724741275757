import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Icon, StyledComponent } from '4finance-components';
import Calculator from './Calculator.react';
import ResponsiveOffer from '../components/ResponsiveOffer.react';
import { connectActions, connectPrefetch, connectState } from '../connect';

@connectPrefetch([
  ['calculator', 'fetchDefaultConstraintsWithOffer'],
])
@connectActions({
  toggleCalculatorOpened: ['calculator', 'toggleCalculatorOpened'],
})
@connectState({
  isCalculatorOpened: ['calculator', 'isCalculatorOpened'],
})
@translate
@radium
class ResponsiveEditableCalculator extends StyledComponent {
  static styleRoot = 'EditableCalculator'

  static propTypes = {
    children: RPT.node,
    customResponsiveOffer: RPT.object,
    isCalculatorOpened: RPT.bool.isRequired,
    toggleCalculatorOpened: RPT.func.isRequired,
  }

  render() {
    const {
      children, customResponsiveOffer, isCalculatorOpened, toggleCalculatorOpened,
    } = this.props;
    const style = this.getStyle();
    const ResponsiveOfferComponent = customResponsiveOffer || ResponsiveOffer;

    return (
      <div style={[style.wrapper]}>
        <div style={style.header}>
          <ResponsiveOfferComponent />
          <button id="editToggleButton" onClick={toggleCalculatorOpened} style={style.headerButton}>
            <Icon
              kind={isCalculatorOpened ? 'close-thin' : 'edit'}
              size={28}
              style={style.toggleIcon}
              wrapperStyle={style.headerButtonIcon}
            />
          </button>
        </div>
        <div style={[style.calc.base, isCalculatorOpened && style.calc.opened]}>
          <Calculator noBorderRadius>
            {children}
          </Calculator>
        </div>
      </div>
    );
  }

  static style = ({ colors }) => ({
    wrapper: {
      backgroundColor: colors.white,
      position: 'relative',
    },

    wrapperToggled: {
      backgroundColor: colors.grayLighter,
    },

    toggleIcon: {
      fill: colors.white,
    },

    header: {
      padding: '15px 64px 15px 15px',
      backgroundColor: colors.white,
    },

    headerButton: {
      backgroundColor: colors.primary,
      borderRadius: '10px',
      border: 'none',
      position: 'absolute',
      right: '12px',
      top: '12px',
      width: '50px',
      padding: '0',
      height: '50px',
      outline: 'none',
      cursor: 'pointer',
    },

    headerButtonIcon: {
      display: 'inline-block',
      margin: '4px 0 -4px',
      verticalAlign: 'middle',
    },

    headerButtonText: {
      marginLeft: '10px',
    },

    overviewWrapper: {
      padding: '12px 24px',
    },

    regInfobox: {
      backgroundColor: '#EEEEEE',
      padding: '35',
    },

    calc: {
      base: {
        maxHeight: '0px',
        overflow: 'hidden',
        transition: 'max-height .4s',
      },

      opened: {
        maxHeight: '800px',
      },
    },
  })
}

export default ResponsiveEditableCalculator;
