import { Base64 } from 'js-base64';
import { push as updatePath } from 'react-router-redux';
import { actions as api } from '../../../common/api';
import * as persistence from '../../../common/persistence/actions';

export const AUTHENTICATION_CLEAR_ERROR = 'AUTHENTICATION_CLEAR_ERROR';
export const AUTHENTICATION_LOGIN_START = 'AUTHENTICATION_LOGIN_START';
export const AUTHENTICATION_LOGIN_ERROR = 'AUTHENTICATION_LOGIN_ERROR';
export const AUTHENTICATION_LOGIN_SUCCESS = 'AUTHENTICATION_LOGIN_SUCCESS';
export const AUTHENTICATION_SET_IS_LOGGED_IN = 'AUTHENTICATION_SET_IS_LOGGED_IN';

export function login(email, password, redirectTo, callback, isRegistration = false) {
  return ({ dispatch, httpRequest }) => {
    const getPromise = async () => {
      const basicToken = Base64.encode(`${email}:${password}`);
      const authHeaders = { Authorization: `Basic ${basicToken}` };
      const response = await httpRequest('/token/basic', 'POST', {}, authHeaders)
        .then(handlerResponse => handlerResponse);
      const { error, username, token } = response;

      if (error) throw response;

      const encodedToken = Base64.encode(`${username}:${token}`);

      dispatch(persistence.set('authorizationToken', encodedToken));
      dispatch(setIsLoggedIn({ state: true, token: encodedToken }));
      if (!isRegistration) {
        dispatch(sendGoogleAnalyticsCookie());
      }
      if (callback) {
        await callback(response); // eslint-disable-line no-unused-expressions
      }

      if (redirectTo) {
        dispatch(updatePath(redirectTo));
      }
    };

    return {
      type: 'AUTHENTICATION_LOGIN',
      payload: getPromise(),
    };
  };
}

export function logout() {
  return ({ getState, dispatch }) => {
    const getPromise = async () => {
      if (getState().authentication.isLoggedIn) {
        await dispatch(api.destroyTokenInvalidate());
      }

      dispatch(persistence.remove('authorizationToken'));
      dispatch(setIsLoggedIn({ state: false }));
    };

    return {
      type: 'AUTHENTICATION_LOGOUT',
      payload: getPromise(),
    };
  };
}

export function setIsLoggedIn({ state, token }) {
  return {
    type: AUTHENTICATION_SET_IS_LOGGED_IN,
    isLoggedIn: state,
    token,
  };
}

export function clearError() {
  return {
    type: AUTHENTICATION_CLEAR_ERROR,
  };
}

function sendGoogleAnalyticsCookie() {
  return ({ dispatch, persistenceStore }) => {
    const ga = persistenceStore.get('_ga');

    if (!ga) return Promise.resolve();

    const getPromise = async () => {
      dispatch(api.sendClientCookies({ name: 'ga', value: ga }));
    };

    return {
      type: 'SEND_GOOGLE_ANALYTICS_COOKIE',
      payload: {
        promise: getPromise(),
      },
    };
  };
}
