import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Form } from '4finance-onion-form';
import { Column, Heading, Row, StyledComponent } from '4finance-components';
import { connectActions, connectState, connectPending } from '../connect';
import { Email, Submit } from '../components/fields';
import { SUBMIT_PASSWORD_RECOVERY } from '../../common/passwordRecovery/actions'; // eslint-disable-line no-unused-vars
import validations from '../../common/passwordRecovery/validations';
import ApplicationLayout from '../layouts/Application.react';

@connectActions({
  submitPasswordRecovery: ['passwordRecovery', 'submitPasswordRecovery'],
})
@connectPending({
  pending: SUBMIT_PASSWORD_RECOVERY,
})
@connectState({
  error: ['onionForm', 'fields', 'passwordRecovery', 'username', 'apiError'],
})
@translate
@radium
class PasswordRecovery extends StyledComponent {
  static styleRoot = 'PasswordRecovery'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    error: RPT.string,
    pending: RPT.bool,
    submitPasswordRecovery: RPT.func.isRequired,
  }

  onSubmit = ({ values: { email: username } }) => {
    const { submitPasswordRecovery } = this.props;
    submitPasswordRecovery({ username });
  }

  render() {
    const {
      cnt, error, msg, pending,
    } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <ApplicationLayout>
          <Row>
            <Column phone={12} style={style.offsetColumn}>
              {this.renderIcon()}
              <Heading centered withSubheading kind="h1" inheritedStyle={style.heading}>
                {cnt('passwordRecovery.passwordRecovery.heading')}
                <div style={style.subheading}>{cnt('passwordRecovery.passwordRecovery.subheading')}</div>
              </Heading>
              <div style={style.form}>
                <Form name="passwordRecovery" onSubmit={this.onSubmit} onError={this.onError} validations={validations}>
                  {!!error &&
                    <p className="error" style={style.error}>
                      {msg(`errors.${error}`)}
                    </p>
                  }
                  <Email style={style.form} />
                  <Submit
                    disabled={pending}
                    loading={pending}
                    loadingText={msg('common.loading')}
                    id="submitPasswordRecovery"
                  >
                    {msg('passwordRecovery.passwordRecovery.submit')}
                  </Submit>
                </Form>
              </div>
            </Column>
          </Row>
        </ApplicationLayout>
      </div>
    );
  }

  renderIcon() {
    const style = this.getStyle();

    return (
      <svg viewBox="0 0 65 43" version="1.1" xmlns="http://www.w3.org/2000/svg" className="logo-type login-module__logo-type___2DmbO" style={style.icon}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
          <g id="startpage_login" transform="translate(-119.000000, -79.000000)">
            <g id="Vivus.es_logo-2" transform="translate(119.000000, 79.000000)">
              <g id="Page-1" transform="translate(0.000000, 0.000000)">
                <g id="Vivus.es_logo">
                  <g id="Group">
                    <path d="M64.8684872,29.8226062 L64.8684872,12.8320377 C64.8684872,5.70312787 59.0046691,0 51.7970594,0 L48.7429875,0 L48.7429875,7.6041705 C48.7429875,18.1787201 39.9472605,26.7334119 29.0747645,26.7334119 L21.2563404,26.7334119 L21.2563404,29.8226062 C21.2563404,36.8327008 27.1201585,42.5358287 34.3277682,42.5358287 L51.7970594,42.5358287 C59.0046691,42.5358287 64.8684872,36.8327008 64.8684872,29.8226062" id="Shape" fill="#A7CD45" />
                    <path d="M42.0240293,7.6041705 L42.0240293,0 L34.2056053,0 C26.9979956,0 21.1341775,5.70312787 21.1341775,12.8320377 L21.1341775,20.4362082 L28.9526016,20.4362082 C36.1602113,20.4362082 42.0240293,14.6142652 42.0240293,7.6041705" id="Shape_1_" fill="#106C3A" />
                    <path d="M5.6194923,5.9407582 L0,5.9407582 L0,11.4062557 C0,16.3964926 4.15353778,20.4362082 9.28437858,20.4362082 L14.9038709,20.4362082 L14.9038709,14.9707107 C14.9038709,9.98047378 10.7503331,5.9407582 5.6194923,5.9407582" id="Shape_2_" fill="#106C3A" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    offsetColumn: {
      textAlign: 'center',
      minHeight: '560px',
      [mediaQueries.breakpointLaptopSmall]: {
        minHeight: '0',
      },
    },
    icon: {
      width: '110px',
      height: '67px',
      margin: '48px 0 0 -24px',
    },
    heading: {
      fontWeight: 500,
      lineHeight: '1.2',
      fontSize: '3.8rem',
    },

    subheading: {
      fontSize: '3.8rem',
      fontWeight: 100,
      padding: '2px 0 10px',
    },
    error: {
      color: colors.error,
      textAlign: 'left',
      maxWidth: '300px',
    },
    form: {
      display: 'inline-block',
    },
  })
}

export default PasswordRecovery;
