import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';
import VerticalMenu from './VerticalMenu.react';
import VerticalMenuItem from './VerticalMenuItem.react';
import connectRoutes from '../../../common/lib/connectRoutes';
import { connectActions, connectState } from '../../connect';
import { crossLoginRedirect } from '../../../common/crossLogin/actions';

@connectActions({
  toggleSideMenu: ['ui', 'toggleSideMenu'],
})
@connectState({
  isSideMenuOpen: ['ui', 'isSideMenuOpen'],
})
@connectRoutes({
  isRootPage: '/',
})
@translate
@radium
class ResponsiveMenu extends StyledComponent {
  static styleRoot = 'ResponsiveMenu'

  static propTypes = {
    isSideMenuOpen: RPT.bool,
    msg: RPT.func.isRequired,
  }

  render() {
    const {
      isSideMenuOpen, msg, toggleSideMenu, isRootPage,
    } = this.props;
    const style = this.getStyle();

    return (
      <div id="offCanvasMenu" data-test="offCanvasMenu" style={[style.container, isSideMenuOpen && style.container.opened]}>
        <VerticalMenu>
          {isRootPage && <VerticalMenuItem label={msg('menu.how_it_works')} name="how_it_works" scrollable toggleSideMenuAction={() => toggleSideMenu()} />}
          <VerticalMenuItem onClick={crossLoginRedirect} label={msg('menu.login')} name="login_modal" scrollable toggleSideMenuAction={() => toggleSideMenu()} />
        </VerticalMenu>
      </div>
    );
  }

  static style = ({ colors }) => ({
    container: {
      background: colors.white,
      width: '250px',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: '-250px',
      transition: '.5s right ease-in-out',
      zIndex: 103,
      boxShadow: 'inset 5px 0 10px 0 rgba(0, 0, 0, .1)',
      opened: {
        right: 0,
      },
    },
  })
}

export default ResponsiveMenu;
