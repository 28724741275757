import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';
import { getPath } from '../../routes';

@translate
@radium
class FooterLinks extends StyledComponent {
  static styleRoot = 'FooterLinks'

  static propTypes = {
    msg: RPT.func,
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={style.copyWrapper}>
          <a key="aboutUs" href={`https://www.vivusonline.es${getPath('aboutUs')}`} style={[style.link, style.link.first]}>{msg('footer.links.about_us')}</a>
          <a key="termsPrivacyPolicy" href={`https://www.vivusonline.es${getPath('termsPrivacyPolicy')}`} style={style.link}>{msg('footer.links.privacy_policy')}</a>
          <a key="termsGeneralTerms" href={`https://www.vivusonline.es${getPath('termsGeneralTerms')}`} style={style.link}>{msg('footer.links.terms_conditions')}</a>
          <a key="termsCookiesPolicy" href={`https://www.vivusonline.es${getPath('termsCookiesPolicy')}`} style={style.link}>{msg('footer.links.cookies_policy')}</a>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      textAlign: 'left',
      float: 'left',
      [mediaQueries.breakpointLaptopSmall]: {
        float: 'none',
        textAlign: 'center',
        padding: '1rem 0 3rem',
      },
      [mediaQueries.breakpointTablet]: {
        padding: 0,
      },
    },

    copyWrapper: {
      paddingTop: '1rem',
      [mediaQueries.breakpointTablet]: {
        paddingTop: 0,
      },
    },

    link: {
      padding: '0 2rem',
      color: colors.gray,
      fontSize: '1.3rem',
      textDecoration: 'none',
      fontWeight: '400',
      ':hover': {
        color: colors.lightgreen,
      },
      first: {
        padding: '0 2rem 0 0',
        [mediaQueries.breakpointLaptopSmall]: {
          padding: '0 2rem',
        },
      },
      [mediaQueries.breakpointSmall]: {
        display: 'block',
        fontSize: '1.4rem',
        marginBottom: '2px',
      },
    },
  })
}

export default FooterLinks;
