import moment from 'moment';
import { actions as api } from '4finance-vivuses61days-api'; // eslint-disable-line import/named
import { changeLoanAmount } from '../calculator/actions';

export const CREDIT_LIMIT = 'CREDIT_LIMIT';

export function creditLimit({ dateOfBirth }) {
  const age = moment().diff(dateOfBirth, 'years');

  return ({ dispatch }) => {
    const getPromise = async () => {
      const data = await dispatch(api.fetchApplicationCreditLimit(age));
      dispatch(changeLoanAmount(data.payload));

      return data.payload;
    };

    return {
      type: CREDIT_LIMIT,
      payload: getPromise(),
    };
  };
}
