/* global google */
import config from '4finance-configuration';
import IPT from 'react-immutable-proptypes';
import React from 'react';
import RPT from 'prop-types';
import radium from 'radium';
import Script from 'react-load-script';
import translate from '4finance-translate';
import { StyledComponent, TextField } from '4finance-components';
import { connectActions } from '../connect';
import { parseAddress, provinceMapping } from '../lib/utils';

@connectActions({
  fillAddressFields: ['registration', 'fillAddressFields'],
  setAutoFilledAddress: ['registration', 'setAutoFilledAddress'],
  setMultipleFields: ['onionForm', 'setMultipleFields'],
})
@translate
@radium
class GoogleAddress extends StyledComponent {
  static styleRoot = 'GoogleAddress'

  static propTypes = {
    address: RPT.object,
    name: RPT.string,
    msg: RPT.func,
    googleKey: RPT.string,
    onBlur: RPT.func,
    value: RPT.any,
    error: RPT.string,
    fillAddressFields: RPT.func,
    setMultipleFields: RPT.func,
    setAutoFilledAddress: RPT.func,
  }

  static contextTypes = {
    onionLiveValidate: RPT.func.isRequired,
    themeStyle: IPT.map,
  }

  state = {
    scriptLoaded: false,
  }

  buidGoogleConfig = () => {
    if (this.googleAddressConfig) return this.googleAddressConfig;

    const { urlQueryParams, ...result } = config.googleAddress;
    const query = Object.entries(urlQueryParams).map(pair => pair.join('=')).join('&');

    result.urlQueryParams = query;
    this.googleAddressConfig = result;

    return result;
  }

  handleScriptLoad = () => {
    const { country, types } = this.buidGoogleConfig();

    this.autocomplete = new google.maps.places.Autocomplete(
      this.input,
      {
        types,
      },
    );
    this.autocomplete.setComponentRestrictions({ country });
    this.autocomplete.addListener('place_changed', this.fillInAddress);

    this.setState({ scriptLoaded: true });
  }

  loadScript = () => {
    const { scriptLoaded } = this.state;
    const { url, urlQueryParams } = this.buidGoogleConfig();

    if (scriptLoaded) return null;

    return (
      <Script
        url={`${url}?${urlQueryParams}`}
        onLoad={this.handleScriptLoad}
      />
    );
  }

  fillInAddress = () => {
    const { fillAddressFields, setAutoFilledAddress } = this.props;
    const { onionLiveValidate } = this.context;
    const parsedPlace = parseAddress(this.autocomplete.getPlace());
    const {
      postalCode = '', street: location3 = '', number: location4 = '', province: location1 = '', city: location2 = '',
    } = parsedPlace;

    // according to the Business requirements the provinces returned by google needs to be mapped into the old province values
    const province = provinceMapping[location1] || location1;

    fillAddressFields({
      postalCode, location3, location4, location1: province, location2,
    });
    setAutoFilledAddress(true);
    onionLiveValidate();
  }

  render() {
    return (
      <div>
        {this.loadScript()}
        <TextField
          inputRef={(node) => { this.input = node; }}
          topMargin={false}
          bottomMargin={false}
          placeholder=""
          {...this.props}
        />
      </div>
    );
  }
}

export default GoogleAddress;
