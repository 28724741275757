import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Map } from 'immutable';
import { StyledComponent } from '4finance-components';
import { connectState } from '../connect';
import { PRODUCT_NAME } from '../../common/api'; // eslint-disable-line no-unused-vars

@connectState((props, state) => {
  const amount = state.calculator.get('amount');
  const term = state.calculator.get('term');

  return {
    offer: ['api', 'fetch', 'application', 'firstLoanOffer', 'productNumber', amount.toString(), term.toString(), PRODUCT_NAME],
  };
})
@translate
@radium
class ResponsiveOffer extends StyledComponent {
  static styleRoot = 'ResponsiveOffer'

  static propTypes = {
    formatDate: RPT.func.isRequired,
    msg: RPT.func,
    offer: RPT.object,
    preciseNumberToCurrency: RPT.func.isRequired,
  }

  renderResponsiveOfferRow(data, title) {
    const style = this.getStyle();

    return (
      <td style={style.cell.base} key={title}>
        {title}<br />
        <span style={style.cell.value}>{data}</span>
      </td>
    );
  }

  render() {
    const {
      formatDate, msg, offer, preciseNumberToCurrency,
    } = this.props;
    const data = offer && offer.get('data') ? offer.get('data') : Map();
    const style = this.getStyle();
    const rows = [
      { data: preciseNumberToCurrency(data.get('totalPrincipal')), title: msg('offer.totalPrincipal') },
      { data: preciseNumberToCurrency(data.get('totalInterest')), title: msg('offer.totalInterest') },
      { data: formatDate(data.get('agreementEndDate')), title: msg('offer.agreementEndDate') },
    ];

    return (
      <table style={style.table}>
        <tbody>
          <tr>
            {rows.map(row => this.renderResponsiveOfferRow(row.data, row.title))}
          </tr>
        </tbody>
      </table>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    table: {
      width: '100%',
      minHeight: '50px',
      color: colors.grayDark,
    },

    cell: {
      base: {
        paddingRight: '5px',
        fontSize: '14px',
        fontWeight: 500,
        [mediaQueries.breakpointSmall]: {
          fontSize: '12px',
        },
      },

      value: {
        fontSize: '16px',
      },
    },
  })
}

export default ResponsiveOffer;
