import { createStore } from 'redux';
import actionWatchers from './actionWatchers';
import appReducers from './reducers';
import createMiddlewares from './lib/store/createMiddlewares';
import createStoreReducers from './lib/store/createReducers';
import reviveStateFromPersistence from './lib/persistence/reviveStateFromPersistence';
import runDefaultActions from './runDefaultActions';

export default function configureStore({
  initialState, persistenceStore, platformMiddleware, originHost,
} = {}) {
  // Browser and Client can inject own middlewares into redux store
  const definedPlatformMiddleware = platformMiddleware || [];

  // Revive persistance state from cookies (Browser & Server side)
  const initialStateWithPersistence = reviveStateFromPersistence(initialState, persistenceStore);

  // Combine all reducers and enhance them
  const reducers = createStoreReducers(appReducers, initialState);

  // Create store middlewares
  const middlewares = createMiddlewares({
    actionWatchers,
    definedPlatformMiddleware,
    initialState,
    originHost,
    persistenceStore,
  });

  // Create Store
  const store = createStore(reducers, initialStateWithPersistence, middlewares);

  // Enable hot reload where available.
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers.
    module.hot.accept('./reducers', () => {
      const nextAppReducer = require('./reducers'); // eslint-disable-line global-require
      store.replaceReducer(createStoreReducers(nextAppReducer, initialState));
    });
  }

  // Run default actions - log in user, etc.
  runDefaultActions(store.dispatch, persistenceStore);

  return store;
}
