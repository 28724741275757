import createNotification from './CreateNotification.react';

export default createNotification(
  'compatibility-notification',
  'compatibilityNotification',
  ['device', 'compatibilityNotificationClosed'],
  ['device', 'closeCompatibilityNotification'],
  {
    inheritedStyles: {
      backgroundColor: '#444',
      height: '150px',
    },
  },
  false,
);
