import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, StyledComponent } from '4finance-components';
import Icon from '../components/Icon.react';

const DISCLAIMER_HEIGHT = 200;

@translate
@radium
class LegalTerms extends StyledComponent {
  static styleRoot = 'LegalTerms'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
  }

  state = {
    legalHeight: 'auto',
    legalOpened: true,
  }

  componentDidMount() {
    setTimeout(() => this.getTextContentHeight(), 100);
    window.addEventListener('resize', this.getTextContentHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getTextContentHeight);
  }

  getTextContentHeight = () => {
    const { height } = this.textContent.getBoundingClientRect();

    if (height > DISCLAIMER_HEIGHT) {
      this.setState({ legalHeight: height, legalOpened: false });
    }
  }

  handleLegalText() {
    const { legalOpened } = this.state;
    this.setState({ legalOpened: !legalOpened });
  }

  renderTextStyles = () => (
    <Style
      rules={{
        '.hp-legal-terms': {
          fontSize: '1.3rem',
        },
        '.hp-legal-terms ul': {
          padding: 0,
          margin: 0,
          listStyle: 'none',
        },
        '.hp-legal-terms a': {
          fontWeight: 500,
        },
      }}
    />
  )

  render() {
    const { cnt, msg } = this.props;
    const { legalHeight, legalOpened } = this.state;
    const height = legalOpened ? legalHeight : DISCLAIMER_HEIGHT;
    const shouldRenderToggleButton = legalHeight > DISCLAIMER_HEIGHT;
    const style = this.getStyle();
    const toggleButtonHovered = radium.getState(this.state, 'toggleButton', ':hover');

    return (
      <div style={style.wrapper}>
        <Container>
          {this.renderTextStyles()}
          <div className="hp-legal-terms" style={[style.content, { maxHeight: height }]}>
            <div ref={(node) => { this.textContent = node; }}>{cnt('homepage.legal_terms.text')}</div>
          </div>
          {shouldRenderToggleButton &&
            <div key="toggleButton" onClick={() => this.handleLegalText()} style={style.toggleButton}>
              {legalOpened ? msg('homepage.legal_terms.buttonLess') : msg('homepage.legal_terms.buttonMore')}
              <div style={style.arrowWrapper}>
                <Icon
                  kind="chevron-right"
                  color={toggleButtonHovered ? style.arrow.close.color : ''}
                  size={20}
                  style={[
                    style.arrow,
                    legalOpened && style.arrow.close,
                  ]}
                />
              </div>
            </div>
          }
        </Container>
      </div>
    );
  }

  static style = ({ colors }) => ({
    arrow: {
      display: 'inline-block',
      transform: 'rotate(90deg)',
      transition: 'all .1s linear',
      close: {
        color: colors.primary,
        transform: 'rotate(270deg)',
      },
    },

    arrowWrapper: {
      textAlign: 'center',
    },

    content: {
      overflow: 'hidden',
      transition: 'max-height .2s ease-out',
      fontWeight: 100,
    },

    toggleButton: {
      fontWeight: 400,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '1.4rem',
      marginTop: '20px',
      transition: 'color .1s linear',
      ':hover': {
        color: colors.primary,
        cursor: 'pointer',
      },
    },

    wrapper: {
      padding: '65px 0 35px',
    },
  })
}

export default LegalTerms;
