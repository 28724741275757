import { Map, fromJS } from 'immutable';
import * as actions from './actions';

const initialState = fromJS({
  ekomiCounter: 0,
  isCalculatorOpened: false,
  isLoginModalShown: false,
});

export default function uiReducer(state = initialState, action) {
  if (!(Map.isMap(state))) return fromJS(state);

  switch (action.type) {
    case actions.INCREASE_EKOMI_COUNTER: {
      return state.set('ekomiCounter', state.get('ekomiCounter') + 1);
    }

    case actions.UI_CHANGE: {
      const { name, show } = action.payload;
      return state.set(`is${name}Shown`, show);
    }

    case actions.ON_SIDE_MENU_CHANGE: {
      const { isOpen } = action.payload;
      return state.set('isSideMenuOpen', isOpen);
    }

    case actions.TOGGLE_EDITABLE_CALCULATOR: {
      return state.set('isCalculatorOpened', !state.get('isCalculatorOpened'));
    }

    case actions.TOGGLE_SIDE_MENU:
      return state.update('isSideMenuOpen', isSideMenuOpen => !isSideMenuOpen);
  }

  return state;
}
