import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';
import Footer from '../app/footer/Footer.react';
import Header from '../app/Header.react';

@radium
class ApplicationLayout extends StyledComponent {
  static styleRoot = 'ApplicationLayout'

  static propTypes = {
    children: RPT.any,
    location: RPT.object,
    menuShown: RPT.bool,
  }

  render() {
    const { children, location, menuShown } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.pageWrapper}>
        <Header menuShown={menuShown} pathname={location && location.pathname} smaller />
        <div style={style.contentWrapper}>
          {children}
        </div>
        <Footer withMargin />
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    pageWrapper: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      [mediaQueries.breakpointTablet]: {
        marginTop: '60px',
      },
    },

    contentWrapper: {
      minHeight: '220px',
      flex: '1 0 auto',
    },
  })
}

export default ApplicationLayout;
