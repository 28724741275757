import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, StyledComponent } from '4finance-components';
import Calculator from './Calculator.react';
import Icon from '../components/Icon.react';
import Offer from '../components/Offer.react';
import { connectState, connectActions } from '../connect';
import { getPath } from '../routes';


@connectActions({
  toggleCalculatorOpened: ['calculator', 'toggleCalculatorOpened'],
})
@connectState({
  amount: ['calculator', 'amount'],
  isCalculatorOpened: ['calculator', 'isCalculatorOpened'],
})
@translate
@radium
class EditableCalculator extends StyledComponent {
  static styleRoot = 'EditableCalculator'

  static propTypes = {
    isCalculatorOpened: RPT.bool,
    msg: RPT.func,
    toggleCalculatorOpened: RPT.func,
  }

  renderInfoBox() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.infoBox}>
        <span style={style.infoBoxCertificates}>
          <img style={style.infoBoxImg} src="/assets/img/confianza.png" alt="Confianza" />
        </span>
        <div style={[style.infoBoxCertificates, style.infoBoxCertificates.second]}>
          <Icon kind="lock-calculator" size={24} style={style.infoBoxIcon} />
          <span style={style.infoBoxText}>{msg('registration.calculator.infoBox')}</span>
        </div>
      </div>
    );
  }

  renderInfoLink() {
    const { msg, amount } = this.props;
    const style = this.getStyle();
    const pathname = `https://www.vivusonline.es${getPath('termsLoanInformation')}?amount=${amount}`;

    return (
      <div style={style.infoFormWrapper}>
        <a
          key="infobox-link"
          href={pathname}
          target="_blank"
          style={style.infoFormLink}
        >
          {msg('calculator.informationForm')}
        </a>
      </div>
    );
  }

  render() {
    const { isCalculatorOpened, msg, toggleCalculatorOpened } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <div style={style.container}>
          <div style={style.contactWrapper}>
            <div style={style.loanSummary}>
              <div style={style.loanSummary.loanText}>{msg('registration.calculator.title_1')}</div>
              <div style={style.loanSummary.summaryText}>{msg('registration.calculator.title_2')}</div>
            </div>
          </div>
          <div style={[style.wrapper, isCalculatorOpened && style.wrapper.toggled]}>
            <div style={style.header.wrapper}>
              <Heading kind="h3" thin noMargin inheritedStyle={style.header.text}>
                {msg('calculator.title')}
              </Heading>

              <button className="calculator-edit-button" onClick={toggleCalculatorOpened} style={style.header.button} data-id="editToggle">
                <div style={style.header.button.wrapper}>
                  <Icon
                    kind={isCalculatorOpened ? 'check' : 'edit'}
                    size={20}
                    style={style.header.button.icon}
                  />
                  <span style={style.header.button.text}>
                    {isCalculatorOpened ? msg('calculator.buttons.close') : msg('calculator.buttons.edit')}
                  </span>
                </div>
              </button>
            </div>
            <div style={[style.calculatorWrapper, { height: isCalculatorOpened ? '180px' : '0' }]}>
              <Calculator toggled noBorderRadius editableCalculator />
            </div>
            <div style={style.overviewWrapper}>
              <Offer inverseRow />
            </div>
          </div>
          {this.renderInfoBox()}
        </div>
        {this.renderInfoLink()}
      </div>
    );
  }

  static style = ({ colors }) => ({
    container: {
      backgroundColor: colors.white,
      borderRadius: '8px',
      boxShadow: '0 1px 5px 0 rgba(0,0,0,.25)',
    },
    contactWrapper: {
      background: '#8caf2c',
      padding: '20px 25px 25px',
      color: 'white',
      marginTop: '45px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    loanSummary: {
      fontSize: '26px',
      color: 'white',
      marginTop: '-4px',
      marginBottom: '25px',
      fontWeight: 'normal',
      loanText: {
        fontWeight: 'bold',
        width: '200px',
      },
      summaryText: {
        marginTop: '-4px',
      },
    },
    wrapper: {
      backgroundColor: colors.primary,
      toggled: {
        backgroundColor: colors.primary,
      },
    },
    header: {
      wrapper: {
        padding: '24px 24px 12px 24px',
        color: colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      text: {
        color: colors.white,
      },
      button: {
        fontSize: '16px',
        backgroundColor: colors.white,
        color: colors.monotone5,
        padding: '6px 12px',
        lineHeight: '1.5rem',
        outline: 'none',
        cursor: 'pointer',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderRadius: '4px',
        boxShadow: '0 1px 2px rgba(0 , 0, 0, 0.3)',
        transition: 'background-color .2s ease-in-out',

        ':hover': {
          backgroundColor: colors.monotone2,
        },
        icon: {
          fill: colors.monotone5,
        },
        wrapper: {
          display: 'flex',
          alignItems: 'flex-end',
        },
        text: {
          marginLeft: '10px',
        },
      },
    },
    overviewWrapper: {
      padding: '12px 24px',
      color: colors.white,
    },
    calculatorWrapper: {
      overflow: 'hidden',
      transition: 'height .4s',
    },
    infoFormWrapper: {
      textAlign: 'center',
      marginTop: '17px',
    },
    infoFormLink: {
      display: 'inline-block',
      verticalAlign: 'middle',
      cursor: 'pointer',
      transition: 'all .2s',
      lineHeight: '12px',
      textDecoration: 'none',
      color: colors.secondary,
      borderBottom: `1px solid ${colors.secondary}`,
      fontWeight: 400,
      ':hover': {
        color: colors.primaryDarker,
        borderBottom: `1px solid ${colors.primaryDarker}`,
      },
    },
    infoBox: {
      backgroundColor: colors.white,
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      display: 'flex',
      alignItem: 'center',
      padding: '16px 24px',
      justifyContent: 'spacebetween',
    },
    infoBoxCertificates: {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      width: '30%',
      second: {
        justifyContent: 'flex-end',
        width: '60%',
      },
    },
    infoBoxImg: {
      maxHeight: '60px',
    },
    infoBoxIcon: {
      fill: colors.monotone4,
    },
    infoBoxText: {
      color: colors.monotone4,
      fontSize: '1.2rem',
      fontWeight: 400,
      marginLeft: '7px',
    },
  })
}

export default EditableCalculator;
