import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Loader } from '4finance-components';
import { connectState, connectPrefetch } from '../connect';
import ConditionsLayout from './ConditionsLayout.react';
import Header from './Header.react';

@connectState({
  generalTerms: ['api', 'fetch', 'generalTerms', 'query', 'data'],
})
@connectPrefetch([
  ['agreement', 'fetchGeneralTerms'],
])
@translate
class GeneralTerms extends Component {
  static propTypes = {
    generalTerms: RPT.object,
    location: RPT.object.isRequired,
    msg: RPT.func,
  }

  render() {
    const { generalTerms, location, msg } = this.props;

    return (
      <ConditionsLayout location={location}>
        <div id="conditions-wrapper">
          <Header containerID="conditions-wrapper" title={msg('generalTerms.title')} />

          <div className="terms-wrapper">
            {generalTerms ? <div dangerouslySetInnerHTML={{ __html: generalTerms }} /> : <Loader />}
          </div>
        </div>
      </ConditionsLayout>
    );
  }
}

export default GeneralTerms;
