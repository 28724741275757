import radium from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectState } from '../connect';
import Notification from './Notification.react';

export default function (name, localizationKey, statePath, actionPath, optional = {}, conditionInverter = true) {
  @connectState({
    notificationTrigger: statePath,
  })
  @connectActions({
    dismissNotificationAction: actionPath,
  })
  @translate
  @radium
  class CreateNotification extends Component {
    static propTypes = {
      dismissNotificationAction: RPT.func.isRequired,
      inheritedStyles: RPT.object,
      msg: RPT.func.isRequired,
      notificationTrigger: RPT.any,
    }

    dismissNotificationAction = () => {
      if (optional.dismissActionParams) {
        this.props.dismissNotificationAction(...optional.dismissActionParams);
      } else {
        this.props.dismissNotificationAction(name);
      }
    }

    render() {
      const { msg, inheritedStyles } = this.props;

      if (!this.props.notificationTrigger === conditionInverter) return null;

      return (
        <Notification
          actionTitle={(optional.actionText && msg(`notification.${optional.actionText}`)) || msg('notification.dismiss')}
          linkTitle={optional.linkText && msg(`notification.${optional.linkText}`)}
          message={msg(`notification.${localizationKey}`)}
          inheritedStyles={inheritedStyles}
          name={name}
          onClick={this.dismissNotificationAction}
          {...optional}
        />
      );
    }
  }

  return CreateNotification;
}
