import { Record } from 'immutable';
import * as actions from './actions';

const InitialState = Record({
  amount: 0,
  amountLimit: null,
  amountMessage: null,
  isCalculatorOpened: false,
  term: 61,
});
const initialState = new InitialState();

export default function calculatorReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.CALCULATOR_AMOUNT_CHANGED: {
      const { value } = action.payload;

      return state.set('amount', value);
    }

    case actions.CALCULATOR_SET_AMOUNT_LIMIT: {
      const { maxAmount } = action.payload;

      return state.set('amountLimit', maxAmount);
    }

    case actions.CALCULATOR_TOGGLE_OPENED:
      return state.set('isCalculatorOpened', !state.isCalculatorOpened);

    case actions.CALCULATOR_SET_AMOUNT_MESSAGE: {
      const { message } = action.payload;

      return state.set('amountMessage', message);
    }
  }
  return state;
}
