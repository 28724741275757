import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Loader } from '4finance-components';
import { connectState, connectPrefetch } from '../connect';
import ConditionsLayout from './ConditionsLayout.react';
import Header from './Header.react';

@translate
@connectPrefetch([
  ['agreement', 'prefetchLegalTerms'],
])
@connectState(({ location }, state) => {
  const { query: { amount: queryAmount } } = location;
  const amount = queryAmount || state.calculator.get('amount');
  const term = state.calculator.get('term');

  return {
    firstLoanLegalTerms: ['api', 'fetch', 'firstLoanLegalTerms', amount.toString(), 'false', 'false', term.toString(), 'data'],
  };
})
class LoanInformation extends Component {
  static propTypes = {
    location: RPT.object.isRequired,
    msg: RPT.func,
    firstLoanLegalTerms: RPT.string,
  }

  render() {
    const { location, msg, firstLoanLegalTerms } = this.props;

    return (
      <ConditionsLayout location={location}>
        <div id="loan-info-wrapper">
          <Header containerID="loan-info-wrapper" title={msg('loanInformation.title')} />
          <div className="terms-wrapper">
            {firstLoanLegalTerms ? <div id="eu-content" dangerouslySetInnerHTML={{ __html: firstLoanLegalTerms }} /> : <Loader />}
          </div>
        </div>
      </ConditionsLayout>
    );
  }
}

export default LoanInformation;
