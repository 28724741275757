import promiseMiddleware from '4finance-promise-middleware';
import config from '4finance-configuration';
import createLogger from 'redux-logger';
import { applyMiddleware, compose } from 'redux';
import { pendingActionsMiddleware } from '4finance-connect';
import createActionWatcherMiddleware from './createActionWatcherMiddleware';
import createStoreDependencyInjections from './createDependencyInjections';
import injectDependencies from './injectDependencies';

export default function createMiddlewares({
  actionWatchers,
  definedPlatformMiddleware = [],
  initialState,
  originHost,
  persistenceStore,
}) {
  const actionDependencyInjection = createStoreDependencyInjections({ initialState, persistenceStore });

  const middlewares = [
    ...definedPlatformMiddleware,
    createActionWatcherMiddleware(actionDependencyInjection, actionWatchers),
    injectDependencies({ ...actionDependencyInjection, originHost }),
    promiseMiddleware,
    pendingActionsMiddleware(),
  ];

  // Enable logger only for browser with `debug` cookie.
  if (config.debug.reduxLogger) {
    const logger = createLogger({
      collapsed: true,
      // Convert immutable to JSON.
      stateTransformer: state => JSON.parse(JSON.stringify(state)),
    });
    // Logger must be the last middleware in chain.
    middlewares.push(logger);
  }

  const enableDevToolsExtension = config.debug.devTools && process.env.IS_BROWSER && window.devToolsExtension;

  const appliedMiddlewares = applyMiddleware(...middlewares);

  return enableDevToolsExtension
    ? compose(appliedMiddlewares, window.devToolsExtension())
    : appliedMiddlewares;
}
