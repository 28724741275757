import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import ConditionsLayout from './ConditionsLayout.react';
import Header from './Header.react';


@translate
class InformacionComercial extends Component {
  static propTypes = {
    cnt: RPT.func,
    location: RPT.object.isRequired,
    msg: RPT.func,
  }

  render() {
    const { cnt, location, msg } = this.props;

    return (
      <ConditionsLayout location={location}>
        <div id="informacion-comercial-wrapper">
          <Header containerID="informacion-comercial-wrapper" title={msg('informacionComercial.title')} />
          <div className="terms-wrapper">
            {cnt('informacionComercial.content')}
          </div>
        </div>
      </ConditionsLayout>
    );
  }
}

export default InformacionComercial;
