import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent, Heading } from '4finance-components';
import { connectState } from '../connect';
import Notification from './Notification.react';

const FULL_LOAN_MIN_AGE = 25;

@connectState({
  amount: ['calculator', 'amount'],
  amountMessage: ['calculator', 'amountMessage'],
})
@translate
@radium
class AmountNotification extends StyledComponent {
  static styleRoot = 'AmountNotification'

  static propTypes = {
    amount: RPT.oneOfType([RPT.string, RPT.number]),
    amountMessage: RPT.string,
    msg: RPT.func.isRequired,
  }

  render() {
    const { amount, amountMessage, msg } = this.props;
    const style = this.getStyle();

    if (!amountMessage) return null;

    return (
      <div className="amountNotification errorMessage">
        <Notification>
          <Heading kind="h5" inheritedStyle={style.error.heading}>
            {msg(`registration.credit_limit.${amountMessage}.title`, { amount })}
          </Heading>
          <div>{msg(`registration.credit_limit.${amountMessage}.subtitle`, { age: FULL_LOAN_MIN_AGE, amount })}</div>
        </Notification>
      </div>
    );
  }

  static style = ({ colors }) => ({
    error: {
      heading: {
        color: colors.error,
        marginBottom: 5,
        marginTop: 0,
      },
    },
  })
}

export default AmountNotification;
