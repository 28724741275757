import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { TextField } from '4finance-components';
import { connectState } from '../../connect';

@connectState({
  warning: ['onionForm', 'fields', 'registration', 'personalId', 'warning'],
  amountLimit: ['calculator', 'amountLimit'],
})
@translate
class PersonalIdField extends Component {
  static propTypes = {
    amountLimit: RPT.number,
    msg: RPT.func,
    numberToCurrency: RPT.func,
    warning: RPT.string,
    value: RPT.string,
  }

  render() {
    const {
      warning, amountLimit, msg, numberToCurrency, value,
    } = this.props;

    const upperCaseValue = value ? value.toUpperCase() : '';

    return (
      <TextField {...this.props} value={upperCaseValue} warning={warning && msg('errors.amount_too_high', { amount: numberToCurrency(amountLimit) })} />
    );
  }
}

export default PersonalIdField;
