import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Button, StyledComponent, SliderWithControls } from '4finance-components';
import { connectActions, connectState, connectPrefetch } from '../connect';
import { getPath } from '../routes';
import { PRODUCT_NAME } from '../../common/api'; // eslint-disable-line no-unused-vars
import Offer from '../components/Offer.react';


@connectActions({
  onAmountChange: ['calculator', 'onAmountChange'],
})
@connectState((props, state) => {
  const amount = state.calculator.get('amount');
  const term = state.calculator.get('term');

  return {
    amount: ['calculator', 'amount'],
    amountLimit: ['calculator', 'amountLimit'],
    term: ['calculator', 'term'],
    constraints: ['api', 'fetch', 'application', 'constraints', 'productNumber', PRODUCT_NAME, 'data'],
    offerFetched: ['api', 'fetch', 'application', 'firstLoanOffer', 'productNumber', '', amount.toString(), term.toString(), PRODUCT_NAME, 'fetched'],
  };
})
@connectPrefetch([
  ['calculator', 'fetchDefaultConstraintsWithOffer'],
])
@translate
@radium
class Calculator extends StyledComponent {
  static styleRoot = 'Calculator'

  static defaultProps = {
    toggled: false,
  }

  static propTypes = {
    amount: RPT.number.isRequired,
    amountLimit: RPT.number,
    constraints: RPT.object.isRequired,
    editableCalculator: RPT.bool,
    msg: RPT.func,
    noBorderRadius: RPT.bool,
    numberToCurrency: RPT.func.isRequired,
    offerFetched: RPT.bool,
    onAmountChange: RPT.func.isRequired,
    onApply: RPT.func,
    responsiveCentered: RPT.bool,
    toggled: RPT.bool,
  }

  onAmountChange = (amount) => {
    this.props.onAmountChange(amount);
  }

  onApply = (event) => {
    event.preventDefault();
    this.props.onApply();

    return false;
  }

  renderApplyButton = () => {
    const {
      msg, offerFetched, onApply, toggled,
    } = this.props;
    const style = this.getStyle();

    if (!onApply || toggled) return null;

    return (
      <div style={style.applyButtonWrapper}>
        <Button
          disabled={!offerFetched}
          fullWidth
          id="applyButton"
          kind="primary"
          loading={!offerFetched}
          onClick={onApply}
          style={{ base: style.button }}
        >
          {msg('homepage.get_loan.apply_now')}
        </Button>
      </div>
    );
  }

  renderOffer = () => {
    const { offerWrapper } = this.getStyle();

    if (this.props.toggled) return null;

    return (
      <div style={offerWrapper}>
        <Offer />
      </div>
    );
  }

  renderInfoFormLink = () => {
    const { msg, editableCalculator, amount } = this.props;
    const style = this.getStyle();
    const pathname = `https://www.vivusonline.es${getPath('termsLoanInformation')}?amount=${amount}`;

    if (editableCalculator) return null;

    return (
      <div style={style.infoFormWrapper}>
        <a
          href={pathname}
          style={style.infoFormLink}
        >
          {msg('calculator.informationForm')}
        </a>
      </div>
    );
  }

  render() {
    const {
      amount, amountLimit, constraints, editableCalculator, msg, noBorderRadius, numberToCurrency, responsiveCentered,
    } = this.props;
    const style = this.getStyle();

    const interval = constraints && constraints.get('amountInterval');
    return (
      <div className="calculator" style={[responsiveCentered && style.responsiveWrapper, style.outerWrapper]}>
        <div
          style={[
            style.innerWrapper,
            editableCalculator && style.innerWrapper.editableCalculator,
            noBorderRadius && style.noBorderRadius,
          ]}
        >
          {!editableCalculator &&
            <div style={style.title}>{msg('calculator.secondary_title')}</div>
          }
          <div style={[style.sliderWrapper, editableCalculator && style.sliderWrapper.editableCalculator]}>
            {interval &&
              <SliderWithControls
                defaultValue={interval.get('defaultValue')}
                formater={numberToCurrency}
                heading={msg('calculator.amount.heading')}
                kind="amount"
                max={amountLimit || interval.get('max')}
                min={interval.get('min')}
                onChange={this.onAmountChange}
                selectFormater={numberToCurrency}
                step={interval.get('step')}
                unit={msg('calculator.amount.unit')}
                value={amount}
                loanLimitMessage=""
              />
            }
          </div>
          {this.renderOffer()}
          {this.renderApplyButton()}
          {this.renderInfoFormLink()}
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    button: {
      fontSize: '2rem',
      fontWeight: 500,
      [mediaQueries.breakpointLarge]: {
        minWidth: '100%',
      },
    },

    responsiveWrapper: {
      [mediaQueries.breakpointTablet]: {
        margin: '0 auto',
      },
    },

    outerWrapper: {
      position: 'relative',
    },

    innerWrapper: {
      borderRadius: '8px',
      boxShadow: '0 1px 4px rgba(0,0,0,.15)',
      overflow: 'hidden',
      backgroundColor: colors.white,
      editableCalculator: {
        boxShadow: 'none',
      },
      [mediaQueries.breakpointTablet]: {
        borderRadius: 0,
      },
    },

    sliderWrapper: {
      backgroundColor: colors.primary,
      padding: '15px 24px 5px 24px',
      editableCalculator: {
        padding: '30px 24px 5px 24px',
      },
    },
    offerWrapper: {
      backgroundColor: colors.white,
      padding: '12px 24px 0',
    },

    applyButtonWrapper: {
      backgroundColor: colors.white,
      padding: '0 24px 20px',
    },

    applyButton: {
      marginBottom: '15px',
    },

    noBorderRadius: {
      borderRadius: 0,
    },

    infoFormWrapper: {
      textAlign: 'center',
      paddingBottom: '10px',
    },

    infoFormLink: {
      display: 'inline-block',
      verticalAlign: 'middle',
      cursor: 'pointer',
      transition: 'all .2s',
      lineHeight: '12px',
      textDecoration: 'none',
      color: colors.secondary,
      borderBottom: `1px solid ${colors.secondary}`,
      fontWeight: 400,
      ':hover': {
        color: colors.primaryDarker,
        borderBottom: `1px solid ${colors.primaryDarker}`,
      },
    },

    title: {
      backgroundColor: colors.primary,
      color: colors.white,
      fontSize: '2.6rem',
      fontWeight: 500,
      padding: '24px 24px 5px',
      [mediaQueries.breakpointLaptop]: {
        fontSize: '2rem',
      },
      [mediaQueries.breakpointLaptopSmall]: {
        fontSize: '2.6rem',
      },
    },
  })
}

export default Calculator;
