import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

@radium
class ListCarouselStyles extends StyledComponent {
  static styleRoot = 'ListCarouselStyles'

  static propTypes = {
    collapsed: RPT.bool,
  }

  renderCarouselStyles = () => (
    <Style
      rules={{
          '.slick-list': {
            width: '100%',
            overflow: 'hidden',
          },
          '.slick-track': {
            position: 'relative',
            left: '0',
            top: '0',
            display: 'block',
          },
          '.slick-slide': {
            float: 'left',
            height: '100%',
            minHeight: '1px',
          },
          '.slick-dots': {
            listStyle: 'none',
            margin: 0,
            padding: 0,
          },
          '.slick-dots li': {
            display: 'inline-block',
            margin: '0 8px',
          },
          '.slick-dots li button': {
            backgroundColor: 'hsl(0, 0%, 70%)',
            border: 0,
            borderRadius: '50%',
            textIndent: '-9999px',
            height: '12px',
            width: '12px',
          },
          '.slick-dots li.slick-active button': {
            backgroundColor: '#A6CE39',
          },
          '.homepage-list ul': {
            marginTop: '5px',
            marginBottom: 0,
            listStyleType: 'none',
            paddingLeft: 0,
          },
        }}
    />
  )

  renderListStyles() {
    const style = this.getStyle();

    return (
      <Style
        rules={{
          '.homepage-list ul': {
            marginTop: '14px',
            marginBottom: 0,
            listStyleType: 'none',
            paddingLeft: 0,
          },
          '.homepage-list ul li': {
            fontSize: '16px',
            lineHeight: '1.9',
            textIndent: '-5px',
            paddingLeft: '28px',
          },
          '.homepage-list ul li:before': {
            content: '\'\'',
            borderTop: `2px solid ${style.listIcon.color}`,
            width: '11px',
            height: '2px',
            textIndent: '-11px',
            display: 'block',
            position: 'relative',
            top: '15px',
            left: '-28px',
          },
        }}
      />
    );
  }

  renderListMobileStyles = () => (
    <Style
      rules={{
          '.homepage-list ul': {
            maxWidth: '450px',
            textAlign: 'left',
            display: 'inline-block',
          },
        }}
    />
  )

  render() {
    const { collapsed } = this.props;

    return (
      <span>
        {this.renderListStyles()}
        {collapsed && this.renderListMobileStyles()}
        {collapsed && this.renderCarouselStyles()}
      </span>
    );
  }

  static style = ({ colors }) => ({
    listIcon: {
      color: colors.primary,
    },
  })
}

export default ListCarouselStyles;
