import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';
import Icon from '../../components/Icon.react';

@radium
class FooterLink extends StyledComponent {
  static styleRoot = 'FooterLink'

  static propTypes = {
    color: RPT.string,
    children: RPT.any,
    disabled: RPT.bool,
    iconSize: RPT.number,
    kind: RPT.string,
    onClick: RPT.func,
    to: RPT.string,
    type: RPT.oneOf(['base', 'contact', 'social']),
  }

  static defaultProps = {
    type: 'base',
    iconSize: 22,
  }

  renderIcon() {
    const {
      color, iconSize, kind, type,
    } = this.props;
    const style = this.getStyle();

    return <Icon kind={kind} size={iconSize || 22} color={color || style[type].icon.color} style={[style.icon, type && style[type].icon]} />;
  }

  render() {
    const {
      children, disabled, kind, onClick, to, type,
    } = this.props;
    const style = this.getStyle();

    return (
      <a
        href={to}
        key={to + kind}
        style={[style[type].link, disabled && style.disabled]}
        onClick={onClick}
      >
        {kind && this.renderIcon()}
        <span style={style[type].text} key={to}>{children}</span>
      </a>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    base: {
      link: {
        marginRight: '20px',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: colors.gray,
        color: colors.gray,
        textDecoration: 'none',
        lineHeight: 1,
        display: 'inline-block',
        ':hover': {
          textDecoration: 'none',
          borderBottomColor: colors.grayLight,
          color: colors.grayLight,
        },
      },
      text: {},
      icon: {
        marginRight: '5px',
        color: colors.gray,
      },
    },
    contact: {
      link: {
        cursor: 'pointer',
        color: colors.white,
        display: 'inline-block',
        fontSize: '18px',
        position: 'relative',
        textDecoration: 'none',
        background: 'none',
        border: 'none',
        outline: 'none',
        marginBottom: '12px',
        ':hover': {
          textDecoration: 'none',
        },
        [mediaQueries.breakpointLaptopSmall]: {
          display: 'block',
        },
      },
      text: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: colors.white,
        ':hover': {
          borderBottomColor: 'transparent',
        },
      },
      icon: {
        marginRight: '5px',
        color: colors.white,
      },
    },
    social: {
      link: {
        borderBottomStyle: 'none',
      },
      text: {},
      icon: {
        marginRight: '20px',
        color: colors.black,
      },
    },
    icon: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    disabled: {
      opacity: .5,
      cursor: 'default',
      pointerEvents: 'none',
    },
  })
}

export default FooterLink;
