import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';
import Icon from '../../components/Icon.react';

@radium
class QuickContactsIcon extends StyledComponent {
  static styleRoot = 'QuickContactsIcon'

  static propTypes = {
    disabled: RPT.bool,
    isScrolledDown: RPT.bool,
    kind: RPT.string.isRequired,
    last: RPT.bool,
    onClick: RPT.func,
    to: RPT.string,
  }

  renderIcon = () => {
    const { kind, last } = this.props;
    const style = this.getStyle();

    return (
      <Icon
        color="white"
        kind={kind}
        size={22}
        wrapperStyle={[style.icon, last && style.last]}
      />
    );
  }

  render() {
    const {
      disabled, isScrolledDown, onClick, to,
    } = this.props;
    const style = this.getStyle();

    if (isScrolledDown) {
      return (
        <a
          href={to}
          onClick={onClick}
          style={[style.link, disabled && style.disabled]}
        >
          {this.renderIcon()}
        </a>
      );
    }

    return (
      this.renderIcon()
    );
  }

  static style = ({ colors }) => ({
    icon: {
      padding: '0 14px',
      display: 'inline-block',
      verticalAlign: 'top',
      borderRight: `1px solid ${colors.white}`,
    },
    link: {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: 0,
      background: 0,
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    disabled: {
      opacity: .5,
      cursor: 'default',
    },
    last: {
      borderRight: 'none',
    },
  })
}

export default QuickContactsIcon;
