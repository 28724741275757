import React from 'react';
import translate from '4finance-translate';
import { connectSubmit, connectField } from '4finance-onion-form';
import { Checkbox, TextField, DateField, Select, Button } from '4finance-components';
import { IoBlackBox as IoBlackBoxField } from '4finance-iovation';
import PersonalIdField from './fields/PersonalIdField.react';
import SubmitWithError from './SubmitWithError.react';
import UrlLabel from './fields/UrlLabel.react';
import employmentStatuses from '../../common/constants';
import GoogleAddress from '../registration/GoogleAddress.react';

export { ScrollingForm } from '4finance-onion-form';
export const AcceptAgreement = translate(connectField('acceptAgreement', ({ cntVariables }) => ({ children: <UrlLabel path="personalDetails.acceptAgreement" cntVariables={cntVariables} /> }))(Checkbox));
export const AcceptDataSharing = translate(connectField('acceptDataSharing', { children: <UrlLabel path="personalDetails.acceptDataSharing" /> })(Checkbox));
export const AgreeGetNews = translate(connectField('agreeGetNews', { children: <UrlLabel path="personalDetails.agreeGetNews" /> })(Checkbox));
export const BankAccountNumber = translate(connectField('bankAccountNumber', { labelClass: 'iban-label' })(TextField));
export const City = translate(connectField('location2')(TextField));
export const DateOfBirth = translate(connectField('dateOfBirth', { minimumAge: 21, maximumAge: 112, errorWithLabel: false })(DateField));
export const Email = translate(connectField('email', { type: 'email' })(TextField));
export const EmploymentStatus = translate(connectField('employmentStatus', ({ msg }) => ({
  size: 9,
  placeholder: msg('form.financeAndAddress.employmentStatus.label'),
  options:  employmentStatuses
    .map(({ key, value }) => ({ value, text:  msg(`form.financeAndAddress.employmentStatus.${key}`) })),
}))(Select));
export const FirstName = translate(connectField('firstName', ({ cnt }) => ({ hint: cnt('form.personalDetails.firstName.hint') }))(TextField));
export const Floor = translate(connectField('location5', { width: 'medium' })(TextField));
export const HouseNumber = translate(connectField('location4', { width: 'medium' })(TextField));
export const IoBlackBox = connectField('ioBlackBox', {})(IoBlackBoxField);
export const LandLinePhone = translate(connectField('landLinePhone')(TextField));
export const LastName = translate(connectField('lastName', ({ cnt }) => ({ hint: cnt('form.personalDetails.lastName.hint') }))(TextField));
export const Letter = translate(connectField('location6', { width: 'medium' })(TextField));
export const MobilePhone = translate(connectField('mobilePhone', ({ cnt }) => ({ width: 'medium', hint: cnt('form.personalDetails.mobilePhone.hint') }))(TextField));
export const MonthlyIncome = translate(connectField('monthlyIncome', ({ msg }) => ({
  size: 7,
  placeholder: msg('form.financeAndAddress.monthlyIncome.label'),
  options: [
    { value: 200, text: msg('form.financeAndAddress.monthlyIncome.option1') },
    { value: 450, text: msg('form.financeAndAddress.monthlyIncome.option2') },
    { value: 600, text: msg('form.financeAndAddress.monthlyIncome.option3') },
    { value: 800, text: msg('form.financeAndAddress.monthlyIncome.option4') },
    { value: 1000, text: msg('form.financeAndAddress.monthlyIncome.option5') },
    { value: 1200, text: msg('form.financeAndAddress.monthlyIncome.option6') },
    { value: 1600, text: msg('form.financeAndAddress.monthlyIncome.option7') },
  ],
}))(Select));
export const Password = translate(connectField('password', ({ cnt }) => ({ type: 'password', width: 'medium', hint: cnt('form.personalDetails.password.hint') }))(TextField));
export const PersonalId = translate(connectField('personalId', ({ cnt }) => ({ width: 'medium', hint: cnt('form.personalDetails.personalId.hint') }))(PersonalIdField));
export const PostalCode = translate(connectField('postalCode', ({ inheritedStyle }) => ({ type: 'number', inheritedStyle }))(TextField));
export const Province = translate(connectField('location1', ({ msg }) => ({
  placeholder: msg('form.financeAndAddress.location1.label'),
  options: [
    { value: 'Barcelona' },
    { value: 'Madrid' },
    {},
    { value: 'Álava' },
    { value: 'Albacete' },
    { value: 'Alicante' },
    { value: 'Almería' },
    { value: 'Asturias' },
    { value: 'Ávila' },
    { value: 'Badajoz' },
    { value: 'Baleares' },
    { value: 'Burgos' },
    { value: 'Cáceres' },
    { value: 'Cádiz' },
    { value: 'Cantabria' },
    { value: 'Castellón' },
    { value: 'Ceuta' },
    { value: 'Ciudad Real' },
    { value: 'Córdoba' },
    { value: 'Cuenca' },
    { value: 'Girona' },
    { value: 'Granada' },
    { value: 'Guadalajara' },
    { value: 'Guipúzcoa' },
    { value: 'Huelva' },
    { value: 'Huesca' },
    { value: 'Jaén' },
    { value: 'La Coruña' },
    { value: 'La Rioja' },
    { value: 'Las Palmas' },
    { value: 'León' },
    { value: 'Lleida' },
    { value: 'Lugo' },
    { value: 'Málaga' },
    { value: 'Melilla' },
    { value: 'Murcia' },
    { value: 'Navarra' },
    { value: 'Orense' },
    { value: 'Palencia' },
    { value: 'Pontevedra' },
    { value: 'Salamanca' },
    { value: 'Santa Cruz de Tenerife' },
    { value: 'Segovia' },
    { value: 'Sevilla' },
    { value: 'Soria' },
    { value: 'Tarragona' },
    { value: 'Teruel' },
    { value: 'Toledo' },
    { value: 'Valencia' },
    { value: 'Valladolid' },
    { value: 'Vizcaya' },
    { value: 'Zamora' },
    { value: 'Zaragoza' },
  ],
}))(Select));
export const SecondLastName = translate(connectField('secondLastName', ({ cnt }) => ({ hint: cnt('form.personalDetails.secondLastName.hint') }))(TextField));
export const Street = translate(connectField('location3', { width: 'medium', bottomMargin: false, topMargin: false })(GoogleAddress));
export const Submit = translate(connectSubmit(SubmitWithError));
export const FormSubmitButton = connectSubmit(Button);

