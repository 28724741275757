import config from '4finance-configuration';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import { connectState } from '../connect';

@connectState({
  authorizationToken: ['persistence', 'authorizationToken'],
})
class CrossLogin extends Component {
  /* Usage:

    import { crossLoginRedirect } from '../../common/crossLogin/actions';
    <CrossLogin afterLoginAction={() => crossLoginRedirect()} />

    Without afterLoginAction if will just sit there silently, which is good if you want to do this in registration
    crossLoginImageGenerator method is called from both of the components lifecycle method because the image generation
    should happen for different scenarios:

    1. on login modal ( the authentication happens on this page,
    no additional redirection, e.g the component will not mount self again)
    2. on 2nd registration step, the auth happen on the first page of the registration.
  */

  static propTypes = {
    afterLoginAction: RPT.func,
    authorizationToken: RPT.string,
    children: RPT.any,
  }

  constructor(props) {
    super(props);
    this.crossLoginImageGenerator = this.crossLoginImageGenerator.bind(this);
  }

  componentDidMount() {
    this.crossLoginImageGenerator();
  }

  componentDidUpdate() {
    this.crossLoginImageGenerator();
  }

  crossLoginImageGenerator() {
    const { afterLoginAction, authorizationToken } = this.props;

    if (!authorizationToken) {
      return;
    }

    const crossLoginImageUrl = `${config.originalProductDomain}/cross-login.png?token=${authorizationToken}`;
    const img = new Image();

    if (afterLoginAction) {
      img.onload = () => {
        afterLoginAction();
      };

      img.onerror = (error) => {
        afterLoginAction(error);
      };
    }

    img.src = `${crossLoginImageUrl}&r${Math.random()}`;
  }

  render() {
    const { afterLoginAction, children } = this.props;

    return (
      <div>
        {afterLoginAction && children}
      </div>
    );
  }
}

export default CrossLogin;
