import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, Heading, StyledComponent } from '4finance-components';
import { Form } from '4finance-onion-form';
import Loader from '../components/Loader.react';
import ProgressBar from '../components/ProgressBar.react';
import { connectActions, connectState } from '../connect';
import { IoBlackBox } from '../components/fields';

const IE_OR_SAFARI_BROWSERS = ['IE', 'Safari', 'Mobile Safari'];

@connectState({
  browser: ['device', 'userAgent', 'family'],
})
@connectActions({
  confirmApplicationAndCheckFraud: ['registration', 'confirmApplicationAndCheckFraud'],
})
@translate
@radium
class CreditCheck extends StyledComponent {
  static styleRoot = 'CreditCheck'

  static propTypes = {
    confirmApplicationAndCheckFraud: RPT.func,
    location: RPT.object,
    msg: RPT.func,
  }

  static style = () => ({
    container: {
      textAlign: 'center',
    },
    subheading: {
      fontSize: '1.3em',
      fontWeight: 400,
    },
  })

  componentDidMount() {
    const { confirmApplicationAndCheckFraud, browser, location: { query } } = this.props;

    // To fix an issue with Loader in Safari/IE11 we need to reload a page
    if (process.env.IS_BROWSER) {
      const isIeOrSafari = IE_OR_SAFARI_BROWSERS.indexOf(browser) !== -1;

      if (isIeOrSafari && !query.reload) {
        window.location = `${window.location.pathname}?reload=false`;
      } else {
        confirmApplicationAndCheckFraud();
      }
    }
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <Form name="creditCheck">
        <ProgressBar step={2} />

        <Container style={style.container}>
          <Heading centered kind="h1">
            {msg('registration.creditCheck.heading')}
          </Heading>

          <p style={style.subheading}>
            {msg('registration.creditCheck.subheadingText')}
          </p>

          <Loader variant="sectionsCss" />

          <IoBlackBox />
        </Container>
      </Form>
    );
  }
}

export default CreditCheck;
