import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, StyledComponent } from '4finance-components';
import FooterLinks from './FooterLinks.react';

@translate
class FooterBottom extends StyledComponent {
  static styleRoot = 'FooterBottom'

  static propTypes = {
    msg: RPT.func,
    withMargin: RPT.bool,
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Container>
          <FooterLinks />
          <a href="https://www.4finance.com/" style={style.copy}>{msg('footer.copyright')}</a>
        </Container>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      backgroundColor: colors.white,
      paddingTop: '30px',
      paddingBottom: '50px',
    },

    copy: {
      color: colors.monotone3,
      cursor: 'pointer',
      display: 'block',
      clear: 'both',
      fontSize: '1.4rem',
      textDecoration: 'none',
      fontWeight: 400,
      paddingTop: '20px',
      ':hover': {
        textDecoration: 'underline',
      },
      [mediaQueries.breakpointLaptopSmall]: {
        textAlign: 'center',
      },
    },
  });
}

export default FooterBottom;
