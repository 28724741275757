import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import {
  Button,
  Container,
  Heading,
  Column,
  Row,
  StyledComponent,
  ListItemConected,
} from '4finance-components';
import { connectActions } from '../connect';
import ProgressBar from '../components/ProgressBar.react';
import SideBox from './SideBox.react';

@connectActions({
  completeApplicationProcess: ["registration", "completeApplicationProcess"],
  sendAffiliateEventsQualified: ["registration", "sendAffiliateEventsQualified"],
})
@translate
@radium
class EmailOrWhatsapp extends StyledComponent {
  static styleRoot = 'EmailOrWhatsapp';

  componentDidMount() {
    const { sendAffiliateEventsQualified } = this.props;

    sendAffiliateEventsQualified();
  }

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    completeApplicationProcess: RPT.func.isRequired,
    sendAffiliateEventsQualified: RPT.func.isRequired,
  };

  renderList() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();
    const listNumberStyle = {
      wrapper: style.numberWrapper,
      text: style.numberText,
    };

    return (
      <div style={style.list}>
        <ListItemConected
          number={1}
          last
          style={{ base: style.list.item, number: listNumberStyle }}
        >
          <div style={style.list.wrapperFirstSection}>
            <Heading kind="h4" inheritedStyle={style.list.heading}>
              {msg('identification.emailOrWhatsapp.list.first_heading')}
            </Heading>
            <p style={style.list.text}>
              {msg('identification.emailOrWhatsapp.list.image_instructions')}
            </p>
            <div style={style.list.imgWrapper}>
              <img
                alt="Documents"
                src="/assets/img/documents.png"
                style={style.list.img}
              />
            </div>
          </div>
        </ListItemConected>
        <ListItemConected
          number={2}
          last
          style={{ base: style.list.item, number: listNumberStyle }}
        >
          <Heading kind="h4" inheritedStyle={style.list.heading}>
            {msg('identification.emailOrWhatsapp.list.second_heading')}
          </Heading>
          <div style={style.list.contactWrapper}>
            <div style={[style.list.contact, style.list.contact.second]}>
              <img
                src="/assets/img/email.svg"
                alt="email"
                style={[style.list.icon, style.list.icon.email]}
              />
              <div style={style.list.link}>
                <strong style={style.list.contactHeading}>
                  {msg('identification.emailOrWhatsapp.list.emailHeading')}
                </strong>
                <p style={style.list.contactText}>
                  {cnt('identification.emailOrWhatsapp.list.emailText', {
                    email: 'documentacion@vivus.es',
                  })}
                </p>
              </div>
            </div>
          </div>
        </ListItemConected>
      </div>
    );
  }

  render() {
    const { msg, completeApplicationProcess } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <ProgressBar step={3} />
        <Container style={style.wrapper}>
          <Row>
            <Column
              large={8}
              medium={8}
              small={12}
              phone={12}
              tablet={12}
              style={style.column}
            >
              <div style={style.headingWrapper}>
                <Heading inheritedStyle={style.heading} noMargin kind="h4">
                  {msg('identification.emailOrWhatsapp.title')}
                </Heading>
              </div>
              <div>
                {this.renderList()}
                <div style={style.buttonWrapper}>
                  <Button
                    centered
                    noMargin
                    id="uploadButton"
                    kind="primary"
                    onClick={completeApplicationProcess}
                  >
                    {msg('identification.emailOrWhatsapp.upload_button')}
                  </Button>
                </div>
              </div>
            </Column>
            <Column large={4} medium={4} small={12} phone={12} tablet={12}>
              <SideBox />
            </Column>
          </Row>
        </Container>
      </div>
    );
  }

  static style = ({ mediaQueries, colors }) => ({
    wrapper: {
      marginTop: '60px',
    },

    column: {
      [mediaQueries.breakpointLaptopSmall]: {
        marginLeft: 0,
      },
    },

    headingWrapper: {
      backgroundColor: colors.primary,
      borderRadius: '10px 10px 0 0',
      padding: '16px 22px',
    },

    heading: {
      color: colors.white,
      fontWeight: 400,
    },

    numberText: {
      lineHeight: '32px',
      fontSize: '1.7rem',
      color: colors.white,
      fontWeight: 300,
    },

    numberWrapper: {
      width: '32px',
      height: '32px',
      backgroundColor: colors.primary,
    },

    list: {
      backgroundColor: colors.white,
      padding: '0 40px',
      [mediaQueries.breakpointSmall]: {
        padding: '0 10px',
      },
      contactWrapper: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        [mediaQueries.breakpointLaptop]: {
          display: 'block',
        },
      },
      text: {
        color: colors.monotone3,
        fontSize: '1.4rem',
        marginBottom: 0,
      },
      contact: {
        display: 'inline-block',
        width: '50%',
        [mediaQueries.breakpointLaptop]: {
          width: '100%',
        },
        first: {
          padding: '0 30px 0 0',
        },
        second: {
          padding: 0,
          [mediaQueries.breakpointLaptop]: {
            padding: 0,
            textAlign: 'left',
          },
        },
      },
      contactHeading: {
        fontSize: '2rem',
        marginBottom: '3px',
        fontWeight: 700,
      },
      contactText: {
        margin: 0,
        fontWeight: 500,
        fontSize: '1.6rem',
      },
      link: {
        display: 'inline-block',
        fontSize: '18px',
      },
      heading: {
        color: colors.black,
        fontWeight: 500,
        marginTop: '6px',
        marginBottom: 0,
        lineHeight: '1.3',
      },
      icon: {
        color: colors.primary,
        marginRight: '20px',
        width: '38px',
        email: {
          position: 'relative',
          top: '-4px',
        },
      },
      wrapperFirstSection: {
        [mediaQueries.breakpointTablet]: {
          paddingRight: '53px',
        },
      },
      img: {
        maxWidth: '90%',
      },
      imgWrapper: {
        marginTop: '30px',
      },
      item: {
        padding: '20px 0 35px 49px',
        borderBottom: '1px solid #cecece',
        [mediaQueries.breakpointLaptop]: {
          padding: '20px 0 20px 56px',
        },
      },
    },

    buttonWrapper: {
      backgroundColor: '#f5f7fa',
      borderRadius: '0 0 10px 10px',
      boxShadow: '0 1px 1px 0 rgba(0,0,0,.1)',
      padding: '30px 25px',
      textAlign: 'center',
    },
  });
}

export default EmailOrWhatsapp;
