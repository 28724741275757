/* eslint-disable */
import radium from 'radium';
import React from 'react';
import { StyledComponent } from '4finance-components';

@radium
class Logo extends StyledComponent {

  static styleRoot = 'Logo'

  render() {
		const style = this.getStyle();

    return (
      <svg id="Layer_1" style={style.logo} viewBox="0 0 314.7 49.55">
      	<path d="M53.1,25.1V10.8C53.1,4.8,48.3,0,42.4,0h-2.5v6.4c0,8.9-7.2,16.1-16.1,16.1h-6.4v2.6c0,5.9,4.8,10.7,10.7,10.7
      		h14.3C48.3,35.8,53.1,31,53.1,25.1" fill="#A6CE39"
        />
        <path d="M34.4,6.4V0H28c-5.9,0-10.7,4.8-10.7,10.8v6.4h6.4C29.6,17.2,34.4,12.3,34.4,6.4" fill="#006D38"/>
      	<path d="M4.6,5H0v4.6c0,4.2,3.4,7.6,7.6,7.6h4.6v-4.6C12.2,8.4,8.8,5,4.6,5" fill="#006D38"/>
      	<path d="M91.6,10.6v24c0,0,0.1,0.9,1.1,0.9h4.1c0,0,0.9-0.1,0.9-1V10.6c0,0,0-1.1-1.1-1.1h-4
      		C92.5,9.4,91.6,9.4,91.6,10.6" fill="#65696E"
        />
      	<path d="M106.6,9.6h-3.6c0,0-1.3-0.1-1,1.1c0.3,1.2,6,21.7,6,21.7s1.2,3.4,5.5,3.4c4.3,0,5.3-3.3,5.3-3.3l6.1-21.5
      		c0,0,0.5-1.6-0.7-1.6h-4.3c0,0-1,0.1-1.2,1.2c-0.2,1.1-4.4,19.5-4.4,19.5s-0.1,0.7-0.7,0.7c-0.7,0-0.9-0.8-0.9-0.8l-4.4-19.4
      		C108.3,10.7,108.1,9.5,106.6,9.6" fill="#65696E"
        />
      	<path d="M68.3,9.6h-3.6c0,0-1.3-0.1-1,1.1c0.3,1.2,6,21.7,6,21.7s1.2,3.4,5.5,3.4c4.3,0,5.3-3.3,5.3-3.3L86.6,11
      		c0,0,0.5-1.6-0.7-1.6h-4.3c0,0-1,0.1-1.2,1.2C80.1,11.7,76,30.2,76,30.2s-0.1,0.7-0.7,0.7c-0.7,0-0.9-0.8-0.9-0.8L70,10.7
      		C70,10.7,69.8,9.5,68.3,9.6" fill="#65696E"
        />
      	<path d="M128.9,10.4v16.1c0,0,0.3,9.2,10.6,9.2c10.3,0,10.4-8.7,10.4-8.7V10.5c0,0,0.1-1.1-1-1.1h-4c0,0-0.9-0.2-0.9,1
					v16.7c0,0-0.5,3.5-4.5,3.5c-4,0-4.6-3.5-4.6-3.5V10.9c0,0,0.1-1.4-1.1-1.4h-4.2C129.8,9.4,128.9,9.4,128.9,10.4"
					fill="#65696E"
				/>
      	<path d="M171.2,14.5c0,0-4.9-0.5-7-0.4c-2.1,0-3.3,0.3-3.3,1.8c0,1.5,1.8,2.2,3.3,3c1.6,1,5.4,3.1,6.5,4.1
      		c1.1,1,2.6,2.6,2.6,5.5c0,2.8-1.5,5.6-4.2,6.5c-2.8,1-7.9,0.7-10,0.4c-2.1-0.3-2.7-0.5-3-0.6c-0.4-0.1-0.9-0.4-0.9-1.2v-2.3
      		c0,0,0-1.3,1.4-1c1.4,0.3,5.3,0.8,7.2,0.7c2-0.1,3.3-0.3,3.5-2c0.1-1.7-1.9-2.4-3.9-3.7c-2-1.3-3.8-2.2-5.4-3.5
      		c-1.5-1.3-3-3.6-3-6.2c0-2.6,1.8-6.3,8.5-6.3c5.9,0,8.1,0.7,8.4,0.8c0.3,0.1,0.8,0.3,0.8,1.1c0,0.7,0,2.6,0,2.6
      		S172.8,14.7,171.2,14.5" fill="#65696E"
        />
				<path d="M91.6,1.2v3.9c0,0,0.1,1,0.9,1h4.1c0,0,1,0.1,1-1V1.2c0,0,0.2-1.1-1.3-1.1h-3.6C91.4,0.1,91.6,1.2,91.6,1.2"
					fill="#65696E"
				/>
    		<path d="M185.4,35.5c-0.1,0.1-0.3,0.2-0.5,0.2h-4.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.2-0.3-0.2-0.5v-4.4
    			c0-0.2,0.1-0.3,0.2-0.5c0.2-0.1,0.3-0.2,0.5-0.2h4.1c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5V35
    			C185.7,35.2,185.6,35.3,185.4,35.5z" fill="#A4CE3A"
        />
    		<path d="M206.8,35.5c-0.6,0.1-1.4,0.2-2.4,0.3c-1,0.1-2.5,0.2-4.3,0.2c-1.1,0-2.2-0.1-3.2-0.3c-1-0.2-1.9-0.5-2.7-1.1
    			c-0.8-0.5-1.4-1.3-1.9-2.3c-0.5-1-0.7-2.3-0.7-3.9V16.9c0-1.6,0.2-2.9,0.7-3.8c0.5-1,1.1-1.7,1.9-2.3c0.8-0.5,1.7-0.9,2.7-1.1
    			c1-0.2,2.1-0.3,3.2-0.3c0.9,0,1.8,0,2.5,0.1c0.7,0,1.3,0.1,1.9,0.1c0.5,0,1,0.1,1.4,0.1c0.4,0,0.7,0.1,1,0.1
    			c0.5,0.1,0.7,0.3,0.7,0.7v3.2c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2h-7.1c-0.8,0-1.4,0.2-1.7,0.5
    			c-0.3,0.3-0.5,1-0.5,1.9v3.2h9c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v3.3c0,0.2-0.1,0.3-0.2,0.5
    			c-0.1,0.1-0.3,0.2-0.5,0.2h-9v3.7c0,0.9,0.2,1.5,0.5,1.9c0.3,0.3,0.9,0.5,1.7,0.5h7.1c0.2,0,0.3,0.1,0.5,0.2
    			c0.1,0.1,0.2,0.3,0.2,0.5v3.2C207.5,35.2,207.3,35.5,206.8,35.5z" fill="#A4CE3A"
        />
    		<path d="M229.9,28.3c0,0.9-0.2,1.9-0.5,2.8c-0.3,0.9-0.9,1.7-1.6,2.4c-0.8,0.7-1.7,1.3-3,1.8
    			c-1.2,0.5-2.7,0.7-4.5,0.7c-1.4,0-2.6-0.1-3.7-0.2c-1.1-0.1-2-0.3-2.9-0.6c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.4
    			c0-0.3,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.1,0.5-0.1h0.1c0.3,0,0.8,0.1,1.4,0.1c0.6,0,1.3,0.1,1.9,0.1c0.7,0,1.3,0.1,1.9,0.1
    			c0.6,0,1.1,0.1,1.4,0.1c1.2,0,2-0.2,2.5-0.6c0.4-0.4,0.6-1,0.6-1.9c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.4-0.9-0.8-1.8-1.3l-4.8-2.9
    			c-0.8-0.5-1.5-1-2-1.5c-0.5-0.5-0.9-1-1.3-1.6c-0.3-0.6-0.5-1.1-0.7-1.7c-0.1-0.6-0.2-1.2-0.2-1.8c0-1,0.1-1.8,0.4-2.7
    			c0.3-0.8,0.8-1.5,1.5-2.2c0.7-0.6,1.6-1.1,2.8-1.4c1.1-0.3,2.6-0.5,4.3-0.5c0.6,0,1.3,0,2,0.1c0.7,0,1.4,0.1,2.1,0.2
    			c0.7,0.1,1.3,0.2,1.9,0.2c0.6,0.1,1,0.2,1.4,0.3c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4v3.5c0,0.2,0,0.3-0.1,0.4
    			c-0.1,0.1-0.2,0.2-0.5,0.2h-0.1c-0.3,0-0.7-0.1-1.2-0.1c-0.6,0-1.2-0.1-1.9-0.1c-0.7,0-1.5-0.1-2.2-0.1c-0.8,0-1.4-0.1-2-0.1
    			c-1,0-1.7,0.2-2.1,0.5c-0.4,0.3-0.6,0.8-0.6,1.4c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.2,0.4,0.4,0.7,0.6
    			c0.3,0.2,0.7,0.4,1.2,0.7l4.5,2.4c1.8,1,3.1,2,3.7,3.2C229.6,25.6,229.9,26.9,229.9,28.3z" fill="#A4CE3A"
        />
      </svg>
    );
  }

  static style = ({ mediaQueries }) => ({
    logo: {
      width: 'auto',
      height: '45px',
      verticalAlign: 'middle',
      [mediaQueries.breakpointLaptopSmall]: {
        paddingTop: '3px',
        height: '40px'
      },
      [mediaQueries.breakpointTablet]: {
        paddingTop: '5px',
        height: '35px'
      }
		},
  })
}

export default Logo;