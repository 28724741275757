import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Menu as MenuComponent, StyledComponent } from '4finance-components';
import config from '4finance-configuration';
import connectRoutes from '../../../common/lib/connectRoutes';
import Icon from '../../components/Icon.react';
import Logo from '../../components/Logo.react';
import MenuItem from './MenuItem.react';
import { connectActions, connectState } from '../../connect';

@connectState({
  isLoggedIn: ['authentication', 'isLoggedIn'],
  isSideMenuOpen: ['ui', 'isSideMenuOpen'],
})
@connectActions({
  toggleSideMenu: ['ui', 'toggleSideMenu'],
})
@connectRoutes({
  isRootPage: '/',
})
@translate
@radium
class Menu extends StyledComponent {
  static styleRoot = 'Menu'

  static propTypes = {
    isLoggedIn: RPT.bool.isRequired,
    isSideMenuOpen: RPT.bool,
    menuShown: RPT.bool.isRequired,
    msg: RPT.func.isRequired,
    pathname: RPT.string,
    toggleSideMenu: RPT.func.isRequired,
  }

  static defaultProps = {
    menuShown: true,
  }

  renderMenuToggle() {
    const { isSideMenuOpen, toggleSideMenu } = this.props;
    const style = this.getStyle();
    const iconColor = radium.getState(this.state, 'menuToggle', ':hover')
      ? 'hsl(0, 0%, 60%)' : 'hsl(0, 0%, 75%)';

    return (
      <a id="menuToggle" data-test="menuToggle" key="menuToggle" onClick={() => toggleSideMenu()} style={style.menuToggle}>
        <Icon color={iconColor} kind={isSideMenuOpen ? 'close-thin' : 'menu'} width={60} />
      </a>
    );
  }

  renderLogo(style) {
    const { pathname } = this.props;
    const isRegistrationPage = pathname && pathname !== '/registration' && pathname.indexOf('registration') > -1;
    const logo = isRegistrationPage ? (
      <div style={style.logo}>
        <Logo />
      </div>
    ) : (
      <a style={style.logo} href="https://www.vivusonline.es/">
        <Logo />
      </a>
    );

    return logo;
  }

  render() {
    const {
      menuShown, msg, isLoggedIn, isRootPage,
    } = this.props;
    const style = this.getStyle();

    return (
      <div>
        {!isLoggedIn && (
          <div style={style.login}>
            <a style={style.link} href={`${config.dashboardDomain}/online-login`}>
              {msg('menu.login')}
            </a>
          </div>)}

        {this.renderLogo(style)}

        {menuShown &&
          <div style={style.menu}>
            <MenuComponent LoginModal="">
              {isRootPage && <MenuItem
                label={msg('menu.how_it_works')}
                name="how_it_works"
                scrollable
              />}
              {!isLoggedIn && <MenuItem
                bordered
                isExternal
                to={`${config.dashboardDomain}/online-login`}
                name="login-redirect"
                label={msg('menu.login')}
              />}
            </MenuComponent>
          </div>
        }
        {menuShown && this.renderMenuToggle()}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    login: {
      display: 'none',
      [mediaQueries.breakpointTablet]: {
        display: 'block',
        float: 'left',
        marginLeft: '-15px',
        padding: '15px 0',
        background: colors.secondary,
        height: '60px',
        width: '60px',
        lineHeight: '1.2',
        textAlign: 'center',
      },
    },
    link: {
      color: colors.white,
      fontSize: '10px',
      textDecoration: 'none',
    },
    logo: {
      float: 'left',
      position: 'relative',
      [mediaQueries.breakpointLaptopSmall]: {
        position: 'static',
      },
      [mediaQueries.breakpointTablet]: {
        marginLeft: 'calc(50vw - 135px)',
        maxWidth: '140px',
      },
    },

    menu: {
      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    menuToggle: {
      width: '60px',
      height: '60px',
      lineHeight: '84px',
      float: 'right',
      textAlign: 'right',
      position: 'relative',
      borderLeft: '1px solid hsl(0, 0%, 75%)',
      cursor: 'pointer',
      display: 'none',
      [mediaQueries.breakpointTablet]: {
        display: 'block',
        marginRight: '-15px',
      },
      ':hover': {},
    },
  })
}

export default Menu;
