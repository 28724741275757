import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, StyledComponent } from '4finance-components';
import Icon from '../components/Icon.react';

@translate
@radium
class SideBox extends StyledComponent {
  static styleRoot = 'SideBox'

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
  }

  renderListStyles() {
    const style = this.getStyle();

    return (
      <Style
        rules={{
          '.identification-list': {
            marginTop: '14px',
            marginBottom: 0,
            listStyleType: 'none',
            paddingLeft: 0,
          },
          '.identification-list li': {
            fontSize: '1.6rem',
            lineHeight: '1.5',
            marginBottom: '10px',
            paddingLeft: '24px',
            fontWeight: 400,
          },
          '.identification-list li:before': {
            content: '\'\'',
            borderTop: `2px solid ${style.listIcon.color}`,
            width: '15px',
            height: '2px',
            textIndent: '-11px',
            display: 'block',
            position: 'relative',
            top: '12px',
            left: '-24px',
          },
        }}
      />
    );
  }

  render() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (
      <div>
        {this.renderListStyles()}
        <div style={style.top}>
          <div style={style.headingWrapper}>
            <Heading kind="h4" noMargin inheritedStyle={style.headingTop}>
              <Icon kind="info" size={22} style={style.icon} />
              {msg('identification.emailOrWhatsapp.heading_first')}
            </Heading>
          </div>
          <ul className="identification-list">
            {cnt('identification.emailOrWhatsapp.sideBoxList')}
          </ul>
        </div>
        <div style={style.content}>
          <Heading kind="h4" inheritedStyle={style.heading}>
            <Icon kind="clock" size={22} style={[style.icon, style.icon.dark]} />
            {msg('identification.emailOrWhatsapp.heading_second')}
          </Heading>
          <div style={style.text}>
            {msg('identification.emailOrWhatsapp.send_later')}
          </div>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    content: {
      backgroundColor: colors.white,
      borderRadius: '0 0 10px 10px',
      padding: '25px',
    },

    heading: {
      marginTop: '0',
      marginBottom: '15px',
      paddingTop: 0,
      paddingLeft: '35px',
      position: 'relative',
      fontWeight: 500,
      fontSize: '1.8rem',
      lineHeight: '1.3',
    },

    headingWrapper: {
      paddingBottom: '15px',
      borderBottom: '1px solid hsla(0,0%,100%,.26)',
    },

    headingTop: {
      paddingLeft: '35px',
      color: colors.white,
      display: 'inline-block',
      fontWeight: 500,
    },

    icon: {
      fill: colors.white,
      marginRight: '10px',
      verticalAlign: 'middle',
      position: 'absolute',
      left: 0,
      top: 0,
      dark: {
        fill: colors.monotone5,
        top: '3px',
      },
    },

    listIcon: {
      color: colors.white,
    },

    text: {
      color: '#9b9b9b',
      fontWeight: 400,
    },

    top: {
      backgroundColor: colors.primaryDarker,
      borderRadius: '10px 10px 0 0',
      color: colors.white,
      padding: '20px 25px 10px',
      [mediaQueries.breakpointLaptopSmall]: {
        marginTop: '30px',
      },
    },
  });
}

export default SideBox;
