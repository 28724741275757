export default function forbidAuth({ replace, getState, dispatch }) {
  const { authentication: { isLoggedIn } } = getState();

  if (!isLoggedIn) {
    return true;
  }

  // dispatch info action why transition did not finish
  dispatch({ type: 'GUARD_FORBID_AUTH_FAILED' });
  replace('/dashboard');
  return false;
}
