import { actions as formActions } from '4finance-onion-form';
import { push as updatePath } from 'react-router-redux';
import { actions as api } from '../api';

export const SUBMIT_PASSWORD_RECOVERY = 'SUBMIT_PASSWORD_RECOVERY';

export function submitPasswordRecovery(payload) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(formActions.clearFormProperty('passwordRecovery', 'apiError'));
      const response = await dispatch(api.sendClientTemporaryPassword(payload));
      const { errors } = response.payload;

      if (response.error) {
        dispatch(formActions.setMultipleFields(
          'passwordRecovery',
          'apiError',
          errors.reduce((acc, err) => ({ ...acc, [err.property]: err.messageTemplate }), {}),
        ));
        throw Error('Password Recovery failed');
      }

      dispatch(updatePath('/enviar-contrasena'));
      return {};
    };

    return {
      type: SUBMIT_PASSWORD_RECOVERY,
      payload: getPromise(),
    };
  };
}
