const mappings = {
  route: 'street',
  street_number: 'number',
  locality: 'city',
  administrative_area_level_2: 'province',
  country: 'country',
  postal_code: 'postalCode',
};

const googleProvince = {
  ARABA: 'Araba',
  ILLES_BALEARES: 'Illes Balears',
  ISLAS_BALEARES: 'Islas Baleares',
  GIPUZKOA: 'Gipuzkoa',
  LERIDA: 'Lérida',
  OURENSE: 'Ourense',
  BIZKAIA: 'Bizkaia',
};

export const provinceMapping = {
  [googleProvince.ARABA]: 'Álava',
  [googleProvince.GIPUZKOA]: 'Guipúzcoa',
  [googleProvince.LERIDA]: 'Lleida',
  [googleProvince.OURENSE]: 'Orense',
  [googleProvince.BIZKAIA]: 'Vizcaya',
  [googleProvince.ISLAS_BALEARES]: 'Baleares',
  [googleProvince.ILLES_BALEARES]: 'Baleares',
};

export function parseAddress(place) {
  return (place
    .address_components || [])
    .reduce((hash, value) => {
      const type = value.types[0];
      const name = value.long_name;
      const mappedInfo = mappings[type];

      if (mappedInfo) {
        const mappedKey = mappedInfo;
        // eslint-disable-next-line no-param-reassign
        hash[mappedKey] = name;
      }

      return hash;
    }, {});
}
