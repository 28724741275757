import { fromJS, Map } from 'immutable';
import * as actions from './actions';

const initialState = fromJS({
  returningUser: false,
});

export default function trackingReducer(state = initialState, action) {
  if (!(Map.isMap(state))) return fromJS(state);

  switch (action.type) {
    case actions.TRACK_LOG_IN_CLICK: {
      return state.set('returningUser', true);
    }
  }

  return state;
}
