import { PureComponent as Component } from 'react';
import config from '4finance-configuration';

class Authentication extends Component {
  componentDidMount() {
    window.location.href = `${config.originalProductDomain}/entrar-ahora-2`;
  }

  render() {
    return null;
  }
}

export default Authentication;
