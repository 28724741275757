export const INCREASE_EKOMI_COUNTER = 'INCREASE_EKOMI_COUNTER';
export const ON_SIDE_MENU_CHANGE = 'ON_SIDE_MENU_CHANGE';
export const TOGGLE_EDITABLE_CALCULATOR = 'TOGGLE_EDITABLE_CALCULATOR';
export const TOGGLE_EXISTING_CLIENT_MODAL = 'TOGGLE_EXISTING_CLIENT_MODAL';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const TABS_CHANGE = 'TABS_CHANGE';
export const UI_CHANGE = 'UI_CHANGE';

export function toggleLoginModal(show) {
  return ({ dispatch }) => {
    dispatch(change('LoginModal', show));

    return {
      payload: show,
      type: 'TOGGLE_LOG_IN_MODAL',
    };
  };
}

export function toggleExistingClientModal(show) {
  return ({ dispatch }) => {
    dispatch(change('ExistingClientModal', show));

    return {
      payload: show,
      type: TOGGLE_EXISTING_CLIENT_MODAL,
    };
  };
}

export function change(name, show) {
  return {
    type: UI_CHANGE,
    payload: {
      name,
      show,
    },
  };
}

export function onSideMenuChange(isOpen) {
  return {
    type: ON_SIDE_MENU_CHANGE,
    payload: { isOpen },
  };
}

export function increaseEkomiCounter() {
  return {
    type: INCREASE_EKOMI_COUNTER,
  };
}

export function toggleEditableCalculator() {
  return {
    type: TOGGLE_EDITABLE_CALCULATOR,
  };
}

export function toggleSideMenu() {
  return {
    type: TOGGLE_SIDE_MENU,
  };
}
