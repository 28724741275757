import { email, isRequired, lettersAndSpace, minLength, maxLength, number, password, validAge } from '4finance-validation';
import IBAN from 'iban';

export const personalDetails = {
  acceptAgreement: [isRequired()],
  agreeGetNews: [],
  acceptDataSharing: [],
  dateOfBirth: [isRequired(), validAge(21, 112, 'YYYY-MM-DD')],
  email: [isRequired(), maxLength(50), email()],
  firstName: [isRequired(), lettersAndSpace(), minLength(3), maxLength(50)],
  lastName: [isRequired(), minLength(2), maxLength(30), lettersAndSpace()],
  mobilePhone: [isRequired(), minLength(9), phone()],
  secondLastName: [minLength(2), maxLength(30), lettersAndSpace()],
  password: [isRequired(), minLength(6), maxLength(30), password()],
  personalId: [isRequired(), maxLength(9), personalId()],
};

export const financeAndAddress = {
  bankAccountNumber: [isRequired(), iban()],
  employmentStatus: [isRequired()],
  location1: [isRequired()], // Province
  location2: [isRequired(), maxLength(100)], // City
  location3: [isRequired(), maxLength(100)], // Street
  location4: [isRequired(), maxLength(100)], // House number
  location5: [maxLength(100)], // Floor
  location6: [maxLength(100)], // Letter
  monthlyIncome: [isRequired()],
  postalCode: [isRequired(), maxLength(5), number()],
};

function iban() {
  const ERROR = 'invalid_iban';

  return (value) => {
    if (value && !IBAN.isValid(value)) {
      return ERROR;
    }
    return null;
  };
}

function phone() {
  const ERROR = 'incorrect_format';

  return (value) => {
    if (!value || !value.match(/^((\+?34([ \t|-])?)?[6|7]((\d{1}([ \t|-])?[0-9]{3})|(\d{2}([ \t|-])?[0-9]{2}))([ \t|-])?[0-9]{2}([ \t|-])?[0-9]{2})$/gi)) {
      return ERROR;
    }

    // eslint-disable-next-line no-param-reassign
    value = value.replace(/(\s|-)/gi, '').replace(/^(\+34)/i, '');
    return null;
  };
}

function personalId() {
  const FORMAT_ERROR = 'invalid_personal_id_format';
  const VALIDATION_ERROR = 'invalid_personal_id';

  return (value) => {
    const upperCaseValue = value.toUpperCase();

    if (!upperCaseValue) {
      return VALIDATION_ERROR;
    }
    // This is only for case, when client has `0` numbers at the
    // beginnig of his Personal ID, which in Spain can be omitted in some cases.
    // E.g. 00123456A can be entered by user as 123456A
    if (upperCaseValue.match(/^[0-9]{1,7}[A-Z]$/i)) {
      return FORMAT_ERROR;
    }

    const controlLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const regexp = /^[\dXY]\d{7}[A-Z]$/;

    if (!upperCaseValue.match(regexp)) {
      return VALIDATION_ERROR;
    }

    const controlLetter = upperCaseValue[upperCaseValue.length - 1];
    let firstCharacter = upperCaseValue[0];

    // according to Spain validations if the first character is X || Y
    // should be replaced with 0 || 1 for checking the lastLetter into the below formula
    if (firstCharacter.match(/[XY]/)) {
      firstCharacter = firstCharacter.charCodeAt(0) - 'X'.charCodeAt(0);
    }

    const personalID = firstCharacter + value.slice(1);
    const digits = personalID.match(/\d+/)[0];
    const expectedControlDigit = parseInt(digits, 10) % 23;
    const expectedControlLetter = controlLetters[expectedControlDigit];

    return controlLetter === expectedControlLetter ? null : VALIDATION_ERROR;
  };
}
