import radium from 'radium';
import React from 'react';
import { StyledComponent, Container } from '4finance-components';
import Icon from './Icon.react';

@radium
class Notification extends StyledComponent {
  static styleRoot = 'Notification'

  render() {
    const { children } = this.props;
    const style = this.getStyle();

    return (
      <Container style={style.wrapper}>
        <Icon kind="exclamation" color={style.icon.color} style={style.icon} />
        <div style={style.children}>
          {children}
        </div>
      </Container>
    );
  }

  static style = ({ colors }) => ({
    wrapper: {
      background: 'white',
      border: `3px solid ${colors.error}`,
      borderRadius: 10,
      color: colors.error,
      marginTop: 35,
      padding: '18px 11px 15px 20px',
    },

    children: {
      display: 'inline-block',
    },

    icon: {
      color: colors.error,
      display: 'inline-block',
      marginRight: 20,
    },
  })
}

export default Notification;
