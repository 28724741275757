import collectMonitoringData from '4finance-monitoring';
import config from '4finance-configuration';
import Helmet from 'react-helmet';
import React from 'react';
import ReactDOM from 'react-dom';
import useScroll from 'react-router-scroll/lib/useScroll';
import { Provider } from 'react-redux';
import { Provider as TranslateProvider } from '4finance-translate';
import { applyRouterMiddleware, match, Router } from 'react-router';
import { AppContainer } from 'react-hot-loader';
import configureStoreWithHistory from './configureStoreWithHistory';
import createRoutes from './createRoutes';

const REACT_NATIVE_APP_USER_AGENT = 'VivusEs';

const { store, history } = configureStoreWithHistory();

const createdRoutes = createRoutes(store.dispatch, store.getState);

const { pathname, search, hash } = window.location;
const location = `${pathname}${search}${hash}`;

const app = routes => (
  <React.Fragment>
    <Helmet>
      {!navigator.userAgent.includes(REACT_NATIVE_APP_USER_AGENT) && (
        <script id="CookieConsent" src="https://policy.app.cookieinformation.com/uc.js" data-culture="ES" type="text/javascript" />
      )}
    </Helmet>
    <AppContainer>
      <Provider store={store}>
        <TranslateProvider config={config.translate} pathname={pathname}>
          <Router history={history} render={applyRouterMiddleware(useScroll())}>
            {routes}
          </Router>
        </TranslateProvider>
      </Provider>
    </AppContainer>
  </React.Fragment>
);

match({ history, createdRoutes, location }, () => { // eslint-disable-line  no-unused-vars
  ReactDOM.render(app(createdRoutes), document.getElementById('app'));
});


if (module.hot) {
  module.hot.accept('./createRoutes', () => {
    const createdHotRoutes = require('./createRoutes')(store.dispatch, store.getState); // eslint-disable-line global-require
    ReactDOM.render(app(createdHotRoutes), document.getElementById('app'));
  });
}

window.onload = collectMonitoringData;
