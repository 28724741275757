import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, Heading, Column, Row, StyledComponent } from '4finance-components';
import Calculator from '../calculator/Calculator.react';
import Header from '../app/Header.react';
import Icon from '../components/Icon.react';
import listenWindowResize, { Device } from '../lib/listenWindowResize.react';
import { connectActions } from '../connect';
import { getPath } from '../routes';

@connectActions({
  updatePath: ["routing", "updatePath"]
})
@translate
@listenWindowResize
@radium
class Proposition extends StyledComponent {
  static styleRoot = 'Proposition';

  static propTypes = {
    cnt: RPT.func,
    device: RPT.instanceOf(Device).isRequired,
    msg: RPT.func,
    location: RPT.object.isRequired,
    updatePath: RPT.func.isRequired,
  };

  renderListItem = (id) => {
    if (!id || id === '') return null;

    const { cnt } = this.props;
    const style = this.getStyle();

    return (
      <li style={style.list.item.base}>
        <Icon
          kind="check"
          size={22}
          style={style.list.item.icon}
          wrapperStyle={style.list.item.iconWrapper}
        />
        <div style={style.list.item.description}>
          {cnt(`homepage.proposition.points.${id}`)}
        </div>
      </li>
    );
  };

  renderList() {
    const { device } = this.props;
    const style = this.getStyle();

    if (device.atMost('tablet')) return null;

    return (
      <div>
        <ul style={style.list} id="hp-intro-list" data-test="hp-intro-list">
          {this.renderListItem('one')}
          {this.renderListItem('two')}
          {this.renderListItem('three')}
        </ul>
      </div>
    );
  }

  render() {
    const {
      cnt,
      msg,
      location: { pathname },
      updatePath,
    } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Header inverseColors pathname={pathname} />

        <Container style={style.container}>
          <div style={style.arrowText}>
            {msg('homepage.proposition.arrowText')}
            <Icon kind="arrow-sign-in" size={34} style={style.arrowIcon} />
          </div>

          <Row>
            <Column
              large={5}
              medium={5}
              small={6}
              phone={12}
              lgOffset={3}
              mdOffset={2}
            >
              <div style={style.contentWrapper}>
                <Heading
                  className="propositionHeading"
                  inheritedStyle={style.heading}
                  kind="h1"
                >
                  {msg('homepage.proposition.title')}
                </Heading>

                <Heading inheritedStyle={style.subheading} kind="h2">
                  {cnt('homepage.proposition.subtitle')}
                </Heading>

                {this.renderList()}
              </div>
            </Column>

            <Column
              large={4}
              medium={5}
              small={6}
              phone={12}
              style={style.calculatorHolder}
            >
              <Calculator
                onApply={() =>
                  updatePath(getPath('registrationPersonalDetails'))
                }
                responsiveCentered
              />
            </Column>
          </Row>
        </Container>

        <div style={style.arrow} />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      backgroundColor: '#eff4a5',
      backgroundImage: 'url("https://dev.4finance.com/vivus.es/online-desktop.jpg")',
      backgroundPosition: '52% -50px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      minHeight: '860px',
      position: 'relative',
      [mediaQueries.breakpointTablet]: {
        backgroundImage: 'url("https://dev.4finance.com/vivus.es/online-mobile.jpg")',
        backgroundPosition: 'right -30px',
        backgroundSize: 'auto 270px',
      },
      '@media screen and (max-width: 375px)': {
        backgroundPosition: '75% -20px',
      },
    },

    arrowText: {
      color: colors.grayDark,
      position: 'absolute',
      top: '-20px',
      right: '44px',
      fontStyle: 'italic',
      fontWeight: 400,
      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    arrowIcon: {
      marginLeft: '12px',
      // fill: colors.white
    },

    container: {
      paddingTop: '4rem',
      paddingBottom: '4.8rem',
      position: 'relative',
      [mediaQueries.breakpointTablet]: {
        paddingTop: '2rem',
        marginTop: '6rem',
      },
    },

    contentWrapper: {
      maxWidth: '51rem',
      [mediaQueries.breakpointTablet]: {
        maxWidth: '100%',
        marginBottom: '30px',
        minHeight: '160px',
        paddingRight: '235px',
      },
      '@media screen and (max-width: 375px)': {
        paddingRight: '180px',
      },
    },

    heading: {
      fontSize: '5.8rem',
      lineHeight: '1.1',
      fontWeight: 500,
      color: colors.secondary,
      marginTop: '.5rem',
      marginBottom: '0',
      [mediaQueries.breakpointLaptopSmall]: {
        fontSize: '3.8rem',
        fontWeight: 400,
        lineHeight: '0.9',
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '3rem',
      },
      [mediaQueries.breakpointTablet]: {
        color: colors.primary,
      },
    },

    subheading: {
      fontSize: '3.5rem',
      lineHeight: '1em',
      fontWeight: 500,
      color: colors.gray,
      marginTop: '3rem',
      marginBottom: '3rem',
      [mediaQueries.breakpointTablet]: {
        margin: '1.5rem 0',
        fontWeight: 400,
        color: '#1b6639',
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '2.2rem',
      },
    },

    list: {
      listStyle: 'none',
      marginTop: '3.2rem',
      padding: '0',
      maxWidth: '347px',

      item: {
        base: {
          marginBottom: '1.5rem',
          paddingLeft: '44px',
          position: 'relative',
          fontWeight: 500,
          color: colors.additional,
          fontSize: '1.8rem',
        },

        icon: {
          fill: colors.secondary,
        },

        iconWrapper: {
          position: 'absolute',
          left: '0',
          top: '0',
        },

        heading: {
          color: colors.additional,
          fontSize: '2.2rem',
          margin: '0 0 1.2rem',
          fontWeight: 500,
        },

        description: {
          color: colors.grayDark,
          fontSize: '1.9rem',
          fontWeight: 'initial',
          lineHeight: '1.1',
        },
      },
    },

    arrow: {
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderBottom: `20px solid ${colors.white}`,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },

    calculatorHolder: {
      [mediaQueries.breakpointTablet]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  });
}

export default Proposition;
