import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ProgressBar as ProgressBarComponent, StyledComponent } from '4finance-components';

@translate
@radium
class ProgressBar extends StyledComponent {
  static styleRoot = 'ProgressBar';

  static propTypes = {
    msg: RPT.func,
    step: RPT.number.isRequired,
  };

  render() {
    const { msg, step } = this.props;
    const style = this.getStyle();
    const steps = [
      msg('registration.progressbar.step1'),
      msg('registration.progressbar.step2'),
      msg('registration.progressbar.step3'),
    ];
    const last = step === steps.length;

    return (
      <ProgressBarComponent
        activeStep={msg(`registration.progressbar.step${step}`)}
        steps={steps}
        style={last ? { background: { right: style.background } } : {}}
      />
    );
  }

  static style = ({ colors }) => ({
    background: {
      backgroundColor: colors.primary,
    },
  })
}

export default ProgressBar;
