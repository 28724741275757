// Because https://github.com/change/cookie-dough/issues/3
// https://github.com/change/cookie-dough
import cookie from 'cookie';

export default function getCookieManager(req) {
  if (req) {
    return {
      set(name, value, options) {
        const cookieStr = cookie.serialize(name, value, options);
        req.cookies[name] = value;
        req.res.cookie.call(req.res, name, value, options);
        if (options.expires) {
          req.res.cookie(name, value, { expires: options.expires });
        }
        return cookieStr;
      },

      get(name) {
        return req.cookies[name];
      },

      remove(name, options) {
        const opts = options || {};
        opts.expires = new Date(0);
        req.cookies[name] = null;
        return !!(req.res.cookie(name, '', opts));
      },

      all() {
        return req.cookies;
      },
    };
  }

  return {
    set(name, value, options) {
      const cookieStr = cookie.serialize(name, value, options);
      document.cookie = cookieStr;
      return cookieStr;
    },

    get(name) {
      return cookie.parse(document.cookie)[name];
    },

    remove(name, options) {
      const opts = options || {};
      opts.expires = new Date(0);

      // eslint-disable-next-line no-return-assign
      return !!(document.cookie = cookie.serialize(name, '', opts));
    },

    all() {
      return cookie.parse(document.cookie);
    },
  };
}

export function getCookiesString(req) {
  const cookies = getCookieManager(req);
  const allCookies = cookies.all();

  return Object.keys(allCookies).map(name => `${name}=${allCookies[name]}`).join('; ');
}
