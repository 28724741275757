import classnames from 'classnames';
import radium, { Style as RadiumStyle } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent, Container } from '4finance-components';

@radium
class Notification extends StyledComponent {
  static styleRoot = 'Notification'

  static propTypes = {
    actionTitle: RPT.string.isRequired,
    inheritedStyles: RPT.object,
    link: RPT.string,
    linkTitle: RPT.string,
    message: RPT.string.isRequired,
    name: RPT.string.isRequired,
    onClick: RPT.func.isRequired,
  }

  render() {
    const {
      actionTitle, link, linkTitle, message, name, onClick, inheritedStyles, ...rest
    } = this.props;
    const style = this.getStyle();
    const classes = classnames(rest.className, 'notification');

    return (
      <div className={classes} style={[style.wrapper, inheritedStyles]}>
        <RadiumStyle
          rules={{
            '.notification a': {
              color: 'white',
            },

            '.notification a:hover': {
              opacity: 0.85,
            },
          }}
        />
        <Container>
          <div data-test="cookies-notification-container" style={style.contentWrapper}>
            <div style={style.message} dangerouslySetInnerHTML={{ __html: message }} />
            <div style={style.controls}>
              {
                linkTitle && <a style={style.link} href={link} key="notificationLink" data-test="cookies-notification-link" >{linkTitle}</a>
              }
              <button className={name} data-test={name} onClick={onClick} style={style.button}>
                {actionTitle}
              </button>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      color: colors.white,
      backgroundColor: 'hsla(213, 4%, 40%, 0.93)',
      width: '100%',
      minHeight: '60px',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },

    contentWrapper: {
      display: 'table',
      width: '100%',
      padding: '20px',
      [mediaQueries.breakpointSmall]: {
        padding: '10px 20px',
      },
    },

    message: {
      display: 'table-cell',
      fontSize: '14px',
      textAlign: 'left',
      verticalAlign: 'middle',
      [mediaQueries.breakpointSmall]: {
        fontSize: '11px',
      },
    },

    controls: {
      display: 'table-cell',
      paddingLeft: '20px',
      width: '280px',
      verticalAlign: 'middle',
      [mediaQueries.breakpointTablet]: {
        fontSize: '11px',
        width: '100px',
        textAlign: 'center',
        paddingLeft: '0',
      },
    },

    button: {
      backgroundColor: 'transparent',
      border: '1px solid #fdfdfd',
      borderRadius: '3px',
      minWidth: '55px',
      fontSize: '14px',
      padding: '10px',
      margin: '15px 0',
      outline: '0',
      color: 'white',
      cursor: 'pointer',
      transition: 'background-color .2s ease-in-out, color .2s ease-in-out',
      ':hover': {
        color: 'black',
        backgroundColor: colors.white,
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '12px',
      },
    },

    link: {
      color: colors.white,
      fontSize: '14px',
      marginRight: '20px',
      textDecoration: 'none',
      display: 'inline-block',
      transition: 'opacity .2s ease-in-out',
      ':hover': {
        textDecoration: 'underline',
        opacity: 0.85,
      },
      [mediaQueries.breakpointTablet]: {
        marginRight: 0,
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '12px',
      },
    },
  })
}

export default Notification;
