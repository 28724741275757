import { httpDelete, httpGet, httpGetHtml, httpPost, httpPut, httpRequest } from '4finance-http';
import dispatchAndThrow from './dispatchAndThrow';
import dispatchPromise from './dispatchPromise';
import getApiResponse from './getApiResponse';

export default function createDependencyInjections({ persistenceStore }) {
  return {
    statics: { persistenceStore },
    dynamic: {
      httpDelete, httpPost, httpPut, httpGet, httpGetHtml, httpRequest,
    },
    withDispatch: { dispatchAndThrow, dispatchPromise },
    withStateAndDispatch: { getApiResponse },
  };
}
