import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

export default class VerticalMenu extends StyledComponent {
  static styleRoot = 'VerticalMenu'

  static propTypes = {
    children: RPT.any,
  }

  render() {
    const { children } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <ul style={style.list}>{children}</ul>
      </div>
    );
  }

  static style = ({ colors }) => ({
    list: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      maxHeight: '100vh',
      overflowY: 'auto',
      borderBottom: `1px solid ${colors.inputBorder}`,
    },
  })
}
