import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, Heading, StyledComponent } from '4finance-components';
import ApplicationLayout from '../../layouts/Application.react';
import Icon from '../../components/Icon.react';

@translate
@radium
class Rejected extends StyledComponent {
  static styleRoot = 'Rejected'

  static propTypes = {
    cnt: RPT.func,
    location: RPT.object.isRequired,
  }

  renderListStyle = () => (
    <Style
      rules={{
          '.rejected-wrapper h2': {
            color: '#46484B',
          },
          '.rejected-wrapper ul': {
            listStyle: 'none',
          },
          '.rejected-wrapper ul li': {
            color: '#65696E',
            paddingRight: '30px',
          },
          '.rejected-wrapper ul li::before': {
            content: '"\\2014"',
            textIndent: -21,
            marginRight: 5,
          },
          '.rejected-wrapper p': {
            color: '#65696E',
          },
        }}
    />
  )

  render() {
    const style = this.getStyle();
    const { cnt, location } = this.props;

    return (
      <ApplicationLayout location={location}>
        {this.renderListStyle()}
        <Container>
          <div style={style.wrapper}>
            <Heading decorated kind="h1" thin style={{ decoration: { base: style.heading.decoration } }}>
              <Icon kind="sadface" style={style.heading.icon} size={90} />
              {cnt('application.rejected.heading')}
            </Heading>
            <div className="rejected-wrapper">
              {cnt('application.rejected.text')}
            </div>
          </div>
        </Container>
      </ApplicationLayout>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      paddingLeft: '134px',
      [mediaQueries.breakpointTablet]: {
        paddingLeft: '0',
      },
    },
    heading: {
      decoration: {
        minWidth: '5%',
      },

      icon: {
        paddingRight: '44px',
        position: 'absolute',
        top: '-10px',
        right: '100%',
        boxSizing: 'content-box',
        [mediaQueries.breakpointTablet]: {
          marginBottom: '15px',
          paddingRight: '0',
          width: '45px',
          height: '45px',
          position: 'static',
          display: 'block',
        },
      },
    },
  });
}

export default Rejected;
