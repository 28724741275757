import {
  GTM_PRE_APPROVED_EVENT,
  GTM_PAGE_CHANGE_EVENT,
  gtmStoreEvent,
  gtmTriggerEvent,
  gtmFieldErrorHandler,
} from '../lib/gtm';
import { APPLICATION_PROCESS_COMPLETE } from '../registration/actions';

export function watchRouteChange({ action, getState }) {
  if (action.type === '@@router/LOCATION_CHANGE') {
    gtmTriggerEvent(getState, GTM_PAGE_CHANGE_EVENT, action.payload.pathname);
  }
}

export function watchOnionFormFieldLiveErrors({ action }) {
  if (action.type === 'SET_ONION_FORM_MULTIPLE_FIELDS' && ['error', 'apiError'].indexOf(action.property) !== -1 && Object.getOwnPropertyNames(action.values).length) {
    gtmFieldErrorHandler(action);
  }
}

export function watchLoginEvents({ action, getState }) {
  const data = action.payload;

  switch (action.type) {
    case 'AUTHENTICATION_LOGIN_ERROR': {
      return gtmStoreEvent({ event: 'login_error', errorMessage: data && data.errors && data.errors[0].message });
    }
    case 'AUTHENTICATION_LOGIN_SUCCESS': {
      const { ui } = getState();
      const isShown = ui.get('isLoginModalShown');

      if (isShown) {
        return gtmStoreEvent({ event: 'login_ok' });
      }
      break;
    }
    case 'TOGGLE_LOG_IN_MODAL': {
      if (data) {
        return gtmStoreEvent({ event: 'login_popup' });
      }
      break;
    }
    case APPLICATION_PROCESS_COMPLETE: {
      gtmTriggerEvent(getState, GTM_PRE_APPROVED_EVENT);
      break;
    }
  }
  return {};
}

export function watchExistingClientEvents({ action }) {
  const data = action.payload;

  switch (action.type) {
    case 'TOGGLE_EXISTING_CLIENT_MODAL': {
      if (data) {
        return gtmStoreEvent({ event: 'existing_client_popup' });
      }
      break;
    }
    case 'TRACK_EXISTING_CLIENT_LOGIN': {
      return gtmStoreEvent({ event: 'existing_client_click' });
    }
  }

  return {};
}
