import React, { Component } from 'react';
import RPT from 'prop-types';
import DefaultPage from './DefaultPage.react';

export default class DataSharing extends Component {
  static propTypes = { location: RPT.object.isRequired }

  render() {
    const { location } = this.props;

    return <DefaultPage location={location} titleKey="dataSharing.title" contentKey="dataSharing.content" />;
  }
}
