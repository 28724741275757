import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Link } from 'react-router';
import { StyledComponent } from '4finance-components';

const RadiumLink = radium(Link);

@translate
@radium
class MenuDesktop extends StyledComponent {
  static styleRoot = 'MenuDesktop'

  static propTypes = {
    location: RPT.object.isRequired,
    msg: RPT.func,
    items: RPT.array,
  }

  render() {
    const { items, location: { pathname }, msg } = this.props;
    const style = this.getStyle();

    return (
      <ul style={style.menu}>
        {
          items.map(path => (
            <li key={path} style={style.menu.item}>
              <RadiumLink to={path} style={[style.menu.link, pathname.indexOf(path) !== -1 && style.menu.linkActive]}>{msg(`agreement.menu.${path}`)}</RadiumLink>
            </li>))
        }
      </ul>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    menu: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      background: colors.primary,
      borderRadius: '10px',
      overflow: 'hidden',
      [mediaQueries.breakpointLaptopSmall]: {
        display: 'none',
      },
      item: {
        marginBottom: '-1px',
        display: 'block',
        borderBottom: `1px solid ${colors.primaryDarker}`,
        ':lastChild': {
          borderBottom: 'none',
        },
      },
      link: {
        padding: '15px 25px',
        color: 'white',
        fontSize: '18px',
        textDecoration: 'none',
        display: 'block',
        ':hover': {
          background: colors.primaryDarker,
        },
      },
      linkActive: {
        background: colors.primaryDarker,
      },
    },
  });
}

export default MenuDesktop;
