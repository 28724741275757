/* eslint-disable react/no-multi-comp  */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Immutable from 'immutable';

let devices = [];

export class Device {
  constructor(name, maxWidth) {
    this.name = name;
    this.maxWidth = maxWidth;
  }

  atLeast(sizeName) {
    const searchDevice = devices.find(device => device.name === sizeName);
    return devices.indexOf(searchDevice) <= devices.indexOf(this);
  }

  atMost(sizeName) {
    const searchDevice = devices.find(device => device.name === sizeName);
    return devices.indexOf(searchDevice) >= devices.indexOf(this);
  }
}

devices = Immutable.List([
  new Device('phone', 480),
  new Device('tablet', 768),
  new Device('desktop', 992),
  new Device('large', 5000),
]);
const defaultDevice = devices.get(2); // desktop

export default function listenWindowResize(Component) {
  if (typeof document === 'undefined') {
    return class extends React.Component {
      static displayName = `${Component.name}ListenWindowResize`

      render() {
        return <Component {...this.props} device={defaultDevice} />;
      }
    };
  }

  return class extends React.Component {
    static displayName = `${Component.name}ListenWindowResize`

    state = {
      device: this.findDevice(),
      docHeight: this.calculateDocumentHeight(),
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    setDocumentHeight = () => {
      this.setState({ docHeight: this.calculateDocumentHeight() });
    }

    handleResize = () => {
      const device = this.findDevice();

      if (device !== this.state.device) {
        this.setState({ device });
      }

      this.setDocumentHeight();
    }

    findDevice() {
      return (
        devices.find(device => device.maxWidth > window.innerWidth) || defaultDevice
      );
    }
    calculateDocumentHeight() {
      const body = document ? document.body : 0;
      const html = document ? document.documentElement : 0;

      return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };
}

/* eslint-enable react/no-multi-comp */
