import Helmet from 'react-helmet';
import radium, { StyleRoot } from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ThemeProvider } from '4finance-components';
import './font.css';
import './reset.css';
import AppStyles from './Styles.react';
import CompatibilityNotification from '../notification/CompatibilityNotification.react';
import extendedVivusTheme from '../styles/extendVivusTheme';
import listenWindowResize, { Device } from '../lib/listenWindowResize.react';
import ResponsiveMenu from './menu/ResponsiveMenu.react';
import { connectState, connectActions } from '../connect';

export const OUTER_CONTAINER_ID = 'outer-container';

@listenWindowResize
@translate
@connectState({
  isSideMenuOpen: ['ui', 'isSideMenuOpen'],
  unsupported: ['device', 'unsupported'],
  userAgentString: ['device', 'userAgentString'],
})
@connectActions({
  toggleSideMenu: ['ui', 'toggleSideMenu'],
})
@radium
class App extends Component {
  static contextTypes = {
    store: RPT.object.isRequired,
  }

  static propTypes = {
    children: RPT.node,
    device: RPT.instanceOf(Device).isRequired,
    isSideMenuOpen: RPT.bool,
    location: RPT.object.isRequired,
    msg: RPT.func,
    toggleSideMenu: RPT.func,
    unsupported: RPT.bool,
    userAgentString: RPT.string.isRequired,
  }

  renderOverlay() {
    const { isSideMenuOpen, toggleSideMenu } = this.props;

    if (isSideMenuOpen) {
      return <div style={style.overlay} onClick={toggleSideMenu} />;
    }

    return null;
  }

  render() {
    const {
      children, device, location: { pathname }, msg, userAgentString, isSideMenuOpen, unsupported,
    } = this.props;
    const pageClass = pathname
      .replace(/^.*?([a-z0-9-]+).*$/, '$1')
      .replace('/', ' ');

    const pageDivProps = {
      className: `page ${pageClass}`,
      id: 'page-wrap',
      style: [style.page, isSideMenuOpen ? style.page.opened : false],
      'data-pathname': pathname,
    };

    return (
      <StyleRoot radiumConfig={{ userAgent: userAgentString }} >
        <ThemeProvider style={extendedVivusTheme}>
          <div id={OUTER_CONTAINER_ID}>
            {device.atMost('tablet') &&
              <ResponsiveMenu />
            }
            <div {...pageDivProps} data-test="page-wrap">
              <Helmet>
                <title>{msg('homepage.title')}</title>
              </Helmet>
              <AppStyles />
              {unsupported &&
                <CompatibilityNotification />
              }
              {children}
              {this.renderOverlay()}
            </div>
          </div>
        </ThemeProvider>
      </StyleRoot>
    );
  }
}
export default App;

const style = {
  page: {
    minHeight: '100vh',
    position: 'relative',
    right: 0,
    transition: '.5s right ease-in-out',
    opened: {
      position: 'relative',
      right: '250px',
    },
  },

  overlay: {
    position: 'fixed',
    zIndex: 101,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};
