import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import ConditionsLayout from './ConditionsLayout.react';
import Header from './Header.react';


@translate
class CookiesPolicy extends Component {
  static propTypes = {
    cnt: RPT.func,
    location: RPT.object.isRequired,
    msg: RPT.func,
  }

  render() {
    const { cnt, location, msg } = this.props;

    return (
      <ConditionsLayout location={location}>
        <div id="cookies-info-wrapper">
          <Header containerID="loan-info-wrapper" title={msg('cookiesPolicy.title')} />
          <div className="terms-wrapper">
            {cnt('cookiesPolicy.content')}
          </div>
        </div>
      </ConditionsLayout>
    );
  }
}

export default CookiesPolicy;
