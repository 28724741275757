import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Select, StyledComponent } from '4finance-components';
import { connectActions } from '../connect';

@connectActions({
  updatePath: ['routing', 'updatePath'],
})
@translate
@radium
class MenuMobile extends StyledComponent {
  static styleRoot = 'menuMobile'

  static propTypes = {
    items: RPT.array,
    location: RPT.object.isRequired,
    msg: RPT.func,
    updatePath: RPT.func,
  }

  switchPage = ({ value }) => {
    const { updatePath } = this.props;
    updatePath(`/${value}`);
  }

  render() {
    const { items, location: { pathname }, msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.select}>
        <Select size={12} value={pathname.replace('/', '')} onChange={this.switchPage}>
          {
            items.map(path =>
              <option key={path} value={path}>{msg(`agreement.menu.${path}`)}</option>)
          }
        </Select>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    select: {
      marginTop: '25px',
      display: 'none',
      [mediaQueries.breakpointLaptopSmall]: {
        display: 'block',
      },
    },
  });
}

export default MenuMobile;
