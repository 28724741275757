/* eslint-disable react/no-multi-comp */

import Immutable from 'immutable';
import React from 'react';
import RPT from 'prop-types';
import { connectState } from '../connect';

export class Device {
  constructor(name, maxWidth) {
    this.name = name;
    this.maxWidth = maxWidth;
  }

  atLeast(sizeName) {
    const searchDevice = devices.find(device => device.name === sizeName);
    return devices.indexOf(searchDevice) <= devices.indexOf(this);
  }

  atMost(sizeName) {
    const searchDevice = devices.find(device => device.name === sizeName);
    return devices.indexOf(searchDevice) >= devices.indexOf(this);
  }
}

const devices = Immutable.List([
  new Device('phone', 480),
  new Device('tablet', 768),
  new Device('desktop', 992),
  new Device('large', 5000),
]);

export default function listenWindowResize(Component) {
  let Wrapper;

  if (typeof document === 'undefined') {
    Wrapper = class extends React.Component {
      static displayName = `${Component.name}ListenWindowResize`

      static propTypes = {
        isMobile: RPT.bool,
      }

      render() {
        const device = this.props.isMobile ? devices.get(0) : devices.get(2);

        return <Component {...this.props} device={device} />;
      }
    };
  } else {
    Wrapper = class extends React.Component {
      static displayName = `${Component.name}ListenWindowResize`

      static propTypes = {
        isMobile: RPT.bool,
      }

      constructor(props) {
        super(props);
        this.handleResize = this.handleResize.bind(this);
      }

      state = {
        device: this.findDevice(),
      }

      componentDidMount() {
        window.addEventListener('resize', this.handleResize);
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
      }

      getDefaultDevice() {
        return this.props.isMobile ? devices.get(0) : devices.get(2);
      }

      handleResize() {
        const device = this.findDevice();

        if (device !== this.state.device) {
          this.setState({ device });
        }
      }

      findDevice() {
        return (
          devices.find(device => device.maxWidth > window.innerWidth) || this.getDefaultDevice()
        );
      }

      render() {
        return <Component {...this.props} {...this.state} />;
      }
    };
  }

  return connectState({ isMobile: ['device', 'isMobile'] })(Wrapper);
}

/* eslint-enable react/no-multi-comp */
