import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, StyledComponent } from '4finance-components';
import PrintButton from '../components/PrintButton.react';


@translate
@radium
class TermsAndConditionsHeader extends StyledComponent {
  static styleRoot = 'TermsAndConditionsHeader'

  static propTypes = {
    msg: RPT.func,
    containerID: RPT.string,
    title: RPT.string,
  }

  renderTermsStyle = () => (
    <Style
      rules={{
          'h1 strong, h1 b': {
            fontWeight: 400,
          },
          'h1 i': {
            verticalAlign: 'text-top',
            fontSize: '0.7em',
            fontStyle: 'normal',
          },
        }}
    />
  )

  render() {
    const { containerID, msg, title } = this.props;
    const style = this.getStyle();

    return (
      <div>
        {this.renderTermsStyle()}
        <Heading decorated kind="h1" thin style={style.heading}>
          {title}
        </Heading>
        <div style={style.printWrapper}>
          <PrintButton id={containerID} title={msg('common.print')} />
        </div>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    heading: {
      base: {
        letterSpacing: '-0.5px',
        lineHeight: 1.2,
      },
      decoration: {
        base: {
          minWidth: '5%',
        },
      },
      h1: {
        fontWeight: 'normal',
      },
    },
    printWrapper: {
      marginTop: '30px',
      textAlign: 'right',
      [mediaQueries.breakpointLaptopSmall]: {
        display: 'none',
      },
    },
  });
}

export default TermsAndConditionsHeader;
