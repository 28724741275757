import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Button, Column, Heading, Row, StyledComponent } from '4finance-components';
import { connectActions } from '../connect';
import ApplicationLayout from '../layouts/Application.react';

@connectActions({
  updatePath: ['routing', 'updatePath'],
})
@translate
@radium
class PasswordRecoveryCompleted extends StyledComponent {
  static styleRoot = 'PasswordRecoveryCompleted'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    updatePath: RPT.func.isRequired,
  }

  render() {
    const { cnt, msg, updatePath } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <ApplicationLayout>
          <Row>
            <Column phone={12} style={style.offsetColumn}>
              {this.renderIcon()}
              <Heading centered withSubheading kind="h1" inheritedStyle={style.heading}>
                {cnt('passwordRecovery.passwordRecoveryCompleted.heading')}
                <div style={style.subheading}>{cnt('passwordRecovery.passwordRecoveryCompleted.subheading')}</div>
              </Heading>
              <div style={style.body}>{cnt('passwordRecovery.passwordRecoveryCompleted.body')}</div>
              <Button
                id="backButton"
                kind="primary"
                onClick={() => updatePath('/')}
              >
                {msg('passwordRecovery.passwordRecoveryCompleted.button')}
              </Button>
            </Column>
          </Row>
        </ApplicationLayout>
      </div>
    );
  }

  renderIcon() {
    const style = this.getStyle();

    return (
      <svg viewBox="0 0 99 83" version="1.1" xmlns="http://www.w3.org/2000/svg" className="airplane passwordSend-module__airplane___vIjw9" style={style.icon}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="sign_in_pasw_recovery_sent" transform="translate(-851.000000, -181.000000)" fill="#A7CC46">
            <g id="noun_79693_cc" transform="translate(851.000000, 181.000000)">
              <g id="Group">
                <path d="M99,-7.10542736e-15 L0,37.7286255 L30.3897,47.1477491 L39.9388,77.4249295 L55.5423,62.5442228 L81.2691,82.8554558 L99,-7.10542736e-15 L99,-7.10542736e-15 Z M33.1782,46.1605824 L32.5633,46.3546882 L89.3959,9.61655692 L42.8505,52.5239257 L41.0069,70.9861622 L33.1782,46.1605824 L33.1782,46.1605824 Z" id="Shape" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }

  static style = ({ mediaQueries }) => ({
    body: {
      margin: '30px 0 16px 0',
    },
    offsetColumn: {
      textAlign: 'center',
      minHeight: '560px',
      [mediaQueries.breakpointLaptopSmall]: {
        minHeight: '0',
      },
    },
    icon: {
      width: '99px',
      height: '83px',
      margin: '48px 0 0 0',
    },
    heading: {
      fontWeight: 500,
      lineHeight: '1.2',
      fontSize: '3.8rem',
    },

    subheading: {
      fontSize: '3.8rem',
      fontWeight: 100,
      padding: '2px 0 10px',
    },
  })
}

export default PasswordRecoveryCompleted;
