/* eslint-disable consistent-return */
import config from '4finance-configuration';
import { actions as formActions } from '4finance-onion-form';
import { NotFoundError } from '4finance-http';
import { push as updatePath } from 'react-router-redux';
import * as authActions from '../../features/auth/common/actions';
import generateRandomData from './randomFill';
import { actions as apiActions, PRODUCT_NAME } from '../api';
import { creditLimit } from '../creditLimit/actions';
import { getPath } from '../../browser/routes';
import {
  gtmTriggerEvent,
  GTM_USER_REGISTERED_EVENT,
} from '../lib/gtm';
import getAffiliateInfo from '../lib/getAffiliateInfo';

export const CONFIRM_APPLICATION = 'CONFIRM_APPLICATION';
export const CHECK_USER_EXISTS = 'CHECK_USER_EXISTS';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const RANDOM_FILL = 'RANDOM_FILL';
export const SUBMIT_REGISTRATION = 'SUBMIT_REGISTRATION';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const FILL_ADDRESS_FIELDS = 'FILL_ADDRESS_FIELDS';
export const AUTOFILL_ADDRESS = 'AUTOFILL_ADDRESS';
export const APPLICATION_PROCESS_COMPLETE = 'APPLICATION_PROCESS_COMPLETE';
export const SEND_AFFILIATE_EVENTS_QUALIFIED = 'SEND_AFFILIATE_EVENTS_QUALIFIED';

export function submitRegistration(payload) {
  return ({ dispatch, persistenceStore }) => {
    const getPromise = async () => {
      const gaCookie = persistenceStore.get('_ga');
      const affiliateInfo = getAffiliateInfo();
      const analyticsValues = gaCookie && { analyticsCookie: { name: 'ga', value: gaCookie } };
      const payloadGaValues = {
        ...payload,
        ...analyticsValues,
        ...affiliateInfo,
      };
      const creditLimitData = await dispatch(creditLimit(payload));
      const registrationResponse = await dispatch(apiActions.sendRegistrationProductNumberByProductnumber(payloadGaValues, PRODUCT_NAME));
      const { errors } = registrationResponse.payload;
      const { maxAmount } = creditLimitData.payload;

      if (maxAmount === 0) {
        throw Error('Submit registration failed');
      }

      if (registrationResponse.error) {
        dispatch(formActions.setMultipleFields(
          'personalDetails',
          'error',
          errors.reduce((acc, err) => ({ ...acc, [err.property]: err.messageTemplate }), {}),
        ));

        throw Error('Submit registration failed');
      }

      const { email, password } = payload;
      const loginResponse = await dispatch(authActions.login(email, password, null, null, true));

      if (loginResponse.error) {
        return {};
      }

      await dispatch(createApplication());
      await dispatch(apiActions.fetchClient());
      dispatch(updatePath(getPath('registrationFinanceAndAddress')));

      return {};
    };

    return {
      type: SUBMIT_REGISTRATION,
      payload: getPromise(),
    };
  };
}

// TODO: add affiliate
export function createApplication() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const { api, calculator, device } = getState();
      const affiliateInfo = getAffiliateInfo();
      const application = {
        amount: api.getIn(['fetch', 'application', 'firstLoanOffer', 'productNumber', '', calculator.get('amount').toString(), calculator.get('term').toString(), PRODUCT_NAME, 'data', 'totalPrincipal']),
        source: device.get('type').toUpperCase(),
        term: calculator.get('term'),
        productNumber: parseInt(PRODUCT_NAME, 10),
        ...affiliateInfo,
      };

      await dispatch(apiActions.sendClientApplication(application));
    };

    return {
      type: CREATE_APPLICATION,
      payload: getPromise(),
    };
  };
}

export function checkFraudCall() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const { payload: { resolution, resolutionDetail } } = await dispatch(apiActions.patchClientApplicationCheck());

      if (resolution === 'REJECTED' && resolutionDetail === 'LOW_ADVANCED_FRAUD_MODEL_SCORE') {
        dispatch(updatePath(getPath('rejected')));
      } else {
        await dispatch(apiActions.fetchClientApplication());
        dispatch(updatePath(getPath('registrationIdentificationEmailOrWhatsapp')));
      }
    };
    return {
      type: CONFIRM_APPLICATION,
      payload: getPromise(),
    };
  };
}

export function confirmApplicationAndCheckFraud() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const params = {
        ioBlackBox: getState().onionForm.fields.getIn(['creditCheck', 'ioBlackBox', 'value']),
        sessionId: process.env.IS_BROWSER && window.session_id,
      };

      const { payload: { rejected } } = await dispatch(apiActions.patchClientApplication(params));

      if (rejected) {
        return dispatch(updatePath(getPath('rejected')));
      }
      await dispatch(checkFraudCall());
    };
    return {
      type: CONFIRM_APPLICATION,
      payload: getPromise(),
    };
  };
}

export function updateProfile(payload) {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const updateProfileResponse = await dispatch(apiActions.patchClientProfile(payload));
      const { payload: { bankAccount } } = await dispatch(apiActions.fetchClientBankAccountNumber());

      if (updateProfileResponse.error) {
        dispatch(formActions.setMultipleFields(
          'financeAndAddress',
          'apiError',
          updateProfileResponse.payload.errors.reduce((acc, err) => ({ ...acc, [err.property]: err.messageTemplate }), {}),
        ));

        throw Error('Update profile failed');
      }

      const { payload: { status } } = await dispatch(apiActions.fetchClient());

      if (status === 'REGISTERED' && !bankAccount) {
        gtmTriggerEvent(getState, GTM_USER_REGISTERED_EVENT);
      }

      return dispatch(updatePath(getPath('registrationCreditCheck')));
    };

    return {
      type: UPDATE_PROFILE,
      payload: getPromise(),
    };
  };
}

export function checkUserExists({ email, mobilePhone, personalId }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const args = ['', email || '', mobilePhone || '', personalId || ''];

      const { payload } = await dispatch(apiActions.fetchClientCheck(...args));

      return !(payload instanceof NotFoundError);
    };

    return {
      type: CHECK_USER_EXISTS,
      payload: getPromise(),
    };
  };
}

export function randomFill(field = 'personalDetails') {
  return ({ dispatch }) => {
    if (!config.faker.enabled) {
      return { type: 'DISABLED_RANDOM_FILL' };
    }

    const values = generateRandomData()[field];
    dispatch(formActions.setMultipleFields(field, 'value', values));

    return {
      type: RANDOM_FILL,
    };
  };
}

export function approvedManualPrefetch() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const { payload: { amount, term } } = await dispatch(apiActions.fetchClientApplication());

      await dispatch(apiActions.fetchApplicationFirstLoanOfferProductNumberByProductnumber('', amount, term, PRODUCT_NAME));
      return {};
    };

    return {
      type: 'APPROVED_MANUAL_PREFETCH',
      payload: getPromise(),
    };
  };
}

export function fillAddressFields(address = {}) {
  const values = Object.keys(address).reduce((acc, item) => ({
    ...acc,
    [item]: true,
  }), {});

  return ({ dispatch }) => {
    dispatch(formActions.setMultipleFields('financeAndAddress', 'liveValidation', values));
    dispatch(formActions.setMultipleFields('financeAndAddress', 'value', address));

    return {
      type: FILL_ADDRESS_FIELDS,
    };
  };
}

export function setAutoFilledAddress(value) {
  return ({ dispatch }) => {
    dispatch(formActions.setFieldValue('financeAndAddress', 'autoFilledAddress', value));

    return {
      type: AUTOFILL_ADDRESS,
    };
  };
}

export function completeApplicationProcess() {
  return ({ dispatch }) => {
    dispatch(updatePath(getPath('registrationApprovedManual')));

    return {
      type: APPLICATION_PROCESS_COMPLETE,
    };
  };
}

export function sendAffiliateEventsQualified() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      await dispatch(apiActions.sendAffiliateEventsQualified());
    };

    return {
      type: SEND_AFFILIATE_EVENTS_QUALIFIED,
      payload: getPromise(),
    };
  };
}
