import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import Footer from '../app/footer/Footer.react';

export default class HomeLayout extends Component {
  static propTypes = {
    children: RPT.any,
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
        <Footer />
      </div>
    );
  }
}
