import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';

@translate
class UrlLabel extends Component {
  static propTypes = {
    cnt: RPT.func.isRequired,
    path: RPT.string.isRequired,
    cntVariables: RPT.object,
  }

  render() {
    const { cnt, path, cntVariables } = this.props;
    return (
      <span>{cnt(`form.${path}.label`, cntVariables)}</span>
    );
  }
}

export default UrlLabel;
