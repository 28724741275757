import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import ConditionsLayout from './ConditionsLayout.react';
import Header from './Header.react';

@translate
class DefaultPage extends Component {
  static propTypes = {
    cnt: RPT.func,
    location: RPT.object.isRequired,
    titleKey: RPT.string,
    contentKey: RPT.string,
    showMenu: RPT.bool,
  }

  static defaultProps = {
    titleKey: '',
    contentKey: '',
  }

  render() {
    const {
      cnt, location, titleKey, contentKey, showMenu,
    } = this.props;

    return (
      <ConditionsLayout location={location} showMenu={showMenu}>
        <div id="informacion-comercial-wrapper">
          <Header containerID="informacion-comercial-wrapper" title={cnt(titleKey)} />
          <div className="terms-wrapper">
            {cnt(contentKey)}
          </div>
        </div>
      </ConditionsLayout>
    );
  }
}

export default DefaultPage;
