import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import ApplicationLayout from '../layouts/Application.react';

export default class Account extends Component {
  static propTypes = {
    children: RPT.object.isRequired,
    location: RPT.object.isRequired,
  }

  render() {
    const { children, location } = this.props;

    return (
      <ApplicationLayout location={location} menuShown={false}>
        {children}
      </ApplicationLayout>
    );
  }
}
