import radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Button, ButtonLink, Heading, Modal, StyledComponent } from '4finance-components';
import { connectActions, connectState } from '../connect';

@connectState({
  isExistingClientModalShown: ['ui', 'isExistingClientModalShown'],
})
@connectActions({
  toggleExistingClientModal: ['ui', 'toggleExistingClientModal'],
  trackExistingClientClick: ['tracking', 'trackExistingClientClick'],
})
@translate
@radium
class ExistingClientModal extends StyledComponent {
  static styleRoot = 'ExistingClientModal'

  static propTypes = {
    isExistingClientModalShown: RPT.bool,
    msg: RPT.func.isRequired,
    toggleExistingClientModal: RPT.func.isRequired,
    trackExistingClientClick: RPT.func.isRequired,
  }

  handleCloseModal = () => {
    const { toggleExistingClientModal } = this.props;
    toggleExistingClientModal(false);
  }

  render() {
    const { isExistingClientModalShown, msg, trackExistingClientClick } = this.props;
    const style = this.getStyle();

    return (
      <Modal
        isModalOpen={isExistingClientModalShown}
        onClose={this.handleCloseModal}
        contentLabel="existingClient"
      >
        <div style={style.wrapper}>
          <img alt="logo" src="/assets/img/logotype.svg" style={style.img} />
          <Heading kind="h1" style={style.heading}>
            {msg('existingClient.heading')}
          </Heading>
          <p style={style.text}>{msg('existingClient.existingUser')}</p>
          <ButtonLink centered external kind="primary" to="/login" onClick={trackExistingClientClick} inheritedStyle={style.loginBtn}>
            {msg('existingClient.button.accept')}
          </ButtonLink>
          <Button id="existing-client-decline" centered kind="simple" onClick={this.handleCloseModal} inheritedStyle={style.button}>
            {msg('existingClient.button.decline')}
          </Button>
        </div>
      </Modal>
    );
  }

  static style = ({
    wrapper: {
      textAlign: 'center',
      maxWidth: '550px',
    },

    img: {
      width: '83px',
      height: 'auto',
      marginLeft: '-30px',
    },

    heading: {
      base: {
        margin: '2.4rem 0',
      },

      h1: {
        fontSize: '5.6rem',
      },
    },

    text: {
      fontSize: '3.3rem',
      margin: '0 0 2.4rem',
    },

    button: {
      fontSize: '1.7rem',
      marginRight: 0,
    },

    loginBtn: {
      marginRight: 0,
    },
  });
}

export default ExistingClientModal;
