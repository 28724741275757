export default function requireAuth({ replace, getState, dispatch }) {
  const { authentication: { isLoggedIn } } = getState();

  if (isLoggedIn) {
    return true;
  }

  dispatch({ type: 'GUARD_REQUIRE_AUTH_FAILED' });
  replace('/login');
  return false;
}
