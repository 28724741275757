import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Loader } from '4finance-components';
import ConditionsLayout from './ConditionsLayout.react';
import Header from './Header.react';
import { connectPrefetch, connectState } from '../connect';
import { PRODUCT_NAME } from '../../common/api'; // eslint-disable-line no-unused-vars

@connectState({
  privacyPolicy: ['api', 'fetch', 'privacyPolicy', PRODUCT_NAME, 'data'],
})
@connectPrefetch([
  ['agreement', 'fetchPrivacyPolicy'],
])
@translate
class GeneralTerms extends Component {
  static propTypes = {
    location: RPT.object.isRequired,
    privacyPolicy: RPT.object,
    msg: RPT.func,
  }

  render() {
    const { location, msg, privacyPolicy } = this.props;

    return (
      <ConditionsLayout location={location}>
        <div id="privacy-wrapper">
          <Header containerID="privacy-wrapper" title={msg('privacyPolicy.title')} />
          <div className="terms-wrapper">
            {privacyPolicy ? <div id="privacy-content" dangerouslySetInnerHTML={{ __html: privacyPolicy }} /> : <Loader />}
          </div>
        </div>
      </ConditionsLayout>
    );
  }
}

export default GeneralTerms;
