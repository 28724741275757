import radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Column, Container, Row, StyledComponent } from '4finance-components';
import ApplicationLayout from '../layouts/Application.react';
import MenuDesktop from './MenuDesktop.react';
import MenuMobile from './MenuMobile.react';

const menuItems = [
  'condiciones-generales',
  'informacion-sobre-el-prestamo',
  'marketing-y-comunicacion',
  'comunicaciones-a-terceros',
  'politica-de-cookies',
  'politica-de-privacidad',
];

@translate
@radium
class ConditionsLayout extends StyledComponent {
  static styleRoot = 'ConditionsLayout'

  static propTypes = {
    children: RPT.any,
    location: RPT.object.isRequired,
    showMenu: RPT.bool,
  }

  static defaultProps = { showMenu: true }

  renderTermsStyle() {
    const style = this.getStyle();

    return (
      <Style
        rules={{
          '.terms-wrapper': {
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: 1.9,
            color: style.textColor.color,
          },
          '.terms-wrapper p': {
            marginBottom: '10px',
            letterSpacing: '-0.2px',
          },
          '#loan-info-wrapper table td': {
            padding: '10px 10px 10px 10px !important',
          },
          '#loan-info-wrapper p': {
            color: '#272728',
            marginBottom: '0px',
            marginTop: '-15px',
          },
          '.informacion-comercial td': {
            padding: '7px',
            verticalAlign: 'top',
          },
          '.informacion-comercial p:first-child': {
            marginTop: 0,
          },
        }}
      />
    );
  }

  render() {
    const { children, location, showMenu } = this.props;
    const style = this.getStyle();

    return (
      <ApplicationLayout location={location}>
        {this.renderTermsStyle()}

        <Container>
          <Row>
            <Column large={8} medium={8} phone={12} style={style.columns.left}>
              {showMenu && <MenuMobile items={menuItems} location={location} style={style.select} />}
              {children}
            </Column>
            <Column large={4} medium={4} phone={12} style={style.columns.right}>
              {showMenu && <MenuDesktop items={menuItems} location={location} />}
            </Column>
          </Row>
        </Container>
      </ApplicationLayout>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    columns: {
      left: {
        [mediaQueries.breakpointLarge]: {
          paddingRight: '25px',
        },
      },
      right: {
        [mediaQueries.breakpointLarge]: {
          marginTop: '50px',
        },
      },
    },
    textColor: {
      color: colors.gray,
    },
  });
}

export default ConditionsLayout;
